import React from 'react';
import { IIconProps } from './Icon.props';
import IconContainer from './IconContainer';

const IconWatchList: React.FC<IIconProps> = ({ svgColor, svgSize, ...props }) => {
  return (
    <IconContainer {...props}>
      <svg
        width={svgSize ?? '18'}
        height={svgSize ?? '19'}
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.00113 14.6234C4.15463 14.6234 0.361125 9.91189 0.199125 9.71082C-0.066375 9.37722 -0.066375 8.89739 0.199125 8.5638C0.356625 8.36273 4.15013 3.65125 9.00113 3.65125C13.8521 3.65125 17.6411 8.36273 17.8031 8.5638C18.0686 8.89739 18.0686 9.37722 17.8031 9.71082C17.6456 9.91189 13.8521 14.6234 9.00113 14.6234ZM2.11163 9.13959C3.20513 10.2958 5.92763 12.7954 9.00113 12.7954C12.0746 12.7954 14.7971 10.2958 15.8906 9.13959C14.7971 7.98343 12.0746 5.48374 9.00113 5.48374C5.92763 5.48374 3.20513 7.98343 2.11163 9.13959Z"
          fill={svgColor ?? '#004588'}
        />
        <path
          d="M9.00117 10.9675C9.99528 10.9675 10.8012 10.1491 10.8012 9.13957C10.8012 8.13004 9.99528 7.31165 9.00117 7.31165C8.00706 7.31165 7.20117 8.13004 7.20117 9.13957C7.20117 10.1491 8.00706 10.9675 9.00117 10.9675Z"
          fill={svgColor ?? '#004588'}
        />
      </svg>
    </IconContainer>
  );
};

export default IconWatchList;
