import { BubbleData } from 'models/Bubble';
import { ISearchAsset } from 'models/SearchAsset';
import * as Types from './types';

export function setVolumeSort(newState: Types.SortType): Types.AppActionTypes {
  return {
    type: Types.SET_VOLUME_SORT,
    payload: newState,
  };
}

export function setBubbleData(newState: BubbleData[]): Types.AppActionTypes {
  return {
    type: Types.SET_BUBBLE_DATA,
    payload: newState,
  };
}

export function setSearchData(newState: ISearchAsset[]): Types.AppActionTypes {
  return {
    type: Types.SET_SEARCH_DATA,
    payload: newState,
  };
}

export function SetPrefAsset(newState: ISearchAsset | undefined): Types.AppActionTypes {
  return {
    type: Types.SET_PREF_ASSET,
    payload: newState,
  };
}

export function setColorSort(newState: Types.SortType): Types.AppActionTypes {
  return {
    type: Types.SET_COLOR_SORT,
    payload: newState,
  };
}

export function setDisplaySearch(newState: boolean): Types.AppActionTypes {
  return {
    type: Types.SET_DISPLAY_SEARCH,
    payload: newState,
  };
}

export function setActiveTab(newState: Types.ActiveTabType): Types.AppActionTypes {
  return {
    type: Types.SET_ACTIVE_TAB,
    payload: newState,
  };
}

export function setSubscribeModal(newState: boolean): Types.AppActionTypes {
  return {
    type: Types.SET_SHOW_SUBSCRIBE_MODAL,
    payload: newState,
  };
}

export function setShowDetailView(newState: Types.DetailViewType): Types.AppActionTypes {
  return {
    type: Types.SET_SHOW_DETAIL_VIEW,
    payload: newState,
  };
}
export function setFirstPrefId(newState: number | undefined): Types.AppActionTypes {
  return {
    type: Types.SET_FIRST_PREF_ID,
    payload: newState,
  };
}
export function setSecondPrefId(newState: number | undefined): Types.AppActionTypes {
  return {
    type: Types.SET_SECOND_PREF_ID,
    payload: newState,
  };
}
