import React from 'react';
import { IIconProps } from './Icon.props';
import IconContainer from './IconContainer';

const IconNews: React.FC<IIconProps> = ({ svgSize, svgColor, ...props }) => {
  return (
    <IconContainer {...props}>
      <svg
        width={svgSize ?? 15}
        height={svgSize ?? 15}
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2173_3103)">
          <path d="M10.3412 4.78906H1.9502V5.38906H10.3412V4.78906Z" fill={svgColor ?? '#004588'} />
          <path d="M10.3412 6.28906H1.9502V6.88906H10.3412V6.28906Z" fill={svgColor ?? '#004588'} />
          <path d="M10.3412 7.78906H1.9502V8.38906H10.3412V7.78906Z" fill={svgColor ?? '#004588'} />
          <path d="M10.3412 9.28906H1.9502V9.88906H10.3412V9.28906Z" fill={svgColor ?? '#004588'} />
          <path
            d="M12.291 6V0.5895C12.291 0.264 12.027 0 11.7015 0H0.5895C0.264 0 0 0.264 0 0.5895V13.3515C0 14.262 0.738 15 1.65 15H13.3515C13.452 15 13.551 14.991 13.6455 14.973C14.4165 14.835 15 14.1615 15 13.3515V6H12.291ZM1.65 14.4C1.071 14.4 0.6 13.9305 0.6 13.3515V0.6H11.691V13.3515C11.691 13.731 11.7855 14.088 11.952 14.4H1.65ZM14.4 13.3515C14.4 13.9305 13.929 14.4 13.3515 14.4C12.774 14.4 12.3015 13.9305 12.3015 13.3515V6.6H14.4V13.3515Z"
            fill={svgColor ?? '#004588'}
          />
          <path
            d="M7.57961 3.60121H4.71011C4.54211 3.60121 4.45661 3.39721 4.57661 3.27871L6.01211 1.84321C6.08561 1.76971 6.20561 1.76971 6.27911 1.84321L7.71461 3.27871C7.83461 3.39871 7.74911 3.60121 7.58111 3.60121H7.57961Z"
            fill={svgColor ?? '#25CD98'}
          />
          <path
            d="M7.71461 11.3987L6.27911 12.8342C6.20561 12.9077 6.08561 12.9077 6.01211 12.8342L4.57661 11.3987C4.45661 11.2787 4.54211 11.0762 4.71011 11.0762H7.57961C7.74761 11.0762 7.83311 11.2802 7.71311 11.3987H7.71461Z"
            fill={svgColor ?? '#CC0033'}
          />
        </g>
        <defs>
          <clipPath id="clip0_2173_3103">
            <rect width="15" height="15" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </IconContainer>
  );
};

export default IconNews;
