import makeStyles from '@mui/styles/makeStyles';
import ColorPalette from 'utils/ColorPalette';

const useStyles = makeStyles(() => ({
  navbar: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    position: 'sticky',
    paddingBlock: '1rem',
    paddingInline: '2rem',
    zIndex: 1000,
    '@media (max-width: 1000px)': {
      paddingInline: '1rem',
      alignItems: 'center',
      paddingBlock: '5px',
    },
  },
  navItemContainer: {
    width: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    maxHeight: 35,
  },
  textField: {
    width: '90%',
  },
  searchBarContainer: {
    border: `1px solid ${ColorPalette.white} `,
    borderBottom: 'none',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    width: '450px',
    marginBottom: 0,
    paddingInline: '10px',
  },
  searchBarMobile: {
    border: `1px solid ${ColorPalette.white} `,
    borderRadius: '8px',
    width: '100%',
    paddingInline: '10px',
  },
  menuItem: {
    color: ColorPalette.white,
  },
  menuItemSelected: {
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: 3,
        backgroundColor: 'white',
        marginInlineStart: 0,
        bottom: -3,
        left: 0,
      },
    },
  },
  menuItemSelectedSecond: {
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: 3,
        backgroundColor: ColorPalette.primary,
        marginInlineStart: 0,
        bottom: -3,
        left: 0,
      },
    },
  },
  button: {
    fontSize: '0.875rem',
    borderRadius: '25px',
    height: '30px',
    maxWidth: 'fit-content',
    padding: '0px 35px',
    '&.MuiButtonBase-root:hover"': {
      backgroundColor: ColorPalette.primary,
    },
  },
}));
export default useStyles;
