import { IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconLogo from 'assets/icons/IconLogo';
import CP from 'utils/ColorPalette';

function SidBarHeader({ onClose }: { onClose: () => void }) {
  return (
    <Stack
      direction="row"
      className="align-items-center w-100 p-3"
      sx={{
        borderBottom: '1px solid #BFC0C0',
      }}
    >
      <IconLogo sx={{ width: 40, height: 40 }} />
      <Typography variant="h4" color={CP.primary} sx={{ marginLeft: '10px' }}>
        sTalk
      </Typography>
      <IconButton className="ml-auto p-0" size="large" onClick={onClose}>
        <CloseIcon sx={{ fontSize: '40px', color: CP.primary }} />
      </IconButton>
    </Stack>
  );
}

export default SidBarHeader;
