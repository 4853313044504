import makeStyles from '@mui/styles/makeStyles';
import ColorPalette from 'utils/ColorPalette';

const useStyles = makeStyles(theme => ({
  whiteBox: {
    padding: '5rem 2rem',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      padding: '1rem',
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginInline: 'auto',
    width: '40%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 'inherit',
    },
  },
  lastTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    width: '45%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 'inherit',
      marginTop: '1.5rem',
    },
  },
  imgContainer: {
    marginInline: 'auto',
    minWidth: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      height: '280px',
      overflow: 'hidden',
      display: 'block',
    },
  },

  title: {
    color: ColorPalette.textPrimary,
    fontSize: '1.8rem',
    fontWeight: '900',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      marginTop: '1rem',
    },
  },
  whiteTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '2rem',
    width: '30%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginInline: 'auto',
    },
  },
  whiteTitle: {
    color: ColorPalette.white,
    fontSize: '1.8rem',
    fontWeight: '900',
    marginBottom: '1.8rem',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      marginBottom: '.8rem',
    },
  },
  description: {
    color: ColorPalette.textPrimary,
    fontSize: '1.3rem',
    marginTop: '1rem',
    //textIndent: '12px',
    marginBottom: '2rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9rem',
      textAlign: 'center',
    },
  },
  greenButton: {
    backgroundColor: ColorPalette.newSuccess,
    color: ColorPalette.white,
    height: '60px',
    width: 'fit-content',
    paddingInline: '2.5rem',
    marginLeft: '-5px',
    marginTop: 'auto',
    '&:hover': {
      backgroundColor: ColorPalette.newSuccess,
    },
    [theme.breakpoints.down('md')]: {
      height: '32px',
      marginBlock: '1rem',
      alignSelf: 'center',
      fontSize: '0.8rem',
    },
  },
  greenBox: {
    padding: '4rem 5rem 4rem 2rem',
    display: 'flex',
    alignItems: 'center',
    height: '400px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: '2rem 1rem',
      height: 'max-content',
    },
  },
  whiteButton: {
    backgroundColor: ColorPalette.white,
    color: ColorPalette.newSuccess,
    height: '60px',
    width: '200px',
    paddingInline: '2.5rem',
    marginLeft: '-15px',
    '&:hover': {
      backgroundColor: ColorPalette.white,
    },
    [theme.breakpoints.down('md')]: {
      height: '32px',
      alignSelf: 'center',
      fontSize: '0.8rem',
      width: 'fit-content',
      marginLeft: '0px',
    },
  },
  sliderContainer: {
    border: '1px solid #e0e0e0',
    height: '200px',
    marginLeft: 'auto',
    width: '45%',
    padding: '1rem',
    marginBottom: '10px',
    borderRadius: '10px',
    '& .nav-slide-dot li': {
      margin: '0px',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '150px',
      marginTop: '1rem',
    },
  },
  lastSlider: {
    height: '500px',
    overflowY: 'hidden',
    '& .nav-slide-dot': {
      top: '40px !important',
      left: '2% !important',
      [theme.breakpoints.down('md')]: {
        top: '2% !important',
        left: '0 !important',
      },
    },
    [theme.breakpoints.down('md')]: {
      height: '680px',
    },
  },
  lastSliderContainer: {
    width: '100%',
    marginBottom: '10px',
    padding: '5rem 0rem',
    display: 'flex !important',
    alignItems: 'center',
    '& .nav-slide-dot li': {
      margin: '0px',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '1rem',
      flexDirection: 'column',
      padding: '1rem',
    },
  },
  deactiveDot: {
    height: '10px',
    width: '10px',
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    [theme.breakpoints.down('md')]: {
      height: '5px',
      width: '5px',
    },
  },
  activeDot: {
    height: '10px',
    width: '10px',
    backgroundColor: ColorPalette.white,
    borderRadius: '10px',
    [theme.breakpoints.down('md')]: {
      height: '5px',
      width: '5px',
    },
  },
  boxTitle: {
    color: ColorPalette.white,
    fontSize: '1.3rem',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
  },
  boxContent: {
    color: ColorPalette.white,
    fontSize: '.9rem',
    marginTop: '2rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '0.8rem',
      marginTop: '1rem',
    },
  },
  sliderTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginInline: 'auto',
    width: '40%',
    height: '200px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '150px',
    },
  },
  linkContainer: {
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      color: `${ColorPalette.primary} !important`,
      fontSize: '1.3rem',
    },
    '& a': {
      textDecoration: 'underline',
      color: `${ColorPalette.primary} !important`,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '1rem',
      '& p': {
        textAlign: 'center',
        fontSize: '1rem',
      },
    },
  },
}));
export default useStyles;
