import { useState } from 'react';
import { useMutation } from 'react-query';
import { useParams, useHistory } from 'react-router-dom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Button, IconButton, Input, Stack, Typography, Container } from '@mui/material';

import { AuthApi } from 'api/Auth';
import style from './style.module.scss';
import ColorPalette from 'utils/ColorPalette';
import { WhiteTextTypography } from 'common/CustomTypography';

function NewPassEnter() {
  const { push } = useHistory();
  const { id, uid } = useParams<{ id: string; uid: string }>();
  const [error, setError] = useState('');
  const [form, setForm] = useState({ uid: uid, new_password1: '', new_password2: '', token: id });
  const { mutate, isError } = useMutation('newPass', AuthApi.resetPasswordConfirm, {
    onSuccess: () => {
      push('/confirm-change-pass');
    },
    onError: err => {
      //@ts-ignore
      setError(err.response.data.detail);
    },
  });

  const styles = {
    borderRadius: '30px',
    height: '30px',
    paddingInline: '10px',
    marginTop: '10px',
    width: '100%',
    color: ColorPalette.primary,
    fontSize: '14px',
  };

  return (
    <Container className={style.subscribeContainer}>
      <Stack sx={{ padding: '20px' }}>
        <WhiteTextTypography fontSize="18px" alignSelf="center" marginBottom="40px">
          You can change your password
        </WhiteTextTypography>
        <WhiteTextTypography sx={{ marginRight: 'auto', fontSize: '14px' }}>
          choose a password (at least 8 characters)
        </WhiteTextTypography>
        <Input
          value={form.new_password1}
          type="password"
          id="password"
          onChange={e => setForm(prv => ({ ...prv, new_password1: e.target.value }))}
          placeholder="Password"
          disableUnderline
          endAdornment={
            <>
              <IconButton
                sx={{ color: 'black', padding: '0' }}
                onClick={() => {
                  const typeElement = document.getElementById('password')?.getAttribute('type');
                  document
                    .getElementById('password')
                    ?.setAttribute('type', typeElement === 'password' ? 'text' : 'password');
                }}
              >
                <RemoveRedEyeIcon />
              </IconButton>
            </>
          }
          sx={{
            backgroundColor: isError ? '#fab6bd' : '#f2efea',
            border: isError ? `1px solid  ${ColorPalette.error}` : ColorPalette.primary,
            marginBottom: '20px',
            ...styles,
          }}
        />
        <WhiteTextTypography sx={{ marginRight: 'auto', fontSize: '14px' }}>
          Please confirm your password
        </WhiteTextTypography>
        <Input
          value={form.new_password2}
          type="password"
          id="confirmPass"
          onChange={e => setForm(prv => ({ ...prv, new_password2: e.target.value }))}
          placeholder="Password"
          disableUnderline
          endAdornment={
            <>
              <IconButton
                sx={{ color: 'black', padding: '0' }}
                onClick={() => {
                  const typeElement = document.getElementById('confirmPass')?.getAttribute('type');
                  document
                    .getElementById('confirmPass')
                    ?.setAttribute('type', typeElement === 'password' ? 'text' : 'password');
                }}
              >
                <RemoveRedEyeIcon />
              </IconButton>
            </>
          }
          sx={{
            backgroundColor: isError ? '#fab6bd' : '#f2efea',
            border: isError ? `1px solid  ${ColorPalette.error}` : ColorPalette.primary,
            ...styles,
          }}
        />
        {error.length > 0 && <Typography sx={{ color: ColorPalette.error }}>{error}</Typography>}
        <Button
          variant="contained"
          disabled={
            form.new_password1.length !== form.new_password2.length || form.new_password1.length < 8
          }
          onClick={() => mutate(form)}
          color="secondary"
          sx={{
            borderRadius: '30px',
            marginTop: '40px',
            width: '200px',
            alignSelf: 'center',
          }}
        >
          Confirm
        </Button>
      </Stack>
    </Container>
  );
}

export default NewPassEnter;
