const ColorPalette = {
  pureWhite: '#FFFFFF',
  greenBtn: '#25cD98',
  offBlack: '#404040',
  lightGray: '#efefef',
  mediumGray: '#c2c2c2',
  darkGray: '#828282',
  pureBlack: '#000000',
  offWhite: '#f8f8f8',
  newBlue: '#2951b7',
  softRed: '#db5c48',
  darkBlue: '#0b3c89',
  // old colours
  primary: '#1057c4',
  success: '#009966',
  newSuccess: '#25cD98',
  error: '#e2062c',
  newError: '#cc0033',
  white: '#f2efea',
  grey: '#bfc0C0',
  newGrey: '#909090',
  greyPercent: '#878787',
  blue: '#4c77ed',
  circleSort: 'rgba(138, 138, 138,0.4)',
  tooltip: '#757575',
  black: '#000000',
  navbarColor: 'whitesmoke',
  greyButton: '#aaaaaa',
  textPrimary: '#004588',
  disableBtn: '#e0ddddAA',
};

export const greenPallet = [
  '#74ce9f',
  '#68c192',
  '#5db385',
  '#51a678',
  '#46996b',
  '#3a8c51',
  '#2e7e51',
  '#237144',
  '#176437',
  '#0c562a',
  '#00491d',
  '#003514',
];
export const redPallet = [
  '#e27d7d',
  '#d77070',
  '#cc6464',
  '#c15757',
  '#b64b4b',
  '#ab3f3f',
  '#9f3232',
  '#942626',
  '#891919',
  '#7e0d0d',
  '#730000',
  '#5e0000',
];

export default ColorPalette;
