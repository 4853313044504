import { Box, ButtonBase, Collapse, Stack, Typography, useMediaQuery } from '@mui/material';
import { BlackTextTypography } from 'common/CustomTypography';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React from 'react';
import { useQuery } from 'react-query';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { SummaryNewsService } from 'services/stocks';
import ColorPalette from 'utils/ColorPalette';
import IconNews from 'assets/icons/IconNews';
import { NewsSentiments } from 'models/NewsSentiment';

const SummaryNewsTab: React.FC<{ id?: string }> = ({ id }) => {
  const [currentView, setCurrent] = React.useState<string | null>(null);
  const isMobile = useMediaQuery('(max-width:800px)');
  const { data } = useQuery<NewsSentiments, Error>(
    ['assetSummaryNews', id],
    () => SummaryNewsService(id ? +id : 0),
    {
      retry: 1,
    },
  );

  const color = (sentiment: 'positive' | 'negative' | 'natural') => {
    if (sentiment === 'negative') {
      return ColorPalette.newError;
    }
    if (sentiment === 'positive') {
      return ColorPalette.newSuccess;
    }
    return ColorPalette.newGrey;
  };

  return (
    <div
      className="d-flex h-100 flex-column px-4 pt-2"
      style={{
        backgroundColor: ColorPalette.offWhite,
      }}
    >
      {data?.summary ? (
        <>
          <BlackTextTypography
            fontSize={isMobile ? '12px' : '18px'}
            display="flex"
            alignItems="center"
            mb={2}
            mt={2}
          >
            For the period {data?.summary?.start_date} - {data?.summary?.end_date}
          </BlackTextTypography>
          {data?.summary.summaries &&
            Object.keys(data?.summary?.summaries).map((key, index) => {
              const item = data?.summary?.summaries[key as keyof typeof data.summary.summaries];
              return (
                <Box key={index}>
                  <Stack
                    sx={{
                      borderTop: `1px solid ${ColorPalette.newGrey}`,
                      width: '100%',
                      paddingBlock: '8px',
                    }}
                    direction="row"
                    component={ButtonBase}
                    alignItems="center"
                    onClick={currentView === key ? () => setCurrent(null) : () => setCurrent(key)}
                  >
                    <BlackTextTypography sx={{ ml: '5px' }} fontSize="18px">
                      {key}
                    </BlackTextTypography>
                    <Typography
                      fontSize={isMobile ? '12px' : '16px'}
                      ml="auto"
                      mr="4px"
                      color={color(item?.sentiment as any)}
                    >
                      {item?.sentiment.charAt(0).toUpperCase() + item?.sentiment.slice(1)}
                    </Typography>
                    <MoreHorizIcon color="info" />
                  </Stack>
                  <Collapse in={currentView === key}>
                    {item?.items.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          paddingBlock: '5px',
                          marginBottom: '5px',
                          paddingInline: '10px',
                          borderRadius: '5px',
                          textAlign: 'justify',
                          color: ColorPalette.darkBlue,
                        }}
                      >
                        <Typography fontSize={isMobile ? '12px' : '16px'}>
                          <strong>• </strong> {item}
                        </Typography>
                      </Box>
                    ))}
                  </Collapse>
                </Box>
              );
            })}
        </>
      ) : (
        <BlackTextTypography fontSize="14px" display="flex" alignItems="center" mb={2}>
          No Data Available
        </BlackTextTypography>
      )}
    </div>
  );
};
export default SummaryNewsTab;
