import * as Types from './types';

const initialState: Types.AppState = {
  volumeSort: 'PRICE_CHANGE',
  colorSort: 'SENTIMENT_CHANGE',
  displaySearch: false,
  showDetailView: { isShown: false, stockIndex: '' },
  bubbleData: [],
  searchData: [],
  prefAsset: undefined,
  fistPrefID: undefined,
  secondPrefID: undefined,
  showSubscribeModal: false,
  currentTab: 'ALL',
};

function appReducer(state = initialState, actions: Types.AppActionTypes): Types.AppState {
  switch (actions.type) {
    case Types.SET_VOLUME_SORT:
      return { ...state, volumeSort: actions.payload };
    case Types.SET_ACTIVE_TAB:
      return { ...state, currentTab: actions.payload };
    case Types.SET_BUBBLE_DATA:
      return { ...state, bubbleData: actions.payload };
    case Types.SET_SEARCH_DATA:
      return { ...state, searchData: actions.payload };
    case Types.SET_PREF_ASSET:
      return { ...state, prefAsset: actions.payload };
    case Types.SET_COLOR_SORT:
      return { ...state, colorSort: actions.payload };
    case Types.SET_DISPLAY_SEARCH:
      return { ...state, displaySearch: actions.payload };
    case Types.SET_SHOW_DETAIL_VIEW:
      return { ...state, showDetailView: actions.payload };
    case Types.SET_FIRST_PREF_ID:
      return { ...state, fistPrefID: actions.payload };
    case Types.SET_SHOW_SUBSCRIBE_MODAL:
      return { ...state, showSubscribeModal: actions.payload };
    case Types.SET_SECOND_PREF_ID:
      return { ...state, secondPrefID: actions.payload };
    default:
      return state;
  }
}

export default appReducer;
