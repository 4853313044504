import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import { RootState } from 'store';
import { setDataRange } from 'store/lineChart/actions';
import { DataRange } from 'store/lineChart/types';
import ColorPalette from 'utils/ColorPalette';

interface DatesType {
  type: DataRange;
  text: string;
}

const DateSections: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { dateRange } = useSelector((state: RootState) => state.LineChart);
  const dates: DatesType[] = [
    { type: 30, text: '1M' },
    { type: 180, text: '6M' },
    { type: 365, text: '1Y' },
  ];

  return (
    <div className="d-flex justify-content-center align-items-center mt-3">
      {dates.map(item => {
        return (
          <div
            key={item.type}
            className="d-flex justify-content-center align-items-center mx-1 my-1"
            style={{
              height: 30,
              borderRadius: 50,
              border: `1px solid ${ColorPalette.mediumGray}`,
              width: '100px',
              maxWidth: '100px',
              minWidth: '100px',
              cursor: 'pointer',
              backgroundColor:
                item.type !== dateRange ? ColorPalette.pureWhite : ColorPalette.greenBtn,
              color: item.type !== dateRange ? ColorPalette.offBlack : ColorPalette.pureWhite,
            }}
            onClick={() => dispatch(setDataRange(item.type))}
          >
            <Typography
              color={item.type !== dateRange ? ColorPalette.offBlack : ColorPalette.pureWhite}
              variant="body2"
              style={{ fontSize: '0.75rem' }}
            >
              {item.text}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

export default DateSections;
