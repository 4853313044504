import Logo from 'assets/images/Logo.svg';
import UpArrowCircle from 'assets/images/upArrowCircle.svg';
import DownArrowCircle from 'assets/images/downArrowCircle.svg';
import DownArrow from 'assets/images/downArrow.svg';
import UpArrow from 'assets/images/upArrow.svg';
import TowArrow from 'assets/images/twoArrow.svg';
import SadFace from 'assets/images/sadFace.svg';
import Happyface from 'assets/images/happyFace.svg';
import PokerFace from 'assets/images/pokerFace.svg';
import Convert from 'assets/images/convert.svg';
import Gradient from 'assets/images/gradient.svg';
import Done from 'assets/images/done.svg';
import Google from 'assets/images/google.png';
import Linkedin from 'assets/images/linkedin.png';
import MenuCircle from 'assets/images/menuCircle.svg';
import DownCircle from 'assets/images/arrowDown.svg';
import CloseCircle from 'assets/images/close.svg';
import FirstSection from 'assets/images/section1.svg';
import ThirdSection from 'assets/images/sample2.svg';
import FourthSection from 'assets/images/sample.svg';

const Assets = {
  Logo: {
    src: Logo,
    alt: 'logo',
  },
  DownCircle: {
    src: DownCircle,
    alt: 'DownCircle',
  },
  CloseCircle: {
    src: CloseCircle,
    alt: 'CloseCircle',
  },
  UpArrow: {
    src: UpArrow,
    alt: 'UpArrow',
  },
  UpArrowCircle: {
    src: UpArrowCircle,
    alt: 'UpArrowCircle',
  },
  DownArrow: {
    src: DownArrow,
    alt: 'DownArrow',
  },
  DownArrowCircle: {
    src: DownArrowCircle,
    alt: 'DownArrowCircle',
  },
  Convert: {
    src: Convert,
    alt: 'Convert',
  },
  MenuCircle: {
    src: MenuCircle,
    alt: 'MenuCircle',
  },
  HappyFace: {
    src: Happyface,
    alt: 'like',
  },
  SadFace: {
    src: SadFace,
    alt: 'dislike',
  },
  PokerFace: {
    src: PokerFace,
    alt: 'neutral',
  },
  TowArrow: {
    src: TowArrow,
    alt: 'TowArrow',
  },
  Gradient: {
    src: Gradient,
    alt: 'Gradient',
  },
  Done: {
    src: Done,
    alt: 'Done',
  },
  google: {
    src: Google,
    alt: 'google',
  },
  linkedin: {
    src: Linkedin,
    alt: 'linkedin',
  },
  Section1: {
    src: FirstSection,
    alt: 'FirstSection',
  },
  Section3: {
    src: ThirdSection,
    alt: 'ThirdSection',
  },
  Section4: {
    src: FourthSection,
    alt: 'FourthSection',
  },
};

export default Assets;
