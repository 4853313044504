import React, { useState } from 'react';
import Slider from 'react-slick';
import { Box, Button, Container, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useHistory } from 'react-router-dom';
import DesktopBack from 'assets/images/backDesk4.png';
import MobileBack from 'assets/images/backMob3.png';
import useStyles from './_styles';
import Content from '../content';
import ColorPalette from 'utils/ColorPalette';

const LastSection: React.FC = () => {
  const classes = useStyles();
  const { push } = useHistory();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const settings = {
    //@ts-ignore
    customPaging: i => {
      return <div className={i !== activeSlide ? classes.deactiveDot : classes.activeDot} />;
    },
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dotsClass: 'nav-slide-dot',
    //@ts-ignore
    afterChange: current => setActiveSlide(current),
  };

  const Assets = [
    { src: require('../../../assets/videos/1.mp4'), alt: 'Slide1' },
    { src: require('../../../assets/videos/2.mp4'), alt: 'Slide2' },
    { src: require('../../../assets/videos/3.mp4'), alt: 'Slide3' },
  ];
  return (
    <Box
      sx={{
        background: isMobile ? `url(${MobileBack})` : `url(${DesktopBack})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Container className={classes.lastSlider}>
        <Slider {...settings}>
          {Content.lastSection.map((section, index) => (
            <Box key={section.title} className={classes.lastSliderContainer}>
              <Stack
                sx={(theme: Theme) => ({
                  marginInline: 'auto',
                  width: '50%',
                  maxHeight: '500px',
                  [theme.breakpoints.down('md')]: {
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxHeight: '300px',
                  },
                })}
              >
                <video
                  autoPlay
                  muted
                  style={{
                    width:
                      index === 0
                        ? isMobile
                          ? '330px'
                          : '520px'
                        : index === 2
                        ? isMobile
                          ? '550'
                          : '700'
                        : '550px',
                    height:
                      index === 1
                        ? isMobile
                          ? '250px'
                          : '400px'
                        : index === 2
                        ? isMobile
                          ? '250px'
                          : '400px'
                        : isMobile
                        ? '250px'
                        : '100%',
                    zIndex: 1000,
                  }}
                  playsInline
                  id={Assets[index].alt}
                  loop
                >
                  <source src={Assets[index].src} type="video/mp4" />
                </video>
              </Stack>
              <Stack className={classes.lastTextContainer}>
                <Typography className={classes.whiteTitle}>{section.title}</Typography>
                <Typography
                  className={classes.description}
                  sx={{ color: `${ColorPalette.white} !important`, marginTop: '0px !important' }}
                >
                  {section.content}
                </Typography>
                <Button
                  variant="contained"
                  className={classes.greenButton}
                  onClick={() => push(section.href)}
                >
                  {section.button}
                </Button>
              </Stack>
            </Box>
          ))}
        </Slider>
      </Container>
    </Box>
  );
};

export default LastSection;
