import app from 'api';

const baseURL = 'https://app.stalk-exchange.com:3005/api';

export const AuthApi = {
  signUp: (req: any) => {
    return app.post(`${baseURL}/register/`, req);
  },
  signUpGoogle: (req: { code: string }) => {
    return app.post(`${baseURL}/google_login`, req);
  },
  signIn: (req: any): Promise<any> => {
    return app.post(`${baseURL}/login/`, req);
  },
  emailVerify: (req: any) => {
    return app.post(`${baseURL}/register/verify-email/`, req);
  },
  resetPassword: (req: any) => {
    return app.post(`${baseURL}/password/reset/`, req);
  },
  resetPasswordConfirm: (req: any) => {
    return app.post(`${baseURL}/password/reset/confirm/`, req);
  },
  getUser: () => {
    return app.get(`${baseURL}/user/`);
  },
  createBookmark: (id: number) => {
    return app.get(`${baseURL}/create_bookmark?asset=${id}`);
  },
  deleteBookmark: (id: number) => {
    return app.get(`${baseURL}/delete_bookmark?asset=${id}`);
  },
  getBookmarks: () => {
    return app.get(`${baseURL}/votes_and_data_for_table?bookmark=True`);
  },
  getUserVoteHistory: () => {
    return app.get(`${baseURL}/votes_of_user`);
  },
};
