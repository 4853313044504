import { useMutation } from 'react-query';
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';

import IconUp from 'assets/icons/IconUp';
import IconDown from 'assets/icons/IconDown';
import IconHold from 'assets/icons/IconHold';
import ColorPalette from 'utils/ColorPalette';
import { voteService } from 'services/stocks';
import IconThink from 'assets/icons/IconThink';
import IconGauge from 'assets/icons/IconGauge';
import { VoteType } from 'store/sentiment/types';
import '../pages/asset-list/components/styles.css';
import { Vote } from '../pages/asset-list/components/types';

//IF YOU WANT TO CHOOSE ICONS VERSION 1 PLEASE COMMENT THESE LINES:  90 , 102, 114

interface VoteSectionProps {
  id: number;
  ticker: string;
  voted: Vote;
  onVote: (el: Vote) => void;
  withPercent?: boolean;
}

const NewVoteSection: React.FC<VoteSectionProps> = ({ id, voted, onVote, withPercent }) => {
  const isMobile = useMediaQuery('(max-width:820px)');
  const { mutate } = useMutation(voteService, {
    onSuccess(res) {
      if (res) {
        onVote({
          isVoted: res.vote_outcome_within_revote_period !== 'not_voted',
          voteType: res.vote_outcome_within_revote_period,
          voteCount: res.vote_count,
          totalVotePercent: ((res.like_count - res.dislike_count) / res.vote_count) * 100,
          likePercent: Number((res?.like_count / res?.vote_count) * 100).toFixed(0),
          dislikePercent: Number((res?.dislike_count / res?.vote_count) * 100).toFixed(0),
          neutralPercent: Number((res?.neutral_count / res?.vote_count) * 100).toFixed(0),
        });
      }
    },
  });

  const onClickHandler = (type: VoteType) => mutate({ id: id, type });

  const stylePercent = {
    width: isMobile ? 25 : 36,
    height: isMobile ? 25 : 36,
    fontSize: isMobile ? '10px' : '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  };

  const VoteBarLocation = voted.totalVotePercent
    ? +voted.totalVotePercent === 0
      ? 50
      : +voted.totalVotePercent > 0
      ? +voted.totalVotePercent / 2 + 50
      : 50 + +voted.totalVotePercent / 2
    : 50;

  return (
    <Box
      sx={{
        backgroundColor: ColorPalette.pureWhite,
        borderRadius: '5px',
        display: 'grid',
        height: isMobile ? '36px' : '46px',
        minHeight: isMobile ? '36px' : '46px',
        gridTemplateColumns: `${
          !voted.isVoted
            ? isMobile
              ? '6fr 1.2fr 1.2fr 1.2fr'
              : '3fr 1fr 1fr 1fr'
            : isMobile
            ? withPercent
              ? '1fr 1fr 1fr 1fr'
              : '1fr 1fr 1fr'
            : withPercent
            ? '1fr 1fr 1fr 2fr'
            : '1fr 1fr 2fr'
        }`,
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 5,
      }}
    >
      {!voted.isVoted ? (
        <>
          <Stack direction="row" flex={2} alignItems="center">
            <Typography
              lineHeight={1.1}
              fontSize={isMobile ? '10px' : '15px'}
              maxWidth="130px"
              fontWeight="bold"
              marginLeft="10px"
            >
              What is your view?
            </Typography>
          </Stack>
          <IconDown
            svgSize={isMobile ? 12 : 20}
            onClick={() => onClickHandler('dislike')}
            sx={{
              backgroundColor: ColorPalette.newError,
              borderRadius: 0,
              minHeight: '100%',
              marginInline: '0px !important',
              padding: '2px !important',
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row-reverse',
            }}
          >
            <Typography
              fontSize={isMobile ? '10px' : '16px'}
              color="white"
              mr={isMobile ? '' : '10px'}
            >
              Sell
            </Typography>
          </IconDown>
          <IconHold
            onClick={() => onClickHandler('neutral')}
            svgSize={isMobile ? 12 : 20}
            svgColor={ColorPalette.darkBlue}
            sx={{
              backgroundColor: ColorPalette.pureWhite,
              color: ColorPalette.black,
              borderRadius: 0,
              minHeight: '100%',
              marginInline: '0px !important',
              padding: '2px !important',
              paddingTop: '6px !important',
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row-reverse',
            }}
          >
            <Typography
              fontSize={isMobile ? '10px' : '16px'}
              color={ColorPalette.darkBlue}
              mt={isMobile ? '2px' : ''}
              mr={isMobile ? '' : '10px'}
            >
              Hold
            </Typography>
          </IconHold>
          <IconUp
            onClick={() => onClickHandler('like')}
            svgSize={isMobile ? 12 : 20}
            sx={{
              backgroundColor: ColorPalette.newSuccess,
              borderRadius: '0px 5px 5px 0px',
              marginInline: '0px !important',
              minHeight: '100%',
              padding: '2px !important',
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row-reverse',
            }}
          >
            <Typography
              fontSize={isMobile ? '10px' : '16px'}
              color="white"
              mt="2px"
              mr={isMobile ? '' : '10px'}
            >
              Buy
            </Typography>
          </IconUp>
        </>
      ) : (
        <>
          <Stack
            direction="row"
            sx={{
              borderRight: `1px solid ${ColorPalette.primary}`,
              padding: '3px 10px 3px 3px',
            }}
          >
            {/*<IconThink svgSize={isMobile ? 15 : 25} />*/}
            <Typography
              fontWeight="bold"
              lineHeight={1.1}
              marginLeft="5px"
              fontSize={isMobile ? '10px' : '14px'}
            >
              User Sentiment
            </Typography>
          </Stack>
          <Stack direction="column" alignItems="center" marginX="5px">
            <Typography fontSize={isMobile ? '12px' : '16px'}>{voted.voteCount}</Typography>
            <Typography fontSize={isMobile ? '8px' : '12px'} noWrap>
              Users Voted
            </Typography>
          </Stack>
          {withPercent && (
            <Stack
              sx={{
                borderLeft: `1px solid ${ColorPalette.primary}`,
                padding: '3px 3px 3px 10px',
              }}
              gap={1}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                sx={{
                  ...stylePercent,
                  backgroundColor: ColorPalette.newSuccess,
                  color: ColorPalette.white,
                }}
              >
                {voted?.likePercent}%
              </Typography>
              <Typography
                sx={{
                  ...stylePercent,
                  backgroundColor: ColorPalette.greyPercent,
                  color: ColorPalette.white,
                }}
              >
                {voted?.neutralPercent}%
              </Typography>
              <Typography
                sx={{
                  ...stylePercent,
                  backgroundColor: ColorPalette.error,
                  color: ColorPalette.white,
                }}
              >
                {voted?.dislikePercent}%
              </Typography>
            </Stack>
          )}
          <Box
            style={{
              background:
                'linear-gradient(270deg, rgba(37,205,152,1) 0%, rgba(255,255,255,1) 50%, rgba(204,0,51,1) 100%)',
              height: '100%',
              width: '100%',
              borderRadius: '0px 5px 5px 0px',
              position: 'relative',
            }}
          >
            <div
              style={{
                position: 'absolute',
                left: `${VoteBarLocation === 100 ? (isMobile ? 88 : 96) : VoteBarLocation}%`,
                top: '-8px',
              }}
            >
              <div className="class1"></div>
              <div className="class2"></div>
              <div className="class3" style={{ backgroundColor: 'rgb(255, 255, 255)' }}></div>
            </div>
          </Box>
        </>
      )}
    </Box>
  );
};

export default NewVoteSection;
