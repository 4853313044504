import React from 'react';
import { Box, Typography } from '@mui/material';
import IconLogo from 'assets/icons/IconLogo';
import ColorPalette from 'utils/ColorPalette';

interface LogoProps {
  width?: number | string;
  height?: number | string;
  isHome?: boolean;
}

const Logo: React.FunctionComponent<LogoProps> = ({ isHome, ...props }) => {
  const { width, height } = props;
  return (
    <Box
      className="d-flex align-items-center"
      sx={{ backgroundColor: isHome ? '#fff' : 'inherit' }}
    >
      <IconLogo sx={{ width, height }} />
      <div className="d-flex flex-column ml-2">
        <Typography
          color="whitesmoke"
          variant="h4"
          sx={{ color: !isHome ? ColorPalette.white : ColorPalette.textPrimary }}
        >
          sTalk
        </Typography>
      </div>
    </Box>
  );
};

export default Logo;
