import React from 'react';
import { Box, Collapse, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import IconInformation from 'assets/icons/IconInformation';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import ColorPalette from 'utils/ColorPalette';
import IconDown from 'assets/icons/IconDown';
import IconHold from 'assets/icons/IconHold';
import IconUp from 'assets/icons/IconUp';
import useStyles from './_styles';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { userVoteHistory } from 'services/lists';

const VoteHistory: React.FunctionComponent = () => {
  const isMobile = useMediaQuery('(max-width:820px)');
  const classes = useStyles();
  const { push } = useHistory();
  const [viewHistory, setViewHistory] = React.useState('');
  const { data } = useQuery('voteHistory', userVoteHistory);

  const voteSum = [
    {
      type: 'dislike',
    },
    {
      type: 'neutral',
    },
    {
      type: 'like',
    },
  ];

  return (
    <React.Fragment>
      {Object.entries(data || {}).map(([key, value]) => (
        <div
          style={{
            backgroundColor: '#00254B',
            borderRadius: '10px',
            padding: '15px',
          }}
          key={key}
          className="w-100 d-flex mb-3 flex-column "
        >
          <Stack direction="row" className="align-items-center">
            <Box
              sx={{
                backgroundColor: ColorPalette.white,
                borderRadius: '5px',
                padding: isMobile ? '1px 4px' : '5px 8px',
                boxShadow:
                  '-3px 3px 5px 0px rgba(255, 255, 255, 0.35) inset, 2px -2px 2px 0px rgba(0, 0, 0, 0.25) inset',
              }}
              onClick={() => push(`/asset/${value[0]?.id}`)}
            >
              <IconInformation />
            </Box>
            <Stack direction="row" alignItems="baseline" ml={1}>
              <Typography
                lineHeight={1}
                color={ColorPalette.white}
                style={{ fontWeight: 'bold', fontSize: isMobile ? '16px' : ' 20px' }}
              >
                {value[0]?.ticker}
              </Typography>
              <Typography
                lineHeight={1}
                color={ColorPalette.white}
                style={{ fontSize: isMobile ? '14px' : '18px', marginLeft: '8px' }}
              >
                {key}
              </Typography>
            </Stack>
            {viewHistory !== key ? (
              <IconButton sx={{ ml: 'auto', padding: '0px' }} onClick={() => setViewHistory(key)}>
                <MoreHorizIcon sx={{ color: ColorPalette.white }} />
              </IconButton>
            ) : (
              <IconButton sx={{ ml: 'auto', padding: '0px' }} onClick={() => setViewHistory('')}>
                <CloseIcon sx={{ color: ColorPalette.white }} fontSize="small" />
              </IconButton>
            )}
          </Stack>
          <Stack direction="row" mt={1} sx={{ borderRadius: '5px', overflow: 'hidden' }}>
            {voteSum.map(vote => {
              const voteCount = value.filter(item => item.outcome === vote.type).length;
              return (
                <Stack
                  key={vote.type}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    backgroundColor:
                      vote.type === 'like'
                        ? ColorPalette.newSuccess
                        : vote.type === 'dislike'
                        ? ColorPalette.error
                        : ColorPalette.newGrey,
                    padding: '3px 8px',
                    flex: 1,
                    height: '32px',
                  }}
                >
                  <Typography
                    lineHeight={1}
                    color={ColorPalette.white}
                    style={{ fontSize: isMobile ? '12px' : '16px' }}
                  >
                    {voteCount}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
          <Collapse in={key === viewHistory}>
            {value.map((item, index) => (
              <Stack
                key={item.casting_date}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  borderRadius: '5px',
                  padding: '0px',
                  marginTop: '10px',
                }}
              >
                <Stack direction="row" alignItems="center">
                  <Typography
                    lineHeight={1}
                    color={ColorPalette.white}
                    sx={{
                      fontSize: isMobile ? '10px' : '12px',
                      borderRadius: '50%',
                      textAlign: 'center',
                      padding: '5px 8px',
                      marginRight: '10px',
                      border: `1px solid ${ColorPalette.white}`,
                    }}
                  >
                    {index + 1}
                  </Typography>
                  <Typography
                    lineHeight={1}
                    color={ColorPalette.white}
                    sx={{ fontSize: isMobile ? '14px' : '18px' }}
                  >
                    {item.casting_date}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  sx={{
                    minWidth: '40%',
                    height: isMobile ? '26px' : '30px',
                    borderRadius: '5px',
                    overflow: 'hidden',
                  }}
                >
                  <IconDown
                    svgSize={isMobile ? 12 : 30}
                    className={classes.voteButton}
                    sx={{
                      backgroundColor: ColorPalette.newError,
                      flexDirection: isMobile ? 'column' : 'row-reverse',
                      opacity: item.outcome === 'dislike' ? 1 : 0.5,
                    }}
                  />
                  <IconHold
                    svgSize={isMobile ? 12 : 30}
                    className={classes.voteButton}
                    sx={{
                      backgroundColor: ColorPalette.newGrey,
                      flexDirection: isMobile ? 'column' : 'row-reverse',
                      opacity: item.outcome === 'neutral' ? 1 : 0.5,
                    }}
                  />
                  <IconUp
                    svgSize={isMobile ? 12 : 30}
                    className={classes.voteButton}
                    sx={{
                      backgroundColor: ColorPalette.newSuccess,
                      flexDirection: isMobile ? 'column' : 'row-reverse',
                      opacity: item.outcome === 'like' ? 1 : 0.5,
                    }}
                  />
                </Stack>
              </Stack>
            ))}
          </Collapse>
        </div>
      ))}
    </React.Fragment>
  );
};

export default VoteHistory;
