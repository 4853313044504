import React from 'react';

export interface IAsset {
  src: string;
  alt: string;
}

interface RxtImgProps {
  asset: IAsset;
  className?: string;
  style?: React.CSSProperties;
  width?: number | string;
  height?: number | string;
}

const RxtImg: React.FunctionComponent<RxtImgProps> = ({ asset, ...props }) => {
  return <img src={asset.src} alt={asset.alt} {...props} />;
};

export default RxtImg;
