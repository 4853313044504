import React, { useState } from 'react';
import { Button, Dialog, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  paper: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    padding: '20px',
  },
});

function CookieModal() {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(!document.cookie.includes('acceptCookie=true'));

  const onCloseHandler = () => {
    document.cookie = 'acceptCookie=true';
    // 'acceptCookie=true; expires=Thu, 18 Dec 2013 12:00:00 UTC';
    setIsOpen(false);
  };
  return (
    <Dialog
      open={isOpen}
      classes={{ paper: classes.paper }}
      hideBackdrop
      sx={{
        bottom: 0,
      }}
    >
      <Typography sx={{ fontSize: '22px', marginBottom: '10px' }}>Cookies</Typography>
      <Typography sx={{ fontSize: '16px' }}>
        By using this website, you automatically accept <br />
        that we use cookies. <a href="/disclaimers">What for?</a>
      </Typography>
      <Button
        onClick={onCloseHandler}
        sx={{ marginTop: '20px', maxWidth: 'fit-content', paddingInline: '40px' }}
        variant="outlined"
      >
        Understood
      </Button>
    </Dialog>
  );
}

export default CookieModal;
