import React from 'react';
import { IIconProps } from './Icon.props';
import IconContainer from './IconContainer';

const IconFooter: React.FC<IIconProps> = ({ svgColor, ...props }) => {
  return (
    <IconContainer {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.801055 39.9904C0.589999 39.9904 0.388536 39.9089 0.23504 39.7554C0.0335772 39.5539 -0.0479674 39.2565 0.0287804 38.9831L2.51829 29.7062C0.868209 26.7514 0 23.3985 0 19.9976C0 8.9699 8.9699 0 19.9976 0C31.0253 0 39.9952 8.9699 39.9952 19.9976C39.9952 31.0253 31.0253 39.9952 19.9976 39.9952C16.5967 39.9952 13.2438 39.127 10.2842 37.4817L1.00731 39.9712C0.94016 39.9904 0.868209 40 0.801055 40V39.9904ZM10.3945 35.8172C10.5336 35.8172 10.6727 35.8556 10.7975 35.9228C13.5844 37.5345 16.7646 38.3883 19.9976 38.3883C30.1427 38.3883 38.3931 30.1379 38.3931 19.9928C38.3931 9.8477 30.1427 1.59731 19.9976 1.59731C9.8525 1.59731 1.60211 9.8525 1.60211 19.9976C1.60211 23.2306 2.45593 26.4156 4.06763 29.1977C4.17316 29.38 4.20194 29.6007 4.14918 29.8069L1.93788 38.0621L10.1931 35.8508C10.2602 35.8316 10.3322 35.822 10.3993 35.822L10.3945 35.8172Z"
          fill={svgColor ?? 'black'}
        />
        <path
          d="M27.2785 19.0399H12.7156C11.8618 19.0399 11.4301 18.0038 12.0393 17.3995L19.3207 10.118C19.6949 9.74387 20.3041 9.74387 20.6782 10.118L27.9596 17.3995C28.564 18.0038 28.1371 19.0399 27.2833 19.0399H27.2785Z"
          fill={svgColor ?? 'black'}
        />
        <path
          d="M27.9556 22.5912L20.6741 29.8726C20.3 30.2468 19.6908 30.2468 19.3166 29.8726L12.0352 22.5912C11.4308 21.9868 11.8577 20.9507 12.7115 20.9507H27.2744C28.1282 20.9507 28.5599 21.9868 27.9508 22.5912H27.9556Z"
          fill={svgColor ?? 'black'}
        />
      </svg>
    </IconContainer>
  );
};

export default IconFooter;
