import { useHistory } from 'react-router-dom';
import { Button, Stack, Container } from '@mui/material';

import Done from 'common/Done';
import style from './style.module.scss';

function ConfirmChangePass() {
  const { push } = useHistory();
  return (
    <Container className={style.subscribeContainer}>
      <Stack alignItems="center" justifyContent="center" display="flex" direction="column">
        <Done message="Your Password changed successfully!" detail="You can login now." />
        <Button
          variant="contained"
          onClick={() => push('/login')}
          color="secondary"
          sx={{
            borderRadius: '30px',
            marginTop: '40px',
            width: '200px',
            alignSelf: 'center',
            boxShadow:
              '2px -2px 2px 0px rgba(0, 0, 0, 0.25) inset, -3px 3px 5px 0px rgba(255, 255, 255, 0.35) inset',
          }}
        >
          login
        </Button>
      </Stack>
    </Container>
  );
}

export default ConfirmChangePass;
