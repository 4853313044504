import React, { memo } from 'react';
import FirstSection from './sections/FirstSection';
import SecondSection from './sections/SecondSection';
import ThirdSection from './sections/ThirdSection';
import FourthSection from './sections/FourthSection';
import LastSection from './sections/LastSection';
import HelpSection from './sections/HelpSection';

const Home: React.FunctionComponent = memo(() => {
  return (
    <div className="d-flex flex-column">
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <LastSection />
      <HelpSection />
    </div>
  );
});

export default Home;
