import { useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Button, Container, Input, Stack, Typography } from '@mui/material';

import { AuthApi } from 'api/Auth';
import style from './style.module.scss';
import ColorPalette from 'utils/ColorPalette';
import * as EmailValidator from 'email-validator';
import { WhiteTextTypography } from 'common/CustomTypography';

function ChangePass() {
  const { push } = useHistory();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const { mutate, isLoading } = useMutation('changePass', AuthApi.resetPassword, {
    onSuccess: () => {
      push('/change-password-request-success');
    },
    onError: err => {
      //@ts-ignore
      setError(err.response.data.detail);
    },
  });

  return (
    <Container className={style.subscribeContainer}>
      <Stack className="px-3">
        <WhiteTextTypography textAlign="center">
          Enter the email address associated with your account and we’ll send you a link to reset
          your password.
        </WhiteTextTypography>
        <WhiteTextTypography className="mt-5">Email</WhiteTextTypography>
        <Input
          value={email}
          onChange={e => setEmail(e.target.value)}
          type="email"
          placeholder="Email"
          disableUnderline
          sx={{
            backgroundColor: '#f2efea',
            border: ColorPalette.primary,
            borderRadius: '30px',
            height: '30px',
            paddingInline: '10px',
            marginTop: '10px',
            width: '100%',
            color: ColorPalette.primary,
            fontSize: '14px',
          }}
        />
        {error && <Typography sx={{ color: ColorPalette.error }}>{error}</Typography>}
        <Button
          variant="contained"
          onClick={() => mutate({ email: email })}
          disabled={!EmailValidator.validate(email) || isLoading}
          sx={{
            backgroundColor: ColorPalette.newSuccess,
            borderRadius: '30px',
            width: '200px',
            alignSelf: 'center',
            marginTop: '20px',
            boxShadow:
              '2px -2px 2px 0px rgba(0, 0, 0, 0.25) inset, -3px 3px 5px 0px rgba(255, 255, 255, 0.35) inset',
          }}
        >
          Continue
        </Button>
      </Stack>
    </Container>
  );
}

export default ChangePass;
