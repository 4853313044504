import React from 'react';
import { IIconProps } from './Icon.props';
import IconContainer from './IconContainer';

const IconFilterDown: React.FC<IIconProps> = ({ svgSize, ...props }) => {
  return (
    <IconContainer {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={svgSize ?? 20}
        height={svgSize ?? 20}
        viewBox="0 0 20 20"
        fill="none"
      >
        <path d="M16 19.0002V1" stroke="#004588" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M15.9998 19.0002L18.9248 16.0752"
          stroke="#004588"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.075 16.0752L16 19.0002"
          stroke="#004588"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M13 1H11" stroke="#004588" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 5H8.99996" stroke="#004588" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 9H4.99991" stroke="#004588" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 13H0.999871" stroke="#004588" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </IconContainer>
  );
};

export default IconFilterDown;
