import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useMediaQuery, Hidden } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip } from 'chart.js';

import ColorPalette from 'utils/ColorPalette';

interface ChartProps {
  chartData: number[];
  labels: string[];
}

const BarChart: React.FunctionComponent<ChartProps> = ({ chartData, labels }) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  ChartJS.defaults.font.size = isMobile ? 10 : 12;
  ChartJS.defaults.color = ColorPalette.offBlack;
  ChartJS.defaults.borderColor = ColorPalette.greyPercent;
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

  const data = {
    labels: labels,
    datasets: [
      {
        type: 'bar' as const,
        label: 'Number of news',
        data: chartData,
        stepped: true,
        backgroundColor: ColorPalette.darkBlue,
        yAxisID: 'y0',
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y0: {
        display: true,
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: true,
          text: 'News',
          color: ColorPalette.offBlack,
          font: {
            size: isMobile ? 12 : 20,
          },
        },
      },
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  return (
    <div
      style={{ paddingInlineEnd: '3rem' }}
      className="d-flex flex-column w-100 align-items-center mt-4"
    >
      <Hidden smDown>
        <Bar datasetIdKey="news" data={data} options={options} height={30} />
      </Hidden>
      <Hidden smUp>
        <Bar datasetIdKey="news" data={data} options={options} height={130} />
      </Hidden>
    </div>
  );
};
export default BarChart;
