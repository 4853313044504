import React from 'react';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import IconNews from 'assets/icons/IconNews';
import IconInformation from 'assets/icons/IconInformation';
import { setActiveTab } from 'store/informationPage/actions';
import ColorPalette from 'utils/ColorPalette';
import IconNewsSummary from 'assets/icons/IconNewsSummary';
import IconInfo from 'assets/icons/IconInfo';

interface DatesType {
  type: 'summary' | 'information' | 'charts' | 'news';
  text: string;
  icon: React.ReactNode;
}

const MobileView: React.FC = () => {
  const dispatch = useDispatch();
  const { activeTab } = useSelector((state: RootState) => state.InformationPage);

  const dates: DatesType[] = [
    { type: 'summary', text: 'Analysis', icon: <IconNewsSummary /> },
    { type: 'information', text: 'Company', icon: <IconInfo /> },
    { type: 'charts', text: 'Charts', icon: <IconInformation /> },
    { type: 'news', text: 'News', icon: <IconNews /> },
  ];

  return (
    <div
      className="d-flex flex-wrap p-2 justify-content-center w-100 align-items-center"
      style={{
        marginTop: '1rem',
        backgroundColor: ColorPalette.lightGray,
        borderRadius: '5px',
      }}
    >
      {dates.map(item => {
        return (
          <div
            key={item.type}
            className="d-flex align-items-center m-1"
            style={{
              height: 30,
              borderRadius: 5,
              border: `1px solid ${ColorPalette.white}`,
              width: '22%', //clamp(calc(100% / 2 - 22px), calc(100% / 2 - 22px), calc(100% / 2 - 22px))',
              cursor: 'pointer',
              opacity: 1,
              color: activeTab === item.type ? ColorPalette.pureWhite : ColorPalette.offBlack,
              backgroundColor:
                activeTab === item.type ? ColorPalette.greenBtn : ColorPalette.pureWhite,
              paddingTop: '1.5rem',
              paddingBottom: '1.5rem',
              margin: '1%',
            }}
            onClick={() => dispatch(setActiveTab(item.type))}
          >
            <Typography variant="body2" style={{ fontSize: '1.1rem', marginInline: 'auto' }}>
              {item.text}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};
export default MobileView;
