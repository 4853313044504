import * as Types from './types';

const initialState: Types.InformationPageState = {
  activeTab: 'summary',
};

function InformationPageReducer(
  state = initialState,
  actions: Types.InformationPageActions,
): Types.InformationPageState {
  switch (actions.type) {
    case Types.SET_ACTIVE_TAB:
      return { ...state, activeTab: actions.payload };
    default:
      return state;
  }
}

export default InformationPageReducer;
