import React from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ThreeCircles } from 'react-loader-spinner';
import { Container, useMediaQuery } from '@mui/material';

import { RootState } from 'store';
import MobileView from './view/mobile';
import DesktopView from './view/desktop';
import { ChartData } from 'models/Chart';
import ColorPalette from 'utils/ColorPalette';
import { ISearchAsset } from 'models/SearchAsset';
import { loadAssetChartData } from 'services/stocks';
import { BlackTextTypography } from 'common/CustomTypography';

const AssetDetails: React.FC = () => {
  // const { id } = useParams<{ id: string | undefined }>();
  const { ticker } = useParams<{ ticker: string | undefined }>();
  useSelector((state: RootState) => state.InformationPage.activeTab);
  const isXS = useMediaQuery('(max-width:800px)');
  const list_data = useSelector<RootState, ISearchAsset[]>(s => s.App.searchData);
  const asset: ISearchAsset | undefined = list_data.find(obj => obj.ticker === ticker);
  const asset_id = asset?.id ?? 1;
  const id_str = String(asset_id);
  const { data, isLoading } = useQuery<ChartData, Error>(
    ['chartDataAsset', id_str],
    () => loadAssetChartData(asset_id ? +asset_id : 0),
    { enabled: asset !== undefined, refetchInterval: 0 },
  );

  if (isLoading)
    return (
      <Container className="w-100 d-flex justify-content-center align-items-center">
        <ThreeCircles color="#1057C4" />
      </Container>
    );

  if (!data) {
    return (
      <Container
        className="d-flex h-100 w-50 px-4 flex-column mt-4"
        style={{
          borderRadius: '5px',
          padding: '4rem',
          backgroundColor: ColorPalette.lightGray,
        }}
      >
        <BlackTextTypography textAlign="center">
          Cannot find any stock with ticker symbol {ticker}.
        </BlackTextTypography>
      </Container>
    );
  }

  return (
    <>{isXS ? <MobileView data={data} id={id_str} /> : <DesktopView data={data} id={id_str} />}</>
  );
};

export default AssetDetails;
