import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Button, Container } from '@mui/material';
import { ThreeCircles } from 'react-loader-spinner';

import { AuthApi } from 'api/Auth';
import style from './style.module.scss';
import ColorPalette from 'utils/ColorPalette';
import { WhiteTextTypography } from 'common/CustomTypography';

const VerifyEmail: React.FunctionComponent = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const { push } = useHistory();
  const { isSuccess, isError } = useQuery(
    ['verify-email'],
    () => AuthApi.emailVerify({ key: id }),
    {
      enabled: !!id,
      retry: false,
    },
  );

  return (
    <Container
      className={`d-flex flex-column w-100 h-100 flex-column justify-content-center align-items-center ${style.subscribeContainer}`}
    >
      {isSuccess ? (
        <>
          <WhiteTextTypography variant="h5" textAlign="center">
            Email Verification!
          </WhiteTextTypography>
          <WhiteTextTypography textAlign="center" variant="body2" className="mt-3">
            Your email was verified. Login to continue using the website.
          </WhiteTextTypography>
          <Button
            variant="contained"
            onClick={() => push('/login')}
            sx={{
              backgroundColor: ColorPalette.newSuccess,
              borderRadius: '30px',
              marginTop: '30px',
              boxShadow:
                '2px -2px 2px 0px rgba(0, 0, 0, 0.25) inset, -3px 3px 5px 0px rgba(255, 255, 255, 0.35) inset',
            }}
          >
            Login
          </Button>
        </>
      ) : isError ? (
        <WhiteTextTypography variant="h5" textAlign="center">
          Your link is invalid or expired.please check login page.
        </WhiteTextTypography>
      ) : (
        <ThreeCircles color="#fff" />
      )}
    </Container>
  );
};

export default VerifyEmail;
