import React from 'react';
import { IconButton, Input, InputProps, Typography, useMediaQuery } from '@mui/material';

import ColorPalette from 'utils/ColorPalette';
import * as EmailValidator from 'email-validator';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEyeOutlined';

interface CustomInputProps extends InputProps {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  show: boolean;
  onEyeClick?: () => void;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  OnSubmit?: () => void;
  hasError?: boolean;
  errorMessages?: string;
}

function CustomInput({
  value,
  setValue,
  show,
  OnSubmit,
  onEyeClick,
  hasError,
  errorMessages,
  ...props
}: CustomInputProps) {
  const isMobile = useMediaQuery('(max-width:800px)');
  return (
    <>
      <Input
        sx={{
          backgroundColor: hasError ? '#fab6bd' : 'transparent',
          borderRadius: '30px',
          height: '50px',
          minHeight: '50px',
          paddingInline: '20px',
          marginTop: '10px',
          color: ColorPalette.offBlack,
          fontSize: '16px',
          display: show ? 'inherit' : 'none',
          border: hasError
            ? `1px solid  ${ColorPalette.error}`
            : `1px solid  ${ColorPalette.mediumGray}`,
        }}
        disableUnderline
        onChange={e => {
          setValue(e.target.value);
        }}
        placeholder="Type Here"
        value={value}
        endAdornment={
          <>
            {props.type === 'email' && EmailValidator.validate(value) ? (
              <span
                style={{
                  backgroundColor: ColorPalette.newSuccess,
                  color: ColorPalette.white,
                  padding: '3px 7px',
                  borderRadius: '20px',
                  marginRight: '-5px',
                  cursor: 'pointer',
                }}
                onClick={OnSubmit}
              >
                OK
              </span>
            ) : (
              <>
                {props.type === 'password' && (
                  <IconButton
                    sx={{ color: 'black', padding: '0' }}
                    onClick={() => {
                      if (onEyeClick) {
                        onEyeClick();
                      }
                    }}
                  >
                    <RemoveRedEyeIcon sx={{ color: ColorPalette.white }} />
                  </IconButton>
                )}
              </>
            )}
          </>
        }
        {...props}
      />
      {errorMessages && hasError && (
        <Typography
          sx={{ color: ColorPalette.error, marginTop: '5px', marginLeft: '8px' }}
          fontSize={isMobile ? '12px' : '14px'}
        >
          {errorMessages}
        </Typography>
      )}
    </>
  );
}

export default CustomInput;
