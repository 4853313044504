import clsx from 'clsx';
import React from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { Container } from '@mui/material';

import { ChartData } from 'models/Chart';
import NewsTab from '../components/NewsTab';
import LineChart from '../components/LineChart';
import TabsContainer from '../components/TabsContainer';
import HeaderSummary from '../components/HeaderSummary';
import SummaryNewsTab from '../components/SummaryNewsTab';
import InformationTab from '../components/InformationTab';
import VoteSectionMobile from '../components/NewsSentimentSection';

const DesktopView: React.FC<{ data: ChartData; id?: string }> = ({ data, id }) => {
  const { activeTab } = useSelector((state: RootState) => state.InformationPage);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center mt-2">
      <HeaderSummary data={data} withoutBackButton />
      <VoteSectionMobile ticker={data.ticker} id={id ? +id : 0} withPercent />
      <TabsContainer />
      {activeTab === 'summary' && <SummaryNewsTab id={id} />}
      {activeTab === 'information' && <InformationTab id={id} />}
      {activeTab === 'news' && <NewsTab id={id} />}
      {activeTab === 'charts' && (
        <LineChart chartData={data} id={id ? +id : 0} ticker={data.ticker} />
      )}
    </div>
  );
};
export default DesktopView;
