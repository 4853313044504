import { FC } from 'react';
import clsx from 'clsx';
import { IconButtonProps, IconButton } from '@mui/material';
import classes from './style.module.scss';

const IconContainer: FC<IconButtonProps> = ({ children, ...props }) => {
  return (
    <IconButton {...props} className={clsx(classes.icon, props.className)} disableRipple>
      {children}
    </IconButton>
  );
};

export default IconContainer;
