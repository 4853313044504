import React from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { useMediaQuery, Hidden } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import BarChart from './BarChartNews';
import { ChartData } from 'models/Chart';
import MovingAverageMobile from './MovingAverageMobile';
import DateSections from './SetDateSectionMobile';
import ColorPalette from 'utils/ColorPalette';
import { hexToRgba } from 'utils/helper';

interface ChartProps {
  chartData: ChartData;
  id: number;
  ticker: string;
}

const LineChart: React.FunctionComponent<ChartProps> = ({ chartData }) => {
  const isMobile = useMediaQuery('(max-width:960px)');
  ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
  );
  ChartJS.defaults.font.size = isMobile ? 10 : 12;
  ChartJS.defaults.color = ColorPalette.offBlack;
  ChartJS.defaults.borderColor = ColorPalette.greyPercent;
  ChartJS.defaults.elements.line.borderWidth = 2;
  ChartJS.defaults.elements.line.borderJoinStyle = 'round';
  const { dateRange, show10daysMA, show5daysMA } = useSelector(
    (state: RootState) => state.LineChart,
  );

  const data = {
    labels: chartData.date.slice(-dateRange),
    datasets: [
      {
        label: 'Price (USD)',
        data: chartData.price.slice(-dateRange),
        borderColor:
          show10daysMA || show5daysMA
            ? hexToRgba(ColorPalette.darkBlue, 0.4)
            : ColorPalette.darkBlue,
        yAxisID: 'y',
      },
      {
        label: 'Price (USD)',
        data: chartData.price5MovingAvg.slice(-dateRange),
        borderColor: ColorPalette.darkBlue,
        hidden: !show5daysMA,
        yAxisID: 'y',
      },
      {
        label: 'Price (USD)',
        data: chartData.price10MovingAvg.slice(-dateRange),
        borderColor: ColorPalette.darkBlue,
        hidden: !show10daysMA,
        yAxisID: 'y',
      },
      {
        label: 'Sentiment',
        data: chartData.sentiment.slice(-dateRange),
        borderColor:
          show10daysMA || show5daysMA ? hexToRgba(ColorPalette.softRed, 0.4) : ColorPalette.softRed,
        yAxisID: 'y1',
      },
      {
        label: 'Sentiment',
        data: chartData.sentiment5MovingAvg.slice(-dateRange),
        borderColor: ColorPalette.softRed,
        hidden: !show5daysMA,
        yAxisID: 'y1',
      },
      {
        type: 'line' as const,
        label: 'Sentiment',
        data: chartData.sentiment10MovingAvg.slice(-dateRange),
        borderColor: ColorPalette.softRed,
        hidden: !show10daysMA,
        yAxisID: 'y1',
      },
    ],
  };

  const options = {
    responsive: true,
    elements: {
      point: {
        radius: 0,
      },
    },
    animation: {
      duration: 0, // Disable animation
    },
    scales: {
      x: {
        ticks: {
          display: false,
          color: ColorPalette.offBlack,
        },
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        display: true,
        offset: true,
        beginAtZero: false,
        position: 'left' as const,
        ticks: {
          color: ColorPalette.offBlack,
        },
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: true,
          text: 'Price (USD)',
          color: ColorPalette.darkBlue, //'#e8cf76',
          font: {
            size: isMobile ? 12 : 20,
          },
        },
      },
      y1: {
        display: true,
        offset: true,
        min: -1,
        max: 1.5,
        position: 'right' as const,
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          color: ColorPalette.offBlack,
        },
        title: {
          display: true,
          text: 'Sentiment',
          color: '#db5c48',
          font: {
            size: isMobile ? 12 : 20,
          },
        },
      },
    },
  };

  return (
    <div
      className="d-flex flex-column w-100 align-items-center"
      style={{
        padding: '1rem',
        backgroundColor: ColorPalette.offWhite,
      }}
    >
      <DateSections />
      <Hidden smDown>
        <Line datasetIdKey="fund" data={data} options={options} height={100} />
      </Hidden>
      <Hidden smUp>
        <Line datasetIdKey="fund" data={data} options={options} height={300} />
      </Hidden>
      <BarChart
        chartData={chartData.news.slice(-dateRange)}
        labels={chartData.date.slice(-dateRange)}
      />
      <MovingAverageMobile />
    </div>
  );
};
export default LineChart;
