import React from 'react';
import { useHistory } from 'react-router-dom';
import CustomSheet from 'common/Sheet';
import SideBarHeader from './SidBarHeader';
import ListItems from './ListItems';
import SideBarFooter from './SideBarFooter';
import { Button, Typography } from '@mui/material';
import ColorPalette from 'utils/ColorPalette';
import { isLoggedIn } from 'utils/helper';
import { useQuery } from 'react-query';
import { AuthApi } from 'api/Auth';

interface DrawerProps {
  open: boolean;
  onClose: () => void;
}
const style = {
  fontSize: '14px',
  borderRadius: '30px',
  width: '100%',
  height: '50px',
  padding: '0px 35px',
  fontWeight: '400',
  marginBottom: 'auto',
};

const Drawer: React.FunctionComponent<DrawerProps> = ({ open, onClose }) => {
  const { push } = useHistory();
  const isLogged = isLoggedIn();
  const { data } = useQuery(['user-data'], AuthApi.getUser);

  return (
    <CustomSheet
      onOpenChange={onClose}
      open={open}
      anchor="bottom"
      PaperProps={{
        className: 'd-flex align-items-center flex-column',
        style: { height: '100vh', overflowY: 'hidden', paddingInline: '15px' },
      }}
    >
      <SideBarHeader onClose={onClose} />
      <ListItems onClose={onClose} />
      {isLogged && (
        <Typography
          sx={{
            fontSize: '14px',
            width: '100%',
            border: 'none',
            // 1px solid ${ColorPalette.primary}`,
            //borderRadius: '30px',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '10px',
          }}
          color={ColorPalette.textPrimary}
          noWrap
        >
          {`Welcome ${data?.data?.email ? ` ${data?.data?.email[0].toUpperCase()}` : ''}`}
        </Typography>
      )}
      <Button
        variant="contained"
        onClick={() => {
          if (!isLogged) {
            onClose();
            push('/login');
          } else {
            onClose();
            localStorage.clear();
            window.location.reload();
          }
        }}
        sx={{
          backgroundColor: isLogged ? ColorPalette.newError : ColorPalette.newSuccess,
          ...style,
        }}
      >
        {isLogged ? 'Log out' : 'Login'}
      </Button>
      <SideBarFooter />
    </CustomSheet>
  );
};
export default Drawer;
