import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Stack, Button } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { ChartData } from 'models/Chart';
import ColorPalette from 'utils/ColorPalette';

const HeaderSummary: React.FC<{ data: ChartData; withoutBackButton?: boolean }> = ({
  data,
  withoutBackButton = false,
}) => {
  const { push } = useHistory();
  const color = (percent: number) =>
    percent > 0 ? ColorPalette.newSuccess : percent === 0 ? ColorPalette.grey : ColorPalette.error;
  const padding = withoutBackButton ? '0rem' : '1rem';

  return (
    <div className="w-100" style={{ padding: '1rem' }}>
      {!withoutBackButton && (
        <Button
          color="secondary"
          variant="outlined"
          sx={{
            color: ColorPalette.offBlack,
            borderColor: ColorPalette.offBlack,
            height: '28px !important',
            fontSize: '10px',
            paddingInline: '10px',
            width: 'fit-content',
            marginBottom: '1rem',
          }}
          onClick={() => push('/list')}
          startIcon={<ArrowBackIosIcon sx={{ fontSize: '10px !important' }} />}
        >
          Back to list
        </Button>
      )}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack>
          <Typography color={ColorPalette.offBlack} fontSize="21px" fontWeight={600}>
            {data.name}
          </Typography>
          <Typography mt="-5px" color={ColorPalette.offBlack} fontSize="15px">
            Ticker: {data.ticker}
          </Typography>
        </Stack>
        <Stack>
          <Stack direction="column" alignItems="flex-end">
            <Typography color={ColorPalette.offBlack} fontSize="15px" fontWeight={500}>
              {+data.price[data.price.length - 1].toFixed(2)} $
            </Typography>
            <Typography
              style={{
                color: color(+data.priceReturn[data.priceReturn.length - 1].toFixed(2)),
                fontSize: '12px',
              }}
              fontWeight="bold"
            >
              {(+data.priceReturn[data.priceReturn.length - 1] * 100).toFixed(2)} %
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};
export default HeaderSummary;
