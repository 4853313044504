import React, { CSSProperties, ReactNode } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { ThreeDots } from 'react-loader-spinner';

import ColorPalette from 'utils/ColorPalette';

export interface InfiniteScrollProps {
  onFetchMore: () => void;
  hasMore: boolean;
  containerComponent?: ReactNode;
  containerClassName?: string;
  containerStyle?: CSSProperties;
}

const InfiniteScroll: React.FunctionComponent<InfiniteScrollProps> = ({
  onFetchMore,
  children,
  hasMore,
  containerComponent: ContainerComponent,
  containerClassName,
  containerStyle,
}) => {
  const containerRef = useBottomScrollListener(onFetchMore);

  if (ContainerComponent) {
    return (
      // @ts-ignore
      <ContainerComponent ref={containerRef} className={containerClassName} style={containerStyle}>
        {children}
        {hasMore && <ThreeDots />}
      </ContainerComponent>
    );
  }

  return (
    <>
      {children}
      {hasMore && <ThreeDots color={ColorPalette.white} />}
    </>
  );
};

export default InfiniteScroll;
