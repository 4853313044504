import { VoteType } from 'store/sentiment/types';
import { IAsset } from 'common/Image';
export interface VoteListResponse {
  id: number;
  name: string;
  ticker: string;
  like_count: number;
  dislike_count: number;
  neutral_count: number;
  vote_count: number;
  change_price: number;
  change_price_return: number;
  change_sentiment_return: number;
  change_sentiment: number;
  change_num_of_references: number;
  change_reference_return: number;
  vote_outcome_within_revote_period: VoteType;
}

export interface Faces {
  id: number;
  type: VoteType;
  asset: IAsset;
  color: string;
}

export interface IVoteSlide {
  id: number;
  name: string;
  symbol: string;
  likeCount: number;
  dislikeCount: number;
  neutralCount: number;
  voteCount: number;
  referenceReturn: number;
  sentimentReturn: number;
  price: number;
  priceReturn: number;
  visitorVoted: number;
}

export class VoteSlide {
  id: number;
  name: string;
  symbol: string;
  likeCount: number;
  dislikeCount: number;
  neutralCount: number;
  sentiment: number;
  voteCount: number;
  referenceReturn: number;
  sentimentReturn: number;
  referenceNumber: number;
  price: number;
  priceReturn: number;
  visitorVoted: VoteType;
  constructor(props: VoteListResponse) {
    this.id = props.id;
    this.name = props.name;
    this.price = props.change_price;
    this.symbol = props.ticker;
    this.likeCount = props.like_count;
    this.dislikeCount = props.dislike_count;
    this.neutralCount = props.neutral_count;
    this.voteCount = props.vote_count;
    this.dislikeCount = props.dislike_count;
    this.referenceReturn = props.change_reference_return;
    this.referenceNumber = props.change_num_of_references;
    this.sentiment = props.change_sentiment;
    this.sentimentReturn = props.change_sentiment_return;
    this.priceReturn = props.change_price_return;
    this.visitorVoted = props.vote_outcome_within_revote_period;
  }

  get likePercent(): number {
    const percent = Number(((+this.likeCount / this.voteCount) * 100).toFixed(2));
    return Number.isNaN(percent) ? 0 : percent;
  }

  get dislikePercent(): number {
    const percent = Number(((+this.dislikeCount / this.voteCount) * 100).toFixed(2));
    return Number.isNaN(percent) ? 0 : percent;
  }

  get neutralPercent(): number {
    const percent = Number(((+this.neutralCount / this.voteCount) * 100).toFixed(2));
    return Number.isNaN(percent) ? 0 : percent;
  }

  get sumPercent(): number {
    return Number(this.neutralPercent + this.likePercent);
  }
}
