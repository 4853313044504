import { Link, useLocation } from 'react-router-dom';
import { Stack, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { menuItems } from '../../components/NavbarItems';
import ColorPalette from 'utils/ColorPalette';

function ListItems({ onClose }: { onClose: () => void }) {
  const { pathname } = useLocation();

  return (
    <Stack direction="column" className="w-100 my-auto ">
      {menuItems.map(item => (
        <ListItemButton
          sx={{
            '& span': {
              fontSize: '24px',
              color: ColorPalette.primary,
              maxHeight: '60px',
            },
            borderBottom: `1px solid ${ColorPalette.primary}`,
            display: 'flex',
            alignItems: 'center',
            paddingInline: '0px',
          }}
          key={item.id}
          component={Link}
          href={item.href}
          to={item.href}
          onClick={onClose}
        >
          <ListItemIcon
            sx={{
              padding: '0px',
              minWidth: '30px',
            }}
          >
            <ArrowRightIcon
              sx={{
                color: item.href === pathname ? ColorPalette.primary : 'transparent',
                fontSize: '30px',
                marginLeft: '-10px',
              }}
            />
          </ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItemButton>
      ))}
    </Stack>
  );
}

export default ListItems;
