import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'store';
import { ChartData } from 'models/Chart';
import NewsTab from '../components/NewsTab';
import LineChart from '../components/LineChart';
import TabsContainer from '../components/TabsContainer';
import InformationTab from '../components/InformationTab';
import SummaryNewsTab from '../components/SummaryNewsTab';
import MobileHeaderSummary from '../components/HeaderSummary';
import VoteSectionMobile from '../components/NewsSentimentSection';

const MobileView: React.FC<{ data: ChartData; id?: string }> = ({ data, id }) => {
  const { activeTab } = useSelector((state: RootState) => state.InformationPage);

  return (
    <div className="d-flex h-100 flex-column mt-3">
      <MobileHeaderSummary data={data} />
      <VoteSectionMobile ticker={data.ticker} id={id ? +id : 0} withPercent />
      <TabsContainer />
      {activeTab === 'summary' && <SummaryNewsTab id={id} />}
      {activeTab === 'information' && <InformationTab id={id} />}
      {activeTab === 'news' && <NewsTab id={id} />}
      {activeTab === 'charts' && (
        <LineChart chartData={data} id={id ? +id : 0} ticker={data.ticker} />
      )}
    </div>
  );
};
export default MobileView;
