/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import app, { VoteReq } from 'api';

// const baseURL = `http://127.0.0.1:8000/api`;
const baseURL = `https://app.stalk-exchange.com:3005/api`;
// const baseURL = 'http://3.135.195.173:3000/api';

export const DataApi = {
  getSearchList: () => {
    return app.get(`${baseURL}/assets`);
  },

  getNews: (id: number) => {
    return app.get(`${baseURL}/news_for_asset/?asset_id=${id}`);
  },

  getNewsSummary: (id: number) => {
    return app.get(`${baseURL}/summary_news_for_asset/?asset_id=${id}`);
  },

  getStockInformation: (id: number) => {
    return app.get(`${baseURL}/staticinfo_for_asset/?asset_id=${id}`);
  },

  getAssetVoteData: (id: number): Promise<any> => {
    return app.get(`${baseURL}/votes_for_asset/?asset_id=${id}`);
  },

  getChartData: (id: number): Promise<any> => {
    return app.get(`${baseURL}/rawdata-for-asset/${id}`);
  },

  getVoteStockList: () => {
    return app.get(`${baseURL}/votes_and_data_for_slider`);
  },
  Vote: (req: VoteReq) => {
    return app.get(`${baseURL}/vote/?asset_id=${req.id}&vote_type=${req.type}`);
  },

  //@ts-ignore
  getList: (req: any) => {
    let filters = '';
    if (req.asset_id) {
      filters += `&asset_id=${req.asset_id}`;
    }
    if (req.sortby) {
      filters += `&sortby=${req.sortby}`;
    }
    if (req.dir) {
      filters += `&dir=${req.dir}`;
    }
    if (req.bookmark) {
      filters += `&bookmark=${req.bookmark}`;
    }
    return app.get(`${baseURL}/votes_and_data_for_table/?page=${req.page}&pagesize=10${filters}`);
  },
};
