import React from 'react';
import { IIconProps } from './Icon.props';
import IconContainer from './IconContainer';

const IconInformation: React.FC<IIconProps> = ({ svgSize, svgColor, ...props }) => {
  return (
    <IconContainer {...props}>
      <svg
        width={svgSize ?? 15}
        height={svgSize ?? 15}
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2173_3093)">
          <path
            d="M0.6 14.4V0H0V14.7C0 14.865 0.135 15 0.3 15H15V14.4H0.6Z"
            fill={svgColor ?? '#004588'}
          />
          <path
            d="M6.85817 7.66455L7.91567 8.60655C8.03417 8.71305 8.21567 8.70705 8.32667 8.59455L12.8987 4.02255V5.54805H13.4987V3.29805C13.4987 3.13305 13.3637 2.99805 13.1987 2.99805H10.9487V3.59805H12.4742L8.10317 7.96905L7.04567 7.02705C6.92717 6.92055 6.74567 6.92655 6.63467 7.03905L1.88867 11.785L2.31317 12.2095L6.85967 7.66305L6.85817 7.66455Z"
            fill={svgColor ?? '#004588'}
          />
        </g>
        <defs>
          <clipPath id="clip0_2173_3093">
            <rect width="15" height="15" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </IconContainer>
  );
};

export default IconInformation;
