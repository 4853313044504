import React from 'react';
import { IIconProps } from './Icon.props';
import IconContainer from './IconContainer';

const LockLogo: React.FC<IIconProps> = ({ ...props }) => {
  return (
    <IconContainer {...props}>
      <svg
        width="35"
        height="35"
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="17.5" cy="17.5" r="17.5" fill="#D9D9D9" />
        <path
          d="M12.902 25.9667C12.4584 25.9667 12.0788 25.8089 11.7633 25.4933C11.4477 25.1778 11.2896 24.7979 11.2891 24.3538V16.2893C11.2891 15.8458 11.4471 15.4662 11.7633 15.1506C12.0794 14.835 12.459 14.6769 12.902 14.6764H13.7084V13.0635C13.7084 11.9479 14.1017 10.9971 14.8883 10.2111C15.6748 9.42507 16.6256 9.03179 17.7407 9.03125C18.8557 9.03071 19.8068 9.42399 20.5939 10.2111C21.381 10.9982 21.774 11.949 21.7729 13.0635V14.6764H22.5794C23.0229 14.6764 23.4028 14.8345 23.7189 15.1506C24.035 15.4667 24.1928 15.8463 24.1923 16.2893V24.3538C24.1923 24.7974 24.0345 25.1772 23.7189 25.4933C23.4033 25.8095 23.0235 25.9673 22.5794 25.9667H12.902ZM17.7407 21.9345C18.1842 21.9345 18.5641 21.7767 18.8802 21.4611C19.1963 21.1455 19.3541 20.7657 19.3536 20.3216C19.353 19.8775 19.1952 19.4979 18.8802 19.1829C18.5651 18.8678 18.1853 18.7097 17.7407 18.7087C17.2961 18.7076 16.9165 18.8657 16.602 19.1829C16.2874 19.5001 16.1294 19.8796 16.1278 20.3216C16.1262 20.7635 16.2842 21.1433 16.602 21.4611C16.9197 21.7788 17.2993 21.9366 17.7407 21.9345ZM15.3213 14.6764H20.16V13.0635C20.16 12.3915 19.9248 11.8202 19.4544 11.3498C18.984 10.8794 18.4127 10.6442 17.7407 10.6442C17.0686 10.6442 16.4974 10.8794 16.027 11.3498C15.5565 11.8202 15.3213 12.3915 15.3213 13.0635V14.6764Z"
          fill="#003366"
        />
      </svg>
    </IconContainer>
  );
};

export default LockLogo;
