export type DataRange = 30 | 180 | 365;

export interface LineChartState {
  dateRange: DataRange;
  show5daysMA: boolean;
  show10daysMA: boolean;
}

export const SET_DATA_RANGE = 'SET_DATA_RANGE';
export const SET_SHOW_5D_MA = 'SET_SHOW_5D_MA';
export const SET_SHOW_10D_MA = 'SET_SHOW_10D_MA';

interface setDataRange {
  type: typeof SET_DATA_RANGE;
  payload: DataRange;
}
interface setShow5D {
  type: typeof SET_SHOW_5D_MA;
  payload: boolean;
}

interface setShow10D {
  type: typeof SET_SHOW_10D_MA;
  payload: boolean;
}

export type LineChartActionType = setDataRange | setShow5D | setShow10D;
