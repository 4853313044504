import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import LockIcon from '@mui/icons-material/Lock';
import {
  Input,
  ButtonBase,
  Typography,
  IconButton,
  Button,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { RootState } from 'store';
import makeStyles from '@mui/styles/makeStyles';
import CancelIcon from '@mui/icons-material/Cancel';

import { isLoggedIn } from 'utils/helper';
import ColorPalette from 'utils/ColorPalette';
import { ISearchAsset } from 'models/SearchAsset';
import IconFilterUp from 'assets/icons/IconFilterUp';
import IconFilterDown from 'assets/icons/IconFilterDown';

interface FilterSectionProps {
  onChangeSort: ({ sortby, dir }: { sortby: string; dir: string }) => void;
  onChangeAssetId: (data: string) => void;
  filters: any;
  disabled?: boolean;
  setShowLoginModal: (val: boolean) => void;
}

const useStyles = makeStyles(theme => ({
  searchBarMobile: {
    border: `0px solid ${ColorPalette.white} `,
    borderRadius: '0px',
    flex: 1,
    paddingInline: '0px',
  },
}));

const FilterSection: React.FunctionComponent<FilterSectionProps> = ({
  onChangeSort,
  onChangeAssetId,
  filters,
  disabled = false,
  setShowLoginModal,
}) => {
  const isLogged = isLoggedIn();
  const data = useSelector<RootState, ISearchAsset[]>(s => s.App.searchData);
  const [search, setSearch] = useState<string>();
  const [searchList, setSearchList] = useState<any[] | undefined>([]);
  const [ShowToast, setShowToast] = useState<boolean>(false);
  const isMobile = useMediaQuery('(max-width:720px)');
  const classes = useStyles();

  const SearchHandler = (e: string) => {
    setSearch(e);
    const filteredData = data?.filter(
      el =>
        el.name.toLowerCase().startsWith(e.toLowerCase()) ||
        el.ticker.toLowerCase().startsWith(e.toLowerCase()),
    );
    setSearchList(e === '' ? data : filteredData);
    if (search === '') {
      onChangeAssetId('');
    }
    if (filteredData.length === 0) {
      setShowToast(true);
    } else {
      setShowToast(false);
    }
  };
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    //@ts-ignore
    const handleOutSideClick = event => {
      if (!ref.current?.contains(event.target)) {
        setSearchList([]);
      }
    };

    window.addEventListener('mousedown', handleOutSideClick);

    return () => {
      window.removeEventListener('mousedown', handleOutSideClick);
    };
  }, [ref]);

  const SortButtons = [
    { id: 1, key: 'sentiment', title: 'Sentiment Change' },
    { id: 2, key: 'news', title: 'News Count Change' },
    { id: 3, key: 'price', title: 'Price Change' },
  ];

  const onClickHandler = (sortType: string) => {
    onChangeSort({
      sortby: sortType,
      dir:
        filters.dir === 'desc' && sortType === filters.sortby
          ? 'ascen'
          : filters.dir === 'ascen' && sortType === filters.sortby
          ? 'desc'
          : 'desc',
    });
  };

  return (
    <div
      ref={ref}
      className={'d-flex flex-column justify-content-between w-100 mb-3 pt-2 position-relative'}
    >
      <Stack
        // sx={{ display: 'none' }}
        direction="row"
        display="flex"
        mb={isMobile ? '1rem' : '1rem'}
      >
        {SortButtons.map(btn => (
          <Button
            key={btn.id}
            sx={{
              backgroundColor:
                disabled || !isLogged
                  ? ColorPalette.disableBtn
                  : filters.sortby === btn.key
                  ? ColorPalette.white
                  : ColorPalette.disableBtn,
              color: ColorPalette.darkBlue,
              opacity: disabled || !isLogged ? 0.4 : 1,
              fontSize: isMobile ? '10px' : '16px',
              paddingInline: '5px',
              borderRadius: '5px',
              marginInline: btn.key === 'news' ? '7px' : '0',
              minWidth: isMobile ? '30%' : undefined,
              height: isMobile ? '30px' : '45px',
              alignItems: 'center',
              flex: 1,
              '&:hover': {
                backgroundColor: disabled || !isLogged ? ColorPalette.disableBtn : '#fff',
                color: disabled || !isLogged ? ColorPalette.primary : '#3c52b2',
              },
            }}
            onClick={() => {
              if (!isLogged) {
                setShowLoginModal(true);
              } else if (!disabled) {
                onClickHandler(btn.key);
              }
            }}
            variant="contained"
            endIcon={IconFilterUp}
          >
            {!isLogged && <LockIcon sx={{ fontSize: isMobile ? '16px' : '2rem' }} />}
            <Typography
              fontWeight="bold"
              sx={{
                marginInline: !isLogged ? 'auto' : 'inherit',
                fontSize: isMobile ? '8px' : 'inherit',
              }}
            >
              {btn.title}
            </Typography>
            {filters.sortby === btn.key && isLogged ? (
              filters.dir === 'desc' ? (
                <IconFilterUp svgSize={15} sx={{ margin: '0 0 0 10px !important' }} />
              ) : (
                <IconFilterDown svgSize={15} sx={{ margin: '0 0 0 10px !important' }} />
              )
            ) : null}
          </Button>
        ))}
      </Stack>
      <div style={{ padding: '10px', backgroundColor: ColorPalette.lightGray }}>
        <Input
          className={classes.searchBarMobile}
          disableUnderline
          placeholder="Search stocks"
          style={{
            float: 'right',
            minWidth: '50%',
          }}
          inputProps={{
            style: {
              fontSize: isMobile ? '10px' : '18px',
              minHeight: isMobile ? '20px' : '30px',
              maxHeight: isMobile ? '20px' : '35px',
              borderRadius: '5px',
              height: '50px',
              backgroundColor: ColorPalette.pureWhite,
              paddingLeft: '1rem',
              WebkitTextFillColor: ColorPalette.pureBlack,
            },
          }}
          onChange={e => SearchHandler(e.target.value)}
          value={search}
          disabled={disabled}
          endAdornment={
            <>
              {search && (
                <IconButton
                  style={{
                    marginLeft: '1rem',
                    marginInline: 0,
                    paddingInline: 0,
                    paddingBlock: '2px',
                  }}
                  onClick={() => {
                    onChangeAssetId('');
                    setSearch('');
                  }}
                >
                  <CancelIcon
                    style={{ color: ColorPalette.grey, fontSize: isMobile ? '13px' : '24px' }}
                  />
                </IconButton>
              )}
            </>
          }
        />
      </div>
      {searchList && searchList.length > 0 && (
        <div
          id="search-view"
          style={{
            position: 'absolute',
            backgroundColor: ColorPalette.primary + 'DD',
            border: '1px solid white',
            maxHeight: '200px',
            overflow: 'auto',
            display: 'flex',
            top: isMobile ? '90px' : '136px',
            flexDirection: 'column',
            // width: isMobile ? 'calc(100% - 32px)' : 'calc(100% - 22px)',
            width: '100%',
            zIndex: 100000,
          }}
        >
          {searchList?.map(el => (
            <ButtonBase
              key={el.id}
              style={{ borderBottom: '1px solid #fff' }}
              className="w-100 d-flex justify-content-start my-1 ps-2 pb-1"
              onClick={() => {
                if (el.id) {
                  onChangeAssetId(el.id);
                  setSearch(el.name);
                } else {
                  onChangeAssetId('');
                }
                setSearchList(undefined);
              }}
            >
              <Typography
                style={{ color: ColorPalette.white, fontSize: isMobile ? '12px' : '16px' }}
                key={el}
              >
                {el.name} ({el.ticker})
              </Typography>
            </ButtonBase>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterSection;
