import React, { useState } from 'react';
import { Button, Menu, MenuItem, Typography, useMediaQuery } from '@mui/material';
import ColorPalette from 'utils/ColorPalette';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useQuery } from 'react-query';
import { AuthApi } from 'api/Auth';
import { useLocation } from 'react-router-dom';

interface MenuItem {
  id: number;
  text: string;
  href: string;
}

export const menuItems: MenuItem[] = [
  { id: 1, text: 'Home', href: '/' },
  { id: 2, text: 'Stocks', href: '/list' },
  { id: 3, text: 'About Us', href: '/about-us' },
  { id: 4, text: 'Disclaimers', href: '/disclaimers' },
];

const LoginDropDown: React.FunctionComponent = () => {
  const { data } = useQuery(['user-data'], AuthApi.getUser);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isMobile = useMediaQuery('(max-width:800px)');
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  document.addEventListener('scroll', () => {
    if (anchorEl) {
      setAnchorEl(null);
    }
  });

  return (
    <>
      <Button
        variant="contained"
        sx={{
          fontSize: '0.875rem',
          borderRadius: '5px',
          height: '40px',
          width: isMobile ? '150px' : '12rem',
          padding: '0px 10px',
          border: 'None',
          // isHome ? `1px solid ${ColorPalette.textPrimary}` : '1px solid #fff',
          backgroundColor: `${ColorPalette.darkBlue}`,
          // ':hover': {
          //   backgroundColor: 'rgba(0, 0, 0, 0.4)',
          // },
        }}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        <Typography
          sx={{ fontSize: isMobile ? '.7rem' : '1rem', marginInline: 'auto' }}
          color={isHome ? ColorPalette.pureWhite : ColorPalette.pureWhite}
          noWrap
        >
          {`Welcome ${data?.data?.email ? ` ${data?.data?.email[0].toUpperCase()}` : ''}`}
        </Typography>
        <KeyboardArrowDownIcon
          sx={{
            color: isHome ? ColorPalette.textPrimary : ColorPalette.white,
            minHeight: '40px',
            fontSize: '1.9rem',
          }}
        />
      </Button>
      <Menu
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorEl={anchorEl}
        sx={{
          top: 0,
          backgroundColor: 'transparent',
          '& .MuiPaper-root': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            width: isMobile ? '150px' : '12rem',
            '@media (min-width: 1000px)': {},
          },
          '& .MuiList-root': {
            padding: '0',
          },
          '& .MuiMenuItem-root': {
            backgroundColor: ColorPalette.darkBlue,
            borderTop: `1px solid ${ColorPalette.darkGray}`,
            // borderRadius: '20px',
            color: 'white',
            marginTop: '1px',
            maxHeight: '30px',
          },
        }}
      >
        <MenuItem
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 0,
            paddingBottom: 0,
            minHeight: 32,
            right: '0',
            borderTop: `1px solid ${ColorPalette.white}`,
            backgroundColor: ColorPalette.darkBlue,
            color: ColorPalette.white,
          }}
          onClick={() => {
            window.localStorage.clear();
            window.location.reload();
          }}
        >
          <Typography sx={{ fontSize: isMobile ? '.7rem' : '1rem' }}>Logout</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
export default LoginDropDown;
