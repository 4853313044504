/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ReactGA from 'react-ga4';

export const initGA = () => {
  return ReactGA.initialize('G-DSX1MYJEWZ');
};

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.send('pageview');
};
