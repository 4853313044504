import React from 'react';
import Navbar from 'layout/nav-bar';
import Footer from 'layout/footer/new';

const LayoutProvider: React.FunctionComponent = ({ children }) => {
  return (
    <div className="stalk-app">
      <Navbar />
      {children}
      <Footer />
    </div>
  );
};

export default LayoutProvider;
