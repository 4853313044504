import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import 'assets/scss/main.scss';
import 'assets/scss/bootstrap-custom.css';
import { initGA, logPageView } from './analytics';

initGA();
logPageView();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

{
  /* http://localhost:3005/googlecallback&prompt=consent&response_type=token&client_id=222636146678-vjl1go0k1rk5mgc57db44l38mkevev97.apps.googleusercontent.com&scope=openid%20email%20profile */
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

//stalk-exchange@harbinger-technovation.com
//sha2427995
// https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?client_id=222636146678-vjl1go0k1rk5mgc57db44l38mkevev97.apps.googleusercontent.com
// &redirect_uri=http%3A%2F%2Flocalhost%3A3005%2Flogin&response_type=code&scope=email&access_type=offline&service=lso&o2v=2&ddm=0
// &flowName=GeneralOAuthFlowhttps://accounts.google.com/o/oauth2/v2/auth?redirect_uri=<CALLBACK_URL_YOU_SET_ON_GOOGLE>&prompt=consent
// &response_type=token&client_id=<YOUR CLIENT ID>&scope=openid%20email%20profile
