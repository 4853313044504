import React from 'react';
import { IIconProps } from './Icon.props';
import IconContainer from './IconContainer';

const IconVoteHistory: React.FC<IIconProps> = ({ ...props }) => {
  return (
    <IconContainer {...props}>
      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.3618 2.67795C12.1578 -0.575761 7.12233 -0.849951 3.59883 1.84167V1.12421C3.59883 0.62153 3.19383 0.210247 2.69883 0.210247C2.20383 0.210247 1.79883 0.62153 1.79883 1.12421V3.90266C1.79883 4.40534 2.20383 4.81662 2.69883 4.81662H5.43483C5.92983 4.81662 6.33483 4.40534 6.33483 3.90266C6.33483 3.39998 5.92983 2.9887 5.43483 2.9887H5.10633C7.89183 1.16991 11.6538 1.49437 14.0883 3.96664C16.8963 6.8182 16.8963 11.4566 14.0883 14.3081C12.7293 15.6882 10.9203 16.4514 8.99883 16.4514C8.93583 16.4514 8.87733 16.4559 8.81883 16.4697C8.64783 16.5062 8.49483 16.593 8.37333 16.7119C8.37333 16.7119 8.36883 16.7119 8.36433 16.7164C8.33733 16.7438 8.31933 16.7713 8.29683 16.7987C8.28333 16.817 8.26533 16.8307 8.25633 16.8489C8.24733 16.8581 8.24283 16.8718 8.23833 16.8855C8.15283 17.0226 8.10333 17.1871 8.10333 17.3608C8.10333 17.5984 8.19333 17.8132 8.34183 17.9731C8.35083 17.9823 8.35983 17.996 8.36883 18.0051C8.38683 18.0234 8.40933 18.0417 8.43183 18.0554C8.45433 18.0737 8.47683 18.0965 8.50383 18.1148C8.51733 18.1239 8.53083 18.1285 8.53983 18.1376C8.57583 18.1605 8.61633 18.1833 8.65683 18.2016C8.67483 18.2108 8.69733 18.2153 8.71983 18.2199C8.75583 18.2336 8.78733 18.2473 8.82783 18.2519C8.88633 18.2656 8.94933 18.2702 9.00783 18.2702C11.4108 18.2702 13.6698 17.3196 15.3708 15.5922C18.8808 12.0278 18.8808 6.22869 15.3708 2.66881L15.3618 2.67795Z"
          fill="#004588"
        />
        <path
          d="M2.37846 12.0186C2.18046 11.5525 1.65396 11.3377 1.19496 11.5388C0.852962 11.685 0.645963 12.0232 0.650463 12.3797C0.650463 12.4985 0.672963 12.6219 0.722463 12.7361C0.866462 13.0834 1.19946 13.289 1.55046 13.289C1.66746 13.289 1.78896 13.2662 1.90146 13.2159C2.24796 13.0697 2.45046 12.7315 2.45046 12.3751C2.45046 12.2563 2.42346 12.1329 2.37846 12.0186Z"
          fill="#004588"
        />
        <path
          d="M0.911547 10.1816C1.40205 10.1725 1.79355 9.77031 1.79805 9.27677V9.25392C1.79355 8.75124 1.38405 8.34453 0.884547 8.35367C0.394047 8.36281 0.00254688 8.76495 -0.00195312 9.25849V9.28134C0.00704688 9.77945 0.407547 10.1816 0.898047 10.1816H0.911547Z"
          fill="#004588"
        />
        <path
          d="M1.12678 6.99182C1.23478 7.03295 1.34278 7.05579 1.45078 7.05579C1.81078 7.05579 2.15278 6.83187 2.29228 6.47086C2.33278 6.36118 2.35528 6.24694 2.35528 6.13726C2.35528 5.77168 2.13478 5.42894 1.77928 5.28728C1.31578 5.10448 0.793781 5.33754 0.613781 5.80824C0.573281 5.91791 0.550781 6.03216 0.550781 6.14183C0.550781 6.50742 0.771281 6.85015 1.12678 6.99182Z"
          fill="#004588"
        />
        <path
          d="M6.26739 15.9076C5.80839 15.7157 5.28189 15.9396 5.09289 16.4057C5.04789 16.5245 5.02539 16.6433 5.02539 16.7622C5.02539 17.1186 5.24139 17.4568 5.58789 17.5984C5.69589 17.6441 5.81289 17.667 5.92539 17.667C6.28089 17.667 6.61839 17.4522 6.76239 17.1003C6.80739 16.9815 6.82989 16.8627 6.82539 16.7439C6.82539 16.3874 6.61389 16.0493 6.26739 15.9076Z"
          fill="#004588"
        />
        <path
          d="M2.67506 14.3584C2.49956 14.5411 2.41406 14.7742 2.41406 15.0073C2.41856 15.2403 2.50856 15.4734 2.68406 15.6516C2.85956 15.8298 3.08906 15.9167 3.31406 15.9167C3.53906 15.9167 3.77756 15.8253 3.95756 15.6425C4.12856 15.4642 4.21856 15.2266 4.21406 14.9936C4.21406 14.7605 4.12406 14.5274 3.94856 14.3492C3.59306 13.9973 3.02156 14.0019 2.67506 14.3584Z"
          fill="#004588"
        />
        <path
          d="M8.99766 3.65588C8.50266 3.65588 8.09766 4.06717 8.09766 4.56985V9.13966C8.09766 9.38186 8.19216 9.61492 8.36316 9.78401L11.0632 12.5259C11.2387 12.7041 11.4682 12.7955 11.6977 12.7955C11.9272 12.7955 12.1567 12.7041 12.3322 12.5259C12.6832 12.1695 12.6832 11.5891 12.3322 11.2326L9.89766 8.76037V4.56985C9.89766 4.06717 9.49266 3.65588 8.99766 3.65588Z"
          fill="#004588"
        />
      </svg>
    </IconContainer>
  );
};

export default IconVoteHistory;
