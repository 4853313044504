import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ColorPalette from 'utils/ColorPalette';

const useStyles = makeStyles((theme: Theme) => ({
  sentimentResultWrapper: {
    backgroundColor: ColorPalette.white,
    maxHeight: 75,
    width: '100%',
    minHeight: 75,
    border: `3px solid ${ColorPalette.white}`,
    borderRadius: 50,
    marginBottom: '1rem',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginInline: '0rem',
    },
  },
  voteButton: {
    borderRadius: 0,
    minHeight: '100%',
    marginInline: '0px !important',
    padding: '2px !important',
    paddingTop: '6px !important',
    display: 'flex',
    flex: 1,
  },
  symbolContainer: {
    minWidth: '65px',
    maxHeight: '65px',
    minHeight: '65px',
    marginInlineStart: '3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  },
  totalVoteView: {
    backgroundColor: ColorPalette.white,
    color: ColorPalette.primary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'justify',
    fontSize: '12px',
    borderRadius: '12px',
    marginTop: '6px',
  },
  guid: {
    height: '66px',
    width: '100%',
    margin: '10px',
    alignItems: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  collapseOpenIcon: {
    width: '25px',
    position: 'absolute',
    right: 5,
    bottom: '50%',
    transform: 'translate(0, 50%)',
  },
  collapseCloseIcon: {
    width: '25px',
    position: 'absolute',
    right: 5,
    top: 3,
    zIndex: 2000,
  },
  sliderContainer: {
    backgroundColor: ColorPalette.white,
    height: '230px',
    width: 'calc(100% - 20px)',
    marginBottom: '10px',
    borderRadius: '18px',
  },
  deactiveDot: {
    height: '15px',
    width: '15px',
    backgroundColor: ColorPalette.grey,
    borderRadius: '10px',
  },
  activeDot: {
    height: '15px',
    width: '15px',
    backgroundColor: ColorPalette.primary,
    borderRadius: '10px',
  },
}));

export default useStyles;
