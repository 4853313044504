import { Box, Link, Stack, Typography, useMediaQuery } from '@mui/material';
import IconDown from 'assets/icons/IconDown';
import IconHold from 'assets/icons/IconHold';
import IconUp from 'assets/icons/IconUp';
import { News, Sentiment } from 'models/News';
import React from 'react';
import { useQuery } from 'react-query';
import { NewsService } from 'services/stocks';
import ColorPalette from 'utils/ColorPalette';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InsertLinkIcon from '@mui/icons-material/InsertLink';

const NewsTab: React.FC<{ id?: string }> = ({ id }) => {
  const { data } = useQuery<News[], Error>(['assetNews', id], () => NewsService(id ? +id : 0));
  const isMobile = useMediaQuery('(max-width:800px)');
  const color = (sentiment: Sentiment) => {
    if (sentiment === 'Negative') {
      return ColorPalette.newError;
    }
    if (sentiment === 'Positive') {
      return ColorPalette.newSuccess;
    }
    return ColorPalette.greyPercent;
  };

  return (
    <div
      className="d-flex h-100 px-4 pt-2 flex-column"
      style={{
        backgroundColor: ColorPalette.offWhite,
      }}
    >
      {data?.map((news, index) => (
        <Box
          key={index}
          sx={{
            backgroundColor: ColorPalette.offWhite,
            borderRadius: '5px',
            paddingInline: '15px',
            paddingBlock: '6px',
            marginBottom: '10px',
          }}
        >
          {news.sentiment === 'Positive' && <IconUp svgColor={ColorPalette.newSuccess} />}
          {news.sentiment === 'Negative' && <IconDown svgColor={ColorPalette.newError} />}
          {news.sentiment === 'Neutral' && <IconHold svgColor={ColorPalette.greyPercent} />}
          <div
            className="my-class"
            style={{
              display: 'inline-grid',
              marginLeft: '1rem',
            }}
          >
            <Typography
              ml="3px"
              display="inline"
              fontWeight="bold"
              color={ColorPalette.offBlack}
              fontSize={isMobile ? '14px' : '16px'}
            >
              {news.title}
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              component={Link}
              href={news.url}
              target="_blank"
              sx={{ color: ColorPalette.primary, textDecoration: 'none' }}
            >
              <InsertLinkIcon sx={{ fontSize: '1em' }} />
              <Typography fontSize={isMobile ? '0.9em' : '1em'} ml="5px">
                {news.date} | {news.source}
              </Typography>
            </Stack>
          </div>
        </Box>
      ))}
    </div>
  );
};
export default NewsTab;
