import clsx from 'clsx';
import React from 'react';
import { useMediaQuery, Container } from '@mui/material';

import ListView from './views/ListView';
import { WhiteTextTypography } from 'common/CustomTypography';

const ListComponent: React.FC = () => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const Wrapper: React.FC = ({ children }) => {
    if (isMobile)
      return (
        <div className="d-flex flex-column justify-content-center align-items-center px-2">
          {children}
        </div>
      );
    return (
      <Container className="d-flex flex-column justify-content-center align-items-center mt-3">
        {children}
      </Container>
    );
  };

  return (
    <Wrapper>
      <WhiteTextTypography className={isMobile ? 'text-lg' : 'text-xl'} />
      <ListView />
    </Wrapper>
  );
};

export default ListComponent;

// const Lists: React.FunctionComponent = () => {
//   const isMobile = useMediaQuery('(max-width:720px)');
//   const Wrapper: React.FC = ({ children }) => {
//     if (isMobile)
//       return (
//         <div className="d-flex flex-column justify-content-center align-items-center px-2">
//           {children}
//         </div>
//       );
//     return (
//       <Container
//         className={clsx('d-flex flex-column justify-content-center align-items-center mt-3', {
//           'mt-5': isMobile,
//         })}
//       >
//         {children}
//       </Container>
//     );
//   };

//   return (
//     <Wrapper>
//       <WhiteTextTypography style={{ fontSize: isMobile ? '32px' : '42px' }}></WhiteTextTypography>
//       <ListView />
//     </Wrapper>
//   );
// };
// export default Lists;
