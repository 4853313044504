export type Tabs = 'summary' | 'information' | 'charts' | 'news';

export interface InformationPageState {
  activeTab: Tabs;
}

export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';

interface setActiveTab {
  type: typeof SET_ACTIVE_TAB;
  payload: Tabs;
}

export type InformationPageActions = setActiveTab;
