import React from 'react';
import { IIconProps } from './Icon.props';
import IconContainer from './IconContainer';

const IconUp: React.FC<IIconProps> = ({ svgSize, svgColor, children, ...props }) => {
  return (
    <IconContainer {...props}>
      <svg
        width={svgSize ?? '18'}
        height={svgSize ? svgSize / 2 : '9'}
        viewBox="0 0 18 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.9473 9L1.04984 9C0.117776 9 -0.353491 7.98671 0.311519 7.39562L8.26022 0.274433C8.66866 -0.0914777 9.33367 -0.0914777 9.7421 0.274433L17.6908 7.39562C18.3506 7.98671 17.8846 9 16.9525 9H16.9473Z"
          fill={svgColor ?? 'white'}
        />
      </svg>
      {children}
    </IconContainer>
  );
};

export default IconUp;
