import React from 'react';
import { IIconProps } from './Icon.props';
import IconContainer from './IconContainer';

const IconDate: React.FC<IIconProps> = ({ ...props }) => {
  return (
    <IconContainer {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 52 52"
        fill="none"
      >
        <path
          d="M51 26C51 39.8021 39.8125 51 26 51H25.9792L13.5 29.3854L6.45833 41.5833C3.04167 37.3125 1 31.8854 1 26C1 12.1875 12.1979 1 26 1C39.8021 1 51 12.1875 51 26Z"
          stroke="white"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M42.6667 32.25L26 26V7.25"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M25.9779 51.0003H1.01953L6.45703 41.5837L13.4987 29.3857L25.9779 51.0003Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M13.5 37.458V42.6663"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M13.4997 45.792C12.9268 45.792 12.458 46.2607 12.458 46.8337C12.458 47.4066 12.9268 47.8753 13.4997 47.8753C14.0726 47.8753 14.5413 47.4066 14.5413 46.8337C14.5413 46.2607 14.0726 45.792 13.4997 45.792Z"
          fill="white"
        />
      </svg>
    </IconContainer>
  );
};

export default IconDate;
