import React from 'react';
import { Box, Button, Stack, Theme, Typography, useMediaQuery, Container } from '@mui/material';
import { useHistory } from 'react-router-dom';
import DesktopBack from 'assets/images/backDesk1.png';
import MobileBack from 'assets/images/backMob1.png';
import Assets from 'utils/Assets';
import useStyles from './_styles';
import Content from '../content';

const FirstSection: React.FC = () => {
  const classes = useStyles();
  const { push } = useHistory();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  return (
    <Box
      sx={{
        background: isMobile ? `url(${MobileBack})` : `url(${DesktopBack})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Container className={classes.whiteBox}>
        <Stack className={classes.textContainer}>
          <Typography className={classes.title}>{Content.firstSection.title}</Typography>
          <Typography className={classes.description}>{Content.firstSection.content}</Typography>
          <Button
            variant="contained"
            className={classes.greenButton}
            onClick={() => push('/login')}
          >
            Sign up For Free
          </Button>
        </Stack>
        <Stack className={classes.imgContainer}>
          <img
            src={Assets.Section1.src}
            alt={Assets.Section1.alt}
            width={isMobile ? 330 : 450}
            height={isMobile ? 300 : 500}
          />
        </Stack>
      </Container>
    </Box>
  );
};

export default FirstSection;
