import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Menu,
  Button,
  ButtonProps,
  Typography,
  MenuItem,
  useMediaQuery,
  Container,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowDown from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import ColorPalette from 'utils/ColorPalette';
import { BlackTextTypography } from './CustomTypography';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px ${ColorPalette.mediumGray} solid`,
    borderRadius: 25,
    fontSize: '0.5rem',
    maxHeight: '50px',
    height: '50px',
    minHeight: '50px',
    flex: 1,
  },
  menu: {
    '& .MuiMenu-list': {
      padding: 0,
      borderRadius: 15,
    },
  },
}));

interface DropDownProps extends ButtonProps {
  items: ItemProps[];
  defaultText?: string;
  showSelectedText?: boolean;
  onChangeItem?: (value: string) => void;
  label?: string;
}
export interface ItemProps {
  id?: string | number;
  text?: string;
  value?: string;
}

const DropDown: React.FunctionComponent<DropDownProps> = ({
  items,
  defaultText,
  label,
  onChangeItem,
  showSelectedText = true,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:900px)');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedText, setSelectedText] = useState('');
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (onChangeItem) {
      onChangeItem(selectedText);
    }
  }, [selectedText]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (index: any) => {
    if (showSelectedText) {
      setSelectedIndex(index);
      setSelectedText(items[index].value || items[index].text || '');
    }
    handleClose();
  };

  return (
    <>
      <BlackTextTypography variant="body2" sx={{ marginTop: '20px', marginLeft: '20px' }} noWrap>
        {label}
      </BlackTextTypography>
      <Button
        className={clsx(classes.wrapper, 'mt-2 p-0')}
        endIcon={
          <KeyboardArrowDownIcon
            sx={{
              color: ColorPalette.offBlack,
              marginRight: '20px',
            }}
            fontSize="large"
          />
        }
        onClick={handleClick}
      >
        <Typography variant="body2" color={ColorPalette.offBlack} className={clsx('mx-3')} noWrap>
          {!defaultText ? items[selectedIndex].text : defaultText}
        </Typography>
      </Button>
      <Menu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        keepMounted
        className={clsx('small-scrollbar', classes.menu)}
        sx={{
          top: 0,
          backgroundColor: 'transparent',
          '& .MuiPaper-root': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            width: '100%',
          },
          '& .MuiList-root': {
            padding: '0',
          },
          '& .MuiButtonBase-root-MuiMenuItem-root': {
            minHeight: '30px !important',
            padding: '0',
          },
          '& .MuiMenuItem-root': {
            backgroundColor: ColorPalette.darkBlue,
            border: '1px solid white',
            borderRadius: '20px',
            color: 'white',
            marginTop: '1px',
          },
        }}
      >
        {items.map(item => {
          if (!!item.text) {
            return (
              <Container maxWidth="sm">
                <MenuItem
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: 0,
                    paddingBottom: 0,
                    minHeight: 40,
                    border: `1px solid ${ColorPalette.mediumGray}`,
                    backgroundColor: ColorPalette.darkBlue,
                    color: ColorPalette.white,
                    borderRadius: '20px',
                  }}
                  onClick={() => handleMenuItemClick(item.id)}
                  key={item.text || item.id}
                >
                  <Typography style={{ fontSize: '0.9rem' }}>{item.text}</Typography>
                </MenuItem>
              </Container>
            );
          }
        })}
      </Menu>
    </>
  );
};
export default DropDown;
