import { useState } from 'react';
import {
  Button,
  IconButton,
  Stack,
  Input,
  Divider,
  Typography,
  Container,
  Box,
  useMediaQuery,
} from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import { Link, useHistory, useLocation } from 'react-router-dom';

import Image from 'common/Image';
import Assets from 'utils/Assets';
import { AuthApi } from 'api/Auth';
import style from './style.module.scss';
import ColorPalette from 'utils/ColorPalette';
import { BlackTextTypography } from 'common/CustomTypography';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEyeOutlined';

function Login() {
  const [form, setForm] = useState({ email: '', password: '' });
  const isMobile = useMediaQuery('(max-width:800px)');
  const { push } = useHistory();
  const { search } = useLocation();
  const [error, setError] = useState('');
  const { mutate, isError } = useMutation('login', AuthApi.signIn, {
    onSuccess: res => {
      localStorage.setItem('token', res.data.key);
      window.location.href = '/list';
    },
    onError: err => {
      //@ts-ignore
      setError(err.response.data.detail);
    },
  });

  const decodedComponent = decodeURIComponent(search?.split('&')[0]?.slice(6, search.length - 1));
  useQuery('login-google', () => AuthApi.signUpGoogle({ code: decodedComponent }), {
    onSuccess: res => {
      localStorage.setItem('token', res.data.key);
      window.location.href = '/list';
    },
    onError: err => {
      //@ts-ignore
      setError(err.response.data.detail);
    },
    enabled: search.length > 0,
  });

  const handleSubmit = () => {
    mutate(form);
  };

  const styles = {
    // borderRadius: '30px',
    // border: 'None',
    borderBottom: '2px solid #CCCCCC',
    height: '50px',
    paddingInline: '20px',
    width: '100%',
    fontSize: '16px',
    color: ColorPalette.offBlack,
    backgroundColor: ColorPalette.pureWhite,
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        backgroundColor: ColorPalette.offWhite,
        marginTop: '3rem',
      }}
    >
      <Stack
        gap={2}
        alignItems="center"
        className="my-auto p-4"
        sx={{
          borderRadius: '5px',
          backgroundColor: ColorPalette.offWhite,
        }}
      >
        <BlackTextTypography fontSize="24px" marginRight="auto">
          Sign in
        </BlackTextTypography>
        <Stack
          direction="row"
          display="flex"
          alignItems="center"
          marginRight="auto"
          mt="-10px"
          marginBottom="25px"
        >
          <BlackTextTypography fontSize="13px" marginRight="auto">
            Not a member?
          </BlackTextTypography>
          <Link
            to="/sign-up"
            style={{
              textDecoration: 'none',
              color: ColorPalette.newSuccess,
              fontSize: '13px',
              marginLeft: '7px',
            }}
          >
            Create an account
          </Link>
        </Stack>
        <BlackTextTypography
          sx={{
            marginInline: '15px auto',
            marginBottom: '-10px',
            fontSize: '16px',
          }}
        >
          Your Email
        </BlackTextTypography>
        <Input
          value={form.email}
          onChange={e => setForm(prv => ({ ...prv, email: e.target.value }))}
          type="email"
          placeholder="Your email"
          disableUnderline
          sx={{
            border: 'None',
            // isError
            //   ? `1px solid  ${ColorPalette.error}`
            //   : `1px solid  ${ColorPalette.mediumGray}`,
            ...styles,
          }}
        />
        <Stack
          direction="row"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            marginBottom: '-15px',
            paddingInline: '20px',
          }}
        >
          <BlackTextTypography sx={{ fontSize: '16px' }}>Password</BlackTextTypography>
          <Button
            variant="text"
            // color={{ ColorPalette.darkGray }}
            sx={{
              color: ColorPalette.darkGray,
              fontWeight: '400',
              alignSelf: 'end',
              fontSize: '14px',
            }}
            onClick={() => push('/change-password')}
          >
            Forgot password?
          </Button>
        </Stack>
        <Input
          value={form.password}
          type="password"
          id="passwordLogin"
          onChange={e => setForm(prv => ({ ...prv, password: e.target.value }))}
          placeholder="Password"
          disableUnderline
          endAdornment={
            <>
              <IconButton
                sx={{ color: ColorPalette.darkGray, padding: '0' }}
                onClick={() => {
                  const typeElement = document
                    .getElementById('passwordLogin')
                    ?.getAttribute('type');
                  document
                    .getElementById('passwordLogin')
                    ?.setAttribute('type', typeElement === 'password' ? 'text' : 'password');
                }}
              >
                <RemoveRedEyeIcon />
              </IconButton>
            </>
          }
          sx={{
            border: 'None',
            // border: isError
            //   ? `1px solid  ${ColorPalette.error}`
            //   : `1px solid  ${ColorPalette.mediumGray}`,
            ...styles,
          }}
        />
        {error && <Typography sx={{ color: '#fa6969', alignSelf: 'start' }}>{error}</Typography>}
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="secondary"
          sx={{
            fontSize: '16px',
            borderRadius: '30px',
            width: '100%',
            height: '50px',
            padding: '0px 35px',
            fontWeight: '400',
            marginBlock: '40px auto',
          }}
        >
          Login
        </Button>
        <Divider
          className="my-3 w-100"
          sx={{
            color: ColorPalette.darkGray,
            borderTopColor: ColorPalette.darkGray,
            '&::before': {
              borderColor: ColorPalette.darkGray,
            },
            '&::after': {
              borderColor: ColorPalette.darkGray,
            },
          }}
        >
          OR
        </Divider>
        <Box
          sx={{
            display: 'flex',
            backgroundColor: ColorPalette.white,
            width: '100%',
            height: '50px',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '30px',
            marginInline: 'auto',
            cursor: 'pointer',
          }}
          onClick={() =>
            window.open(
              `https://accounts.google.com/o/oauth2/v2/auth?client_id=222636146678-vjl1go0k1rk5mgc57db44l38mkevev97.apps.googleusercontent.com&redirect_uri=https://app.stalk-exchange.com/login&response_type=code&scope=email&access_type=offline`,
            )
          }
        >
          <Image asset={Assets.google} width={isMobile ? 18 : 20} style={{ marginRight: '20px' }} />
          <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
            Continue with Google
          </Typography>
        </Box>
      </Stack>
    </Container>
  );
}

export default Login;
