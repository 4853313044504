import React from 'react';
import { Button, IconButton, Typography, useMediaQuery } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ColorPalette from 'utils/ColorPalette';
import LockLogo from 'assets/icons/LockLogo';
import { useHistory } from 'react-router-dom';

interface SignInModalProps {
  showModal: boolean;
  handleClose: () => void;
}

const SignInModal: React.FC<SignInModalProps> = ({ showModal, handleClose }) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const { push } = useHistory();

  return (
    <Dialog open={showModal} onClose={handleClose}>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          paddingBlock: '1.5rem',
          paddingInline: isMobile ? '1rem' : '3rem',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          disableRipple
          disableTouchRipple
          disableFocusRipple
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <LockLogo sx={{ marginBottom: '20px' }} />
        <DialogContentText>
          <Typography
            sx={{
              color: ColorPalette.primary,
              textAlign: 'center',
              fontSize: isMobile ? '.8rem' : '1.2rem',
            }}
          >
            To unlock, sign in first.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Button
          variant="contained"
          onClick={() => push('/login')}
          color="secondary"
          sx={{ minWidth: '200px', height: isMobile ? 'inherit' : '60px', marginBottom: '10px' }}
        >
          Sign up for free
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignInModal;
