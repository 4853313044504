import React from 'react';
import { IIconProps } from './Icon.props';
import IconContainer from './IconContainer';

const IconFilterUp: React.FC<IIconProps> = ({ svgSize, ...props }) => {
  return (
    <IconContainer {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={svgSize}
        height={svgSize}
        viewBox="0 0 20 20"
        fill="none"
      >
        <path d="M16 0.999805V19" stroke="#004588" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M15.9998 0.999774L18.9248 3.9248"
          stroke="#004588"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.075 3.9248L16 0.999774"
          stroke="#004588"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M13 19H11" stroke="#004588" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 15H8.99996" stroke="#004588" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 11H4.99991" stroke="#004588" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13 7H0.999871" stroke="#004588" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </IconContainer>
  );
};

export default IconFilterUp;
