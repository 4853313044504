import React, { ReactNode } from 'react';
import { Button, Stack, Typography, useMediaQuery } from '@mui/material';
import IconAllStock from 'assets/icons/IconAllStocks';
import IconWatchList from 'assets/icons/IconWatchList';
import IconVoteHistory from 'assets/icons/IconVoteHistory';
import ColorPalette from 'utils/ColorPalette';
import LockIcon from '@mui/icons-material/Lock';
import { isLoggedIn } from 'utils/helper';

type ActiveTabType = 'ALL' | 'WATCHLIST' | 'VOTEHISTORY';

interface TabsProps {
  currentTab: ActiveTabType;
  setCurrentTab: (val: ActiveTabType) => void;
  setShowLoginModal: (val: boolean) => void;
}

interface BtnsProps {
  id: number;
  key: ActiveTabType;
  title: string;
  icon: ReactNode;
}

const Tabs: React.FunctionComponent<TabsProps> = ({
  currentTab,
  setCurrentTab,
  setShowLoginModal,
}) => {
  const isMobile = useMediaQuery('(max-width:720px)');
  const isLogged = isLoggedIn();
  const Tabs: BtnsProps[] = [
    {
      id: 1,
      key: 'ALL',
      title: 'All Stocks',
      icon: null,
    },
    { id: 2, key: 'WATCHLIST', title: 'Watch List', icon: <IconWatchList svgSize={25} /> },
    { id: 3, key: 'VOTEHISTORY', title: 'Vote History', icon: <IconVoteHistory /> },
  ];

  // {<IconAllStock sx={{ marginLeft: 'auto' }} />,}
  return (
    <div
      className={'d-flex flex-column justify-content-between w-100 position-relative'}
      style={{
        padding: '10px',
        backgroundColor: '#efefef',
      }}
    >
      <Stack direction="row" display="flex" mb={isMobile ? 'undefined' : '0rem'}>
        {Tabs.map(btn => (
          <Button
            key={btn.id}
            sx={{
              backgroundColor:
                !isLogged && btn.key !== 'ALL'
                  ? ColorPalette.pureWhite
                  : currentTab === btn.key
                  ? ColorPalette.greenBtn
                  : ColorPalette.pureWhite,
              color: currentTab === btn.key ? ColorPalette.pureWhite : ColorPalette.offBlack,
              opacity: !isLogged && btn.key !== 'ALL' ? 0.8 : 1,
              fontSize: isMobile ? '10px' : '16px',
              paddingInline: '5px',
              borderRadius: '5px',
              display: 'flex',
              height: isMobile ? '30px' : '45px',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
              marginInline: btn.key === 'WATCHLIST' ? '7px' : '0',
              minWidth: isMobile ? '30%' : undefined,
              flex: 1,
              '&:hover': {
                backgroundColor:
                  !isLogged && btn.key != 'ALL'
                    ? ColorPalette.greenBtn + 'AA'
                    : ColorPalette.greenBtn,
                color: ColorPalette.pureWhite,
                // !isLogged && btn.key !== 'ALL' ? ColorPalette.disableBtn : '#e0dddd',
                // color: !isLogged && btn.key !== 'ALL' ? ColorPalette.primary : '#3c52b2',
              },
            }}
            startIcon={!isLogged ? (btn.key === 'ALL' ? btn.icon : undefined) : btn.icon}
            onClick={() => {
              if (!isLogged && btn.key !== 'ALL') {
                setShowLoginModal(true);
              } else {
                setCurrentTab(btn.key);
              }
            }}
            variant="contained"
          >
            <Typography
              fontWeight="bold"
              sx={{
                marginInline: 'auto',
                fontSize: isMobile ? '10px' : 'inherit',
              }}
            >
              {btn.title}
            </Typography>
            {!isLogged && btn.key !== 'ALL' && (
              <LockIcon sx={{ fontSize: isMobile ? '16px' : '2rem' }} />
            )}
          </Button>
        ))}
      </Stack>
    </div>
  );
};

export default Tabs;
