import React, { useState } from 'react';
import Slider from 'react-slick';
import { Box, Button, Container, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useHistory } from 'react-router-dom';
import useStyles from './_styles';
import Content from '../content';
import Icon1 from 'assets/icons/Icon1';
import Icon2 from 'assets/icons/Icon2';
import Icon3 from 'assets/icons/Icon3';
import Icon4 from 'assets/icons/Icon4';
import Icon5 from 'assets/icons/Icon5';
import DesktopBack from 'assets/images/backDeskG.png';
import MobileBack from 'assets/images/backMobG.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SecondSection: React.FC = () => {
  const classes = useStyles();
  const { push } = useHistory();
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const Icons = [
    <Icon4 sx={{ marginRight: '1.3rem !important' }} />,
    <Icon1 sx={{ marginRight: '1.3rem !important' }} />,
    <Icon2 sx={{ marginRight: '1.3rem !important' }} />,
    <Icon3 sx={{ marginRight: '1.3rem !important' }} />,
    <Icon5 sx={{ marginRight: '1.3rem !important' }} />,
  ];
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const settings = {
    //@ts-ignore
    customPaging: i => {
      return <div className={i !== activeSlide ? classes.deactiveDot : classes.activeDot} />;
    },
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dotsClass: 'nav-slide-dot',
    //@ts-ignore
    afterChange: current => setActiveSlide(current),
  };
  return (
    <Box
      sx={{
        background: isMobile ? `url(${MobileBack})` : `url(${DesktopBack})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Container className={classes.greenBox}>
        <Stack className={classes.whiteTextContainer}>
          <Typography className={classes.whiteTitle}>{Content.secondSection.title}</Typography>
          <Button className={classes.whiteButton} onClick={() => push('/list')}>
            Try it Free
          </Button>
        </Stack>
        <Stack className={classes.sliderContainer}>
          <Slider {...settings}>
            {Content.secondSection.items.map((item, index) => (
              <Stack key={index} className={classes.sliderTextContainer}>
                <Typography className={classes.boxTitle}>
                  {Icons[index]} {item.title}
                </Typography>
                <Typography className={classes.boxContent}>{item.content}</Typography>
              </Stack>
            ))}
          </Slider>
        </Stack>
      </Container>
    </Box>
  );
};

export default SecondSection;
