import * as Types from './types';

const initialState: Types.LineChartState = {
  dateRange: 365,
  show10daysMA: false,
  show5daysMA: false,
};

function lineChartReducer(
  state = initialState,
  actions: Types.LineChartActionType,
): Types.LineChartState {
  switch (actions.type) {
    case Types.SET_DATA_RANGE:
      return { ...state, dateRange: actions.payload };
    case Types.SET_SHOW_10D_MA:
      return { ...state, show10daysMA: actions.payload };
    case Types.SET_SHOW_5D_MA:
      return { ...state, show5daysMA: actions.payload };
    default:
      return state;
  }
}

export default lineChartReducer;
