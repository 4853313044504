import React from 'react';
import { IIconProps } from './Icon.props';
import IconContainer from './IconContainer';

const IconDate: React.FC<IIconProps> = ({ ...props }) => {
  return (
    <IconContainer {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="34"
        viewBox="0 0 53 52"
        fill="none"
      >
        <path
          d="M51.0631 51L41.6023 48.4604C38.8546 50.0529 35.6697 50.9584 32.2767 50.9584C21.9416 50.9584 13.5527 42.5695 13.5527 32.2344C13.5527 21.8992 21.9312 13.5 32.2663 13.5C42.6014 13.5 50.9903 21.8784 50.9903 32.224C50.9903 35.6066 50.0848 38.7914 48.5132 41.5287L51.0527 51H51.0631Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 38.4688L10.4505 35.9292C13.1982 37.5216 16.383 38.4167 19.776 38.4167C30.1007 38.4167 38.4896 30.0279 38.4896 19.7032C38.4896 9.37843 30.1007 1 19.7656 1C9.43047 1 1.06245 9.37843 1.06245 19.7136C1.06245 23.0962 1.96794 26.2706 3.53955 29.0079L1 38.4688Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.9492 28.0607L28.1124 23.8975"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M23.9492 23.8975L28.1124 28.0607"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>
    </IconContainer>
  );
};

export default IconDate;
