import React from 'react';
import { Box, Button, Stack, Theme, Typography, useMediaQuery, Container } from '@mui/material';
import { useHistory } from 'react-router-dom';
import DesktopBack from 'assets/images/backDesk3.png';
import useStyles from './_styles';
import Content from '../content';
import Assets from 'utils/Assets';

const FourthSection: React.FC = () => {
  const classes = useStyles();
  const { push } = useHistory();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  return (
    <Box
      sx={{
        background: isMobile ? `#E1E1E1` : `url(${DesktopBack})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Container
        className={classes.whiteBox}
        sx={(theme: Theme) => ({
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column !important',
          },
        })}
      >
        <Stack className={classes.textContainer}>
          <Typography className={classes.title}>{Content.fourthSection.title}</Typography>
          <Typography className={classes.description}>{Content.fourthSection.content}</Typography>
          <Button variant="contained" className={classes.greenButton} onClick={() => push('/list')}>
            Discover it now
          </Button>
        </Stack>
        <Stack className={classes.imgContainer} sx={{ alignItems: 'flex-end !important' }}>
          <img
            src={Assets.Section4.src}
            alt={Assets.Section4.alt}
            width={isMobile ? 330 : 450}
            height={isMobile ? 300 : 500}
          />
        </Stack>
      </Container>
    </Box>
  );
};

export default FourthSection;
