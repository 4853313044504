import { BubbleData } from 'models/Bubble';
import { ISearchAsset } from 'models/SearchAsset';

export type SortType = 'PRICE_CHANGE' | 'NEWS_CHANGE' | 'NEWS_NUMBER' | 'SENTIMENT_CHANGE';
export type OrderDirectionType = 'UP' | 'DOWN';
export type OrderSortType = `${SortType}_${OrderDirectionType}`;
export type ActiveTabType = 'ALL' | 'WATCHLIST' | 'VOTEHISTORY';

export interface DetailViewType {
  isShown: boolean;
  stockIndex: string;
}

export interface AppState {
  volumeSort: SortType;
  colorSort: SortType;
  displaySearch: boolean;
  showDetailView: DetailViewType;
  bubbleData: BubbleData[];
  searchData: ISearchAsset[];
  prefAsset: ISearchAsset | undefined;
  fistPrefID: number | undefined;
  secondPrefID: number | undefined;
  showSubscribeModal: boolean;
  currentTab: ActiveTabType;
}

export const SET_VOLUME_SORT = 'SET_ VOLUME_SORT';
export const SET_COLOR_SORT = 'SET_COLOR_SORT';
export const SET_DISPLAY_SEARCH = 'SET_DISPLAY_SEARCH';
export const SET_SHOW_DETAIL_VIEW = 'SET_SHOW_DETAIL_VIEW';
export const SET_BUBBLE_DATA = 'SET_BUBBLE_DATA';
export const SET_SEARCH_DATA = 'SET_SEARCH_DATA';
export const SET_PREF_ASSET = 'SET_PREF_ASSET';
export const SET_FIRST_PREF_ID = 'SET_FIRST_PREF_ID';
export const SET_SHOW_SUBSCRIBE_MODAL = 'SET_SHOW_SUBSCRIBE_MODAL';
export const SET_SECOND_PREF_ID = 'SET_SECOND_PREF_ID';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';

interface SetBubbleData {
  type: typeof SET_BUBBLE_DATA;
  payload: BubbleData[];
}

interface SetSearchData {
  type: typeof SET_SEARCH_DATA;
  payload: ISearchAsset[];
}

interface SetPrefAsset {
  type: typeof SET_PREF_ASSET;
  payload: ISearchAsset | undefined;
}

interface SetVolumeSort {
  type: typeof SET_VOLUME_SORT;
  payload: SortType;
}
interface SetDisplaySearch {
  type: typeof SET_DISPLAY_SEARCH;
  payload: boolean;
}

interface SetColorSort {
  type: typeof SET_COLOR_SORT;
  payload: SortType;
}
interface SetShowDetailView {
  type: typeof SET_SHOW_DETAIL_VIEW;
  payload: DetailViewType;
}
interface SetFirstPrefID {
  type: typeof SET_FIRST_PREF_ID;
  payload: number | undefined;
}
interface SetSecondPrefID {
  type: typeof SET_SECOND_PREF_ID;
  payload: number | undefined;
}

interface SetSshowSubscribeModal {
  type: typeof SET_SHOW_SUBSCRIBE_MODAL;
  payload: boolean;
}

interface SetActiveTab {
  type: typeof SET_ACTIVE_TAB;
  payload: ActiveTabType;
}

export type AppActionTypes =
  | SetVolumeSort
  | SetColorSort
  | SetDisplaySearch
  | SetShowDetailView
  | SetBubbleData
  | SetSearchData
  | SetPrefAsset
  | SetFirstPrefID
  | SetSshowSubscribeModal
  | SetActiveTab
  | SetSecondPrefID;
