import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { StyledEngineProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';

import { loadStocksSearchList } from 'services/stocks';
import LayoutProvider from 'provider/LayoutProvider';
import RouterProvider from 'provider/Router';
import CookieModal from 'common/CookieModal';
import ThemeProvider from 'provider/Theme';
import store from 'store';

const App: React.FunctionComponent = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 1,
      },
    },
  });

  useEffect(() => {
    if (store.getState().App.searchData.length === 0) {
      loadStocksSearchList();
    }
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <StyledEngineProvider injectFirst>
            <LayoutProvider>
              <RouterProvider />
              <CookieModal />
            </LayoutProvider>
          </StyledEngineProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
