import React from 'react';
import { Box, Button, Container, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useHistory } from 'react-router-dom';
import DesktopBack from 'assets/images/backDesk2.png';
import MobileBack from 'assets/images/backMob2.png';
import useStyles from './_styles';
import Content from '../content';
import Assets from 'utils/Assets';

const ThirdSection: React.FC = () => {
  const classes = useStyles();
  const { push } = useHistory();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        background: isMobile ? `url(${MobileBack})` : `url(${DesktopBack})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Container
        className={classes.whiteBox}
        sx={(theme: Theme) => ({
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column !important',
          },
        })}
      >
        <Stack className={classes.imgContainer} sx={{ alignItems: 'flex-start !important' }}>
          <img
            src={Assets.Section3.src}
            alt={Assets.Section3.alt}
            width={isMobile ? 330 : 450}
            height={isMobile ? 300 : 500}
          />
        </Stack>
        <Stack className={classes.textContainer}>
          <Typography className={classes.title}>{Content.thirdSection.title}</Typography>
          <Typography className={classes.description}>{Content.thirdSection.content}</Typography>
          <Button variant="contained" className={classes.greenButton} onClick={() => push('/list')}>
            Start Exploring
          </Button>
        </Stack>
      </Container>
    </Box>
  );
};

export default ThirdSection;
