import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Box, Button, Container, Stack, Typography, useMediaQuery } from '@mui/material';

import { AuthApi } from 'api/Auth';
import DropDown from 'common/DropDown';
import Typewriter from 'typewriter-effect';
import CustomInput from './components/Input2';
import ColorPalette from 'utils/ColorPalette';
import * as EmailValidator from 'email-validator';
import { BlackTextTypography } from 'common/CustomTypography';

const Subscribe: React.FunctionComponent = () => {
  const { push } = useHistory();
  const isMobile = useMediaQuery('(max-width:800px)');
  const [formData, setFormData] = useState<any>({
    email: '',
    password: '',
    password2: '',
    age: 'N.A.',
    financial_knowledge: 'N.A.',
    user_type: 'PRM',
  });
  const confirmStyle = {
    backgroundColor: ColorPalette.newSuccess,
    border: `1px solid ${ColorPalette.primary}`,
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: '500',
    borderRadius: '30px',
    width: '150px',
    height: '35px',
    padding: '0px 35px',
    boxShadow:
      '2px -2px 2px 0px rgba(0, 0, 0, 0.25) inset, -3px 3px 5px 0px rgba(255, 255, 255, 0.35) inset',
    '&:active': {
      backgroundColor: ColorPalette.white,
    },
    '&:hover': {
      backgroundColor: ColorPalette.newSuccess,
    },
  };
  const [hasError, setHasError] = useState({ email: false, pass: false });
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [showEmailLabel, setShowEmailLabel] = useState(false);
  const [showPassInput, setShowPassInput] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showTypo, setShowTypo] = useState({
    pass: false,
    pass2: false,
    box: false,
    confirm: false,
  });

  const { mutate } = useMutation(['subscribe'], AuthApi.signUp, {
    onSuccess: () => {
      push('/sign-up-success');
    },
    onError: err => {
      //@ts-ignore
      setErrorMessage(err.response.data.detail);
    },
  });

  const signUpSubmitHandler = () => {
    setHasError({ email: false, pass: false });
    const data = {
      ...formData,
      password2: formData.password,
      financial_knowledge: formData.financial_knowledge ? formData.financial_knowledge : 'N.A.',
    };
    mutate(data);
  };

  const onConfirm = () => {
    if (!EmailValidator.validate(formData.email)) {
      setHasError(prv => ({ ...prv, email: true }));
    }
    if (formData.password !== formData.password2) {
      setHasError(prv => ({ ...prv, pass: true }));
    }
    if (EmailValidator.validate(formData.email) && formData.password.length > 7) {
      signUpSubmitHandler();
    }
  };

  const options = {
    autoStart: true,
    loop: false,
    delay: 20,
    wrapperClassName: 'typeWriter',
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          fontSize: '14px',
          paddingInline: '1rem',
          '& .Typewriter__cursor': {
            display: 'none !important',
          },
          '& .Typewriter': {
            marginTop: '20px',
            marginLeft: '20px',
          },
          '& .MuiBox-root': {
            marginLeft: '0px !important',
            marginTop: '0px !important',
            marginBottom: '10px',
            '& a': {
              color: ColorPalette.newSuccess,
              cursor: 'pointer',
            },
          },
        }}
      >
        <BlackTextTypography fontSize="24px" marginRight="auto" marginTop="20px">
          Sign up
        </BlackTextTypography>
        <Typewriter
          component={Box}
          onInit={typewriter => {
            typewriter
              .typeString(
                `Welcome to sTalk! Let's venture into investment together.
              <br/> Have an account? <a href="/login">Sign in</a>
              `,
              )
              .pauseFor(300)
              .callFunction(() => {
                setShowEmailLabel(true);
              })
              .start();
          }}
          options={options}
        />
        <div className="d-flex">
          {showEmailLabel && (
            <Typewriter
              onInit={typewriter => {
                typewriter
                  .typeString(`Please provide your email address.`)
                  .pauseFor(300)
                  .callFunction(() => {
                    setShowEmailInput(true);
                  })
                  .start();
              }}
              options={options}
            />
          )}
        </div>
        <CustomInput
          value={formData?.email}
          setValue={e => {
            setFormData({ ...formData, email: e });
          }}
          OnSubmit={() => setShowTypo({ ...showTypo, pass: true })}
          show={showEmailInput}
          setShow={setShowEmailInput}
          type="email"
          hasError={hasError.email}
          errorMessages={'Email is invalid!'}
        />
        {showTypo.pass && (
          <Typewriter
            onInit={typewriter => {
              typewriter
                .typeString(`Choose a password (at least 8 characters) `)
                .pauseFor(300)
                .callFunction(() => {
                  setShowPassInput(true);
                })
                .start();
            }}
            options={options}
          />
        )}
        <CustomInput
          value={formData?.pass}
          setValue={e => {
            if (e.length > 7) {
              setShowTypo({ ...showTypo, confirm: true });
            }
            setFormData({ ...formData, password: e });
          }}
          show={showPassInput}
          setShow={setShowPassInput}
          onEyeClick={() => {
            const typeElement = document.getElementById('passwordRef')?.getAttribute('type');
            document
              .getElementById('passwordRef')
              ?.setAttribute('type', typeElement === 'password' ? 'text' : 'password');
          }}
          type="password"
          id="passwordRef"
        />
        {showTypo.confirm && (
          <Stack>
            <DropDown
              label="Which age group are you in?"
              value={formData.age}
              onChangeItem={e => setFormData({ ...formData, age: e })}
              items={[
                { id: 0, text: '', value: 'N.A.' },
                { id: 1, text: 'Under 25', value: 'U25' },
                { id: 2, text: '25-35' },
                { id: 3, text: '35-45' },
                { id: 4, text: '45-55' },
                { id: 5, text: '55-65' },
                { id: 6, text: '65-75' },
                { id: 6, text: '75+' },
              ]}
            />
            <DropDown
              label="What's Tell us about your financial expertise"
              value={formData.financial_knowledge}
              onChangeItem={e => setFormData({ ...formData, financial_knowledge: e })}
              items={[
                { id: 0, value: 'N.A.', text: '' },
                { id: 1, value: 'NOV', text: 'Novice' },
                { id: 2, value: 'ADB', text: 'Learning fast' },
                { id: 3, value: 'CMT', text: 'Competent' },
                { id: 4, value: 'PRF', text: 'Professional' },
                { id: 5, value: 'EXP', text: 'Expert' },
              ]}
            />
            {errorMessage.length > 1 && (
              <Typography
                sx={{
                  color: ColorPalette.error,
                  marginLeft: '8px',
                  fontSize: isMobile ? '14px' : '16px',
                  marginTop: '20px',
                }}
              >
                {errorMessage}
              </Typography>
            )}
            <div className="d-flex align-items-center mt-3">
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  fontSize: '16px',
                  borderRadius: '30px',
                  width: '100%',
                  height: '50px',
                  padding: '0px 35px',
                  fontWeight: '400',
                  marginBlock: '1rem',
                }}
                onClick={onConfirm}
              >
                Confirm
              </Button>
              {/* {hasError.pass && (
              <Typography sx={{ color: ColorPalette.error, marginLeft: '8px', fontSize:isMobile? '14px' : '16px' }}>
                Passwords do not match!
              </Typography>
            )} */}
            </div>
          </Stack>
        )}
      </Box>
    </Container>
  );
};

export default Subscribe;
