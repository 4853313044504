import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Box, Button, ListItemButton, ListItemText, MenuItem } from '@mui/material';
import clsx from 'clsx';
import useStyles from '../_styles';
import ColorPalette from 'utils/ColorPalette';
import { isLoggedIn } from 'utils/helper';
import LoginDropDown from './LoginDrop';

interface MenuItem {
  id: number;
  text: string;
  href: string;
}

export const menuItems: MenuItem[] = [
  { id: 1, text: 'Home', href: '/' },
  { id: 2, text: 'Stocks', href: '/list' },
  { id: 3, text: 'About Us', href: '/about-us' },
  { id: 4, text: 'Disclaimers', href: '/disclaimers' },
];

const NavbarItems: React.FunctionComponent = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const isHome = pathname === '/';
  let selectedMenuValue: number | null = null;
  if (pathname === '/') {
    selectedMenuValue = 1;
  } else if (pathname === '/list') {
    selectedMenuValue = 2;
  } else if (pathname.startsWith('/asset')) {
    selectedMenuValue = 2;
  } else if (pathname === '/about-us') {
    selectedMenuValue = 3;
  } else if (pathname === '/disclaimers') {
    selectedMenuValue = 4;
  }

  return (
    <div className={clsx(classes.navItemContainer)}>
      <Box sx={{ marginInline: 'auto', display: 'flex' }}>
        {menuItems.map(el => (
          <ListItemButton
            key={el.id}
            component={Link}
            to={el.href}
            selected={el.id === selectedMenuValue}
            classes={{
              root: clsx(classes.menuItem),
              selected: isHome ? classes.menuItemSelectedSecond : classes.menuItemSelected,
            }}
          >
            <ListItemText
              primaryTypographyProps={{
                color: isHome ? ColorPalette.primary : ColorPalette.white,
                className: 'font-weight-bold',
              }}
              primary={el.text}
            />
          </ListItemButton>
        ))}
      </Box>
      <Button
        sx={{
          visibility: !isLoggedIn() && !pathname.includes('login') ? 'inherit' : 'hidden',
          color: ColorPalette.pureWhite,
          fontSize: '0.875rem',
          borderRadius: '5px',
          height: '30px',
          maxWidth: 'fit-content',
          padding: '0px 35px',
          backgroundColor: ColorPalette.darkBlue,
          '&:hover': {
            backgroundColor: ColorPalette.darkBlue,
          },
        }}
        onClick={() => push('/login')}
      >
        Login
      </Button>
      {isLoggedIn() && !pathname.includes('login') && <LoginDropDown />}
    </div>
  );
};

export default NavbarItems;
