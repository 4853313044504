import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import { RootState } from 'store';
import ColorPalette from 'utils/ColorPalette';
import { setShow5D, setShow10D } from 'store/lineChart/actions';

const MovingAverage: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { show5daysMA, show10daysMA } = useSelector((state: RootState) => state.LineChart);

  const moveAverage = [
    {
      type: 'show5daysMA',
      text: '5 days moving average',
      onClick: () => {
        dispatch(setShow5D(!show5daysMA));
        dispatch(setShow10D(false));
      },
    },
    {
      type: 'show10daysMA',
      text: '10 days moving average',
      onClick: () => {
        dispatch(setShow10D(!show10daysMA));
        dispatch(setShow5D(false));
      },
    },
  ];

  return (
    <div className="py-4 align-items-center justify-content-center d-flex w-100 mx-2">
      {moveAverage.map(item => (
        <div
          key={item.type}
          className="d-flex justify-content-center align-items-center mx-1 my-1"
          style={{
            minHeight: 32,
            borderRadius: 50,
            padding: '5px 8px',
            minWidth: '150px',
            maxWidth: '150px',
            color:
              (show5daysMA && item.type === 'show5daysMA') ||
              (show10daysMA && item.type === 'show10daysMA')
                ? ColorPalette.pureWhite
                : ColorPalette.offBlack,
            border: `1px solid ${
              (show5daysMA && item.type === 'show5daysMA') ||
              (show10daysMA && item.type === 'show10daysMA')
                ? ColorPalette.mediumGray
                : ColorPalette.mediumGray
            }`,
            backgroundColor:
              (show5daysMA && item.type === 'show5daysMA') ||
              (show10daysMA && item.type === 'show10daysMA')
                ? ColorPalette.greenBtn
                : 'transparent',
            width: '100%',
            cursor: 'pointer',
          }}
          onClick={item.onClick}
        >
          <Typography
            color={
              (show5daysMA && item.type === 'show5daysMA') ||
              (show10daysMA && item.type === 'show10daysMA')
                ? ColorPalette.offBlack
                : ColorPalette.offBlack
            }
            variant="body2"
            style={{ fontSize: '0.75rem' }}
          >
            {item.text}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default MovingAverage;
