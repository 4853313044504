import { Drawer, DrawerProps } from '@mui/material';
import React from 'react';

export interface CustomSheetProps extends DrawerProps {
  onOpenChange?: (state: boolean) => void;
  onOpen?: (event: React.SyntheticEvent<unknown, Event>) => void;
  headerComponent?: React.ReactNode;
  headerClassName?: string;
  centerTitle?: boolean;
}

const CustomSheet: React.FunctionComponent<CustomSheetProps> = ({
  children,
  onOpenChange,
  // centerTitle,
  // headerComponent,
  // headerClassName,
  ...props
}) => {
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    if (onOpenChange) {
      onOpenChange(open);
    }
  };

  return (
    <Drawer onClose={toggleDrawer(false)} {...props}>
      {children}
    </Drawer>
  );
};

export default CustomSheet;
