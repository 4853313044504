export type DataSetType = 'PRICE' | 'SENTIMENT' | 'NEWS';
export type DataRange = 'DAILY' | 'WEEKLY';

export interface ScatterChartState {
  XAxis: DataSetType;
  YAxis: DataSetType;
  dataRange: DataRange;
  xIndex: number;
  yIndex: number;
}

export const SET_X_AXIS = 'SET_X_AXIS';
export const SET_Y_AXIS = 'SET_Y_AXIS';
export const SET_X_INDEX = 'SET_X_INDEX';
export const SET_Y_INDEX = 'SET_Y_INDEX';
export const SET_DATA_RANGE = 'SET_DATA_RANGE';

interface setXAxis {
  type: typeof SET_X_AXIS;
  payload: DataSetType;
}
interface setYAxis {
  type: typeof SET_Y_AXIS;
  payload: DataSetType;
}
interface setXIndex {
  type: typeof SET_X_INDEX;
  payload: number;
}
interface setYIndex {
  type: typeof SET_Y_INDEX;
  payload: number;
}
interface setDataRange {
  type: typeof SET_DATA_RANGE;
  payload: DataRange;
}

export type ScatterChartActionsType = setXAxis | setYAxis | setDataRange | setXIndex | setYIndex;
