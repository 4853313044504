import React, { useEffect } from 'react';
import { CssBaseline, ThemeProvider as MuiThemeProvider, Theme } from '@mui/material';
import theme from 'theme';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface,@typescript-eslint/no-unused-vars
  interface DefaultTheme extends Theme {}
}

const ThemeProvider: React.FunctionComponent = ({ children }) => {
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles);
    }
  }, []);

  return (
    <MuiThemeProvider theme={theme()}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
