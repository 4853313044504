import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { Input, Typography, ButtonBase, Button, useMediaQuery } from '@mui/material';
import { SetPrefAsset, setSecondPrefId, setFirstPrefId } from 'store/app/actions';
import { RootState } from 'store';
import { BubbleData } from 'models/Bubble';
import { ISearchAsset } from 'models/SearchAsset';
import ColorPalette from 'utils/ColorPalette';
import useStyles from '../_styles';

const SearchBar: React.FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const data = useSelector<RootState, ISearchAsset[]>(s => s.App.searchData);
  const isShowMobile = useMediaQuery('(min-width:1000px');
  const displaySearch = useSelector<RootState, boolean>(s => s.App.displaySearch);
  const defaultBubbles = useSelector<RootState, BubbleData[]>(s => s.App.bubbleData);
  const [toggleSearchBar, setSearchBar] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [searchList, setSearchList] = useState<any[] | undefined>([]);
  const [assetId, setAssetId] = useState();
  const ref = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const buttonsStrings = ['Add to Chart', 'Stock Page'];
  const isShowButtons = data?.some(el => el.name === inputValue);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (toggleSearchBar && ref.current && !ref.current.contains(e.target)) {
        setSearchBar(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [toggleSearchBar]);

  const SearchHandler = (e: string) => {
    setInputValue(e);
    const filteredData = data?.filter(
      el =>
        el.name.toLowerCase().startsWith(e.toLowerCase()) ||
        el.ticker.toLowerCase().startsWith(e.toLowerCase()),
    );
    setSearchList(e === '' ? [] : filteredData);
    setSearchBar(true);
  };

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        width: displaySearch && !isShowMobile ? '90%' : undefined,
        justifyContent: 'center',
        alignItems: 'center',
      }}
      ref={ref}
    >
      <Input
        className={
          displaySearch && !isShowMobile ? classes.searchBarMobile : classes.searchBarContainer
        }
        disableUnderline
        placeholder={pathname.includes('asset') ? 'Search' : 'Search and add to chart...'}
        inputProps={{ style: { color: ColorPalette.white } }}
        onChange={e => SearchHandler(e.target.value)}
        value={inputValue}
        endAdornment={
          isShowButtons ? (
            <div className="d-flex p-0">
              {!pathname.includes('asset') && (
                <Button
                  onClick={() => {
                    dispatch(SetPrefAsset(data.find(el => el.name === inputValue)));
                    if (defaultBubbles.length === 11) {
                      dispatch(setSecondPrefId(data.find(el => el.name === inputValue)?.id));
                    }
                    if (defaultBubbles.length === 10) {
                      dispatch(setFirstPrefId(data.find(el => el.name === inputValue)?.id));
                    }
                  }}
                  style={{
                    backgroundColor: ColorPalette.grey,
                    borderRadius: 50,
                    width: 80,
                    height: 20,
                  }}
                >
                  <Typography
                    style={{ fontSize: '0.5rem', color: ColorPalette.black, fontWeight: 'bold' }}
                  >
                    {buttonsStrings[0]}
                  </Typography>
                </Button>
              )}
              <Button
                style={{
                  backgroundColor: ColorPalette.grey,
                  borderRadius: 50,
                  marginInline: 8,
                  width: 70,
                  height: 20,
                }}
                onClick={() => push(`/asset/${assetId}`)}
              >
                <Typography
                  style={{ fontSize: '0.5rem', color: ColorPalette.black, fontWeight: 'bold' }}
                >
                  {buttonsStrings[1]}
                </Typography>
              </Button>
            </div>
          ) : undefined
        }
      />
      {searchList && searchList.length > 0 && toggleSearchBar && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: ColorPalette.primary,
            top: 35,
            border: '1px solid white',
            width: displaySearch ? '90%' : '100%',
            maxHeight: '200px',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {searchList?.map(el => (
            <ButtonBase
              key={el}
              className="w-100 d-flex justify-content-start my-1 ps-2"
              onClick={() => {
                setAssetId(el.id);
                setInputValue(el.name);
                setSearchBar(false);
              }}
            >
              <Typography style={{ color: ColorPalette.white }} key={el}>
                {el.name} ({el.ticker})
              </Typography>
            </ButtonBase>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchBar;
