import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import ColorPalette from 'utils/ColorPalette';

const About: React.FunctionComponent = () => {
  return (
    <Container>
      <Box
        sx={{
          backgroundColor: ColorPalette.pureWhite,
          textAlign: 'left',
          padding: '10px',
          marginBlock: '10px',
          '&  < p': {
            color: ` ${ColorPalette.primary} !important`,
            textDecoration: 'none',
          },
        }}
      >
        <Typography sx={{ color: ColorPalette.offBlack }}>
          <br></br>
          <strong>About Us:</strong> <br></br>
          <br></br>
          <p>
            Founded in London, UK, in 2023, Harbinger Technovation is dedicated to developing and
            operating niche market screeners. Our inaugural product, sTalk Exchange, was created in
            response to the growing demand from retail investors for efficient market insights and
            interactive feedback tools.
          </p>{' '}
          <br></br>
          <p>
            At Harbinger Technovation, our product development cycle starts with intelligent demand
            identification. We then create innovative solutions utilizing state-of-the-art
            technologies and scientific expertise. sTalk monitors the market sentiment of over 1,500
            stocks daily, providing users with numerical indications of expert opinions. This allows
            users to perform their own analyses on trends and potential forecasting indicators to
            support their trading decisions.
          </p>
          <br></br>
          <p>
            sTalk also leverages the Wisdom of the Crowds concept, collecting data from users and
            presenting it as statistics that reflect the sentiment of retail investors with various
            levels of financial expertise. This unique combination of expert insights and
            crowd-sourced data empowers our users to make informed and confident investment
            decisions.
          </p>
        </Typography>
      </Box>
    </Container>
  );
};

export default About;
