/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AuthApi } from 'api/Auth';
import { DataApi } from 'api/Data';
import { VoteHistory } from 'models/VoteHistory';

export const loadTable = async (req: any) => {
  try {
    const response = await DataApi.getList(req);
    if (response.data) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

export const loadBookmark = async () => {
  try {
    const response = await AuthApi.getBookmarks();
    if (response.data) {
      return response.data;
    }
  } catch (err) {
    throw err;
  }
};

export const createBookmark = async (id: number): Promise<void> => {
  try {
    await AuthApi.createBookmark(id);
  } catch (err) {
    throw err;
  }
};

export const deleteBookmark = async (id: number): Promise<void> => {
  try {
    await AuthApi.deleteBookmark(id);
  } catch (err) {
    throw err;
  }
};

export const userVoteHistory = async (): Promise<VoteHistory | undefined> => {
  try {
    const response = await AuthApi.getUserVoteHistory();
    if (response.data) {
      return response.data.result;
    }
  } catch (err) {
    throw err;
  }
  return undefined;
};
