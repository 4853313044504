import { VoteResponse } from 'models/Survey';

export type VoteType = 'like' | 'dislike' | 'neutral' | 'not_voted';

export interface SentimentState {
  isUserVoted: boolean;
  sliderStockIndex: number;
  isEndViewShow: boolean;
  VoteData: VoteResponse;
  VoteType: VoteType;
}

export const SET_IS_VOTED = 'SET_IS_VOTED';
export const SET_SLIDER_STOCK_INDEX = 'SET_SLIDER_STOCK_INDEX';
export const SET_IS_END_VIEW_SHOW = 'SET_IS_END_VIEW_SHOW';
export const SET_VOTE_DATA = 'SET_VOTE_DATA';
export const SET_VOTE_TYPE = 'SET_VOTE_TYPE';

interface SetIsUserVoted {
  type: typeof SET_IS_VOTED;
  payload: boolean;
}
interface SetSliderStockIndex {
  type: typeof SET_SLIDER_STOCK_INDEX;
  payload: number;
}
interface SetIsEndViewShow {
  type: typeof SET_IS_END_VIEW_SHOW;
  payload: boolean;
}
interface SetVoteDataShow {
  type: typeof SET_VOTE_DATA;
  payload: VoteResponse;
}

interface SetVoteType {
  type: typeof SET_VOTE_TYPE;
  payload: VoteType;
}

export type SentimentActionTypes =
  | SetIsUserVoted
  | SetSliderStockIndex
  | SetIsEndViewShow
  | SetVoteDataShow
  | SetVoteType;
