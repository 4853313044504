import thunk, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { combineReducers, createStore, applyMiddleware, compose, AnyAction } from 'redux';
import appReducer from './app/reducer';
import sentimentReducer from './sentiment/reducer';
import lineChartReducer from './lineChart/reducer';
import scatterChartReducer from './scatterChart/reducer';
import informationPageReducer from './informationPage/reducer';

const rootReducer = combineReducers({
  App: appReducer,
  Sentiments: sentimentReducer,
  LineChart: lineChartReducer,
  ScatterChart: scatterChartReducer,
  InformationPage: informationPageReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export type ThunkCustomAction<T = Promise<void>> = ThunkAction<T, RootState, unknown, AnyAction>;

export type ThunkCustomDispatch = ThunkDispatch<any, unknown, AnyAction>;

const composeEnhancers =
  typeof window === 'object' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  process.env.NODE_ENV !== 'production'
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

const store = createStore(rootReducer, {}, enhancer);
export default store;
