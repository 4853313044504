// @ts-ignore
import asapMedium from 'assets/fonts/Asap-Medium.ttf';
import { unstable_createMuiStrictModeTheme as createMuiTheme, Theme } from '@mui/material/styles';
import ColorPalette from 'utils/ColorPalette';

const AsapMedium = {
  fontFamily: 'Asap',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `url(${asapMedium}) format('truetype')`,
};

const theme = (): Theme => {
  return createMuiTheme({
    palette: {
      primary: {
        main: ColorPalette.primary,
      },
      text: {
        primary: ColorPalette.primary,
      },
      secondary: {
        main: ColorPalette.newSuccess,
        contrastText: ColorPalette.white,
      },
      info: {
        main: ColorPalette.grey,
      },
    },
    typography: {
      fontFamily: 'Asap',
      caption: { color: ColorPalette.white },
      button: {
        textTransform: 'none',
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1100,
        xl: 1920,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          '@font-face': [AsapMedium],
        },
      },
      MuiTypography: {
        styleOverrides: {
          subtitle2: {
            color: ColorPalette.white,
            fontFamily: 'AsapRegular',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '30px',
            fontSize: '18px',
            fontWeight: 600,
            textTransform: 'none',
          },
          contained: {
            boxShadow: 'none',
          },
          outlined: {
            boxShadow: 'none',
          },
        },
      },
    },
  });
};

export default theme;
