import * as Types from './types';

export function setDataRange(newState: Types.DataRange): Types.LineChartActionType {
  return {
    type: Types.SET_DATA_RANGE,
    payload: newState,
  };
}

export function setShow5D(newState: boolean): Types.LineChartActionType {
  return {
    type: Types.SET_SHOW_5D_MA,
    payload: newState,
  };
}

export function setShow10D(newState: boolean): Types.LineChartActionType {
  return {
    type: Types.SET_SHOW_10D_MA,
    payload: newState,
  };
}
