import { useQuery } from 'react-query';
import React, { useState } from 'react';
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';

import { VoteSlide } from 'models/VoteList';
import IconNews from 'assets/icons/IconNews';
import ColorPalette from 'utils/ColorPalette';
import IconGauge from 'assets/icons/IconGauge';
import { loadAssetVoteData } from 'services/stocks';
import NewVoteSection from 'common/UserSentimentSection';

interface ChartProps {
  id: number;
  ticker: string;
  withPercent?: boolean;
}

const VoteSectionMobileView: React.FunctionComponent<ChartProps> = ({
  id,
  ticker,
  withPercent = false,
}) => {
  const isMobile = useMediaQuery('(max-width:820px)');
  const { data } = useQuery<VoteSlide, Error>(
    ['assetVoteData', id],
    () => loadAssetVoteData(id ? +id : 0),
    {
      onSuccess: res => {
        setVote({
          isVoted: res?.visitorVoted !== 'not_voted',
          voteType: res?.visitorVoted,
          voteCount: res?.voteCount,
          totalVotePercent: ((res.likeCount - res.dislikeCount) / res.voteCount) * 100,
          likePercent: Number((res?.likeCount / res?.voteCount) * 100).toFixed(0),
          dislikePercent: Number((res?.dislikeCount / res?.voteCount) * 100).toFixed(0),
          neutralPercent: Number((res?.neutralCount / res?.voteCount) * 100).toFixed(0),
        });
      },
    },
  );

  const color = (percent: number) =>
    percent > 0 ? ColorPalette.newSuccess : percent === 0 ? ColorPalette.grey : ColorPalette.error;

  const [vote, setVote] = useState<any>({
    isVoted: data?.visitorVoted !== 'not_voted',
    voteType: data?.visitorVoted,
    voteCount: data?.voteCount,
  });

  const changeSentiment = 100 * (data?.sentiment ?? 0);
  const NewsBarLocation =
    changeSentiment === 0
      ? 50
      : changeSentiment > 0
      ? changeSentiment / 2 + 50
      : 50 + changeSentiment / 2;

  return (
    <Stack width="100%">
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          padding: '1rem',
          backgroundColor: ColorPalette.darkBlue,
          width: '100%',
          marginTop: '10px',
          flexDirection: `${isMobile ? 'column' : 'row'}`,
        }}
      >
        <Box
          sx={{
            backgroundColor: ColorPalette.pureWhite,
            borderRadius: '5px',
            display: 'grid',
            gridTemplateColumns: `1fr 1fr ${isMobile ? '1fr' : '2fr'}`,
            alignItems: 'center',
            flex: 5,
          }}
        >
          <Stack
            direction="row"
            sx={{
              borderRight: `1px solid ${ColorPalette.primary}`,
              padding: '3px 10px 3px 3px',
              alignItems: 'center',
            }}
          >
            {isMobile ? (
              <Typography fontWeight="bold" lineHeight={1.1} marginLeft="10px" fontSize="10px">
                News <br />
                Sentiment
              </Typography>
            ) : (
              <Typography fontWeight="bold" lineHeight={1.1} marginLeft="10px" fontSize="14px">
                News Sentiment
              </Typography>
            )}
          </Stack>
          <Stack direction="column" alignItems="center" marginX="5px">
            <Typography fontSize={isMobile ? '12px' : '16px'}>
              {data?.referenceNumber && +data?.referenceNumber.toFixed(2)}
              <strong
                style={{
                  fontSize: isMobile ? '9px' : '14px',
                  color: color(data?.referenceReturn ?? 0),
                  marginLeft: '2px',
                }}
              >
                ({data?.referenceReturn})
              </strong>
            </Typography>
            <Typography fontSize={isMobile ? '8px' : '12px'} noWrap>
              Number Of News
            </Typography>
          </Stack>
          <Box
            style={{
              background:
                'linear-gradient(270deg, rgba(37,205,152,1) 0%, rgba(255,255,255,1) 50%, rgba(204,0,51,1) 100%)',
              height: isMobile ? '36px' : '46px',
              minHeight: isMobile ? '36px' : '46px',
              width: '100%',
              borderRadius: '0px 5px 5px 0px',
              position: 'relative',
            }}
          >
            <div
              style={{
                position: 'absolute',
                left: `${NewsBarLocation === 100 ? (isMobile ? 88 : 96) : NewsBarLocation}%`,
                top: `${isMobile ? -12 : -8}px`,
              }}
            >
              <div className="class1"></div>
              <div className="class2"></div>
              <div className="class3" style={{ backgroundColor: 'rgb(255, 255, 255)' }}></div>
            </div>
            {/* <IconGauge
              svgSize={isMobile ? 5 : 7}
              sx={{
                position: 'absolute',
                left: `${NewsBarLocation}%`,
                bottom: '0',
              }}
            />*/}
          </Box>
        </Box>
        <NewVoteSection
          id={id}
          ticker={ticker}
          voted={vote}
          onVote={setVote}
          withPercent={withPercent}
        />
      </Box>
    </Stack>
  );
};

export default VoteSectionMobileView;
