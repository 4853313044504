import React from 'react';
import { IIconProps } from './Icon.props';
import IconContainer from './IconContainer';

const IconHold: React.FC<IIconProps> = ({ svgSize, svgColor, children, ...props }) => {
  return (
    <IconContainer {...props}>
      <svg
        width={svgSize ?? '18'}
        height={svgSize ? svgSize / 6 : '3'}
        viewBox="0 0 18 3"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="18"
          width="3"
          height="18"
          rx="1.5"
          transform="rotate(90 18 0)"
          fill={svgColor ?? 'white'}
        />
      </svg>
      {children}
    </IconContainer>
  );
};

export default IconHold;
