import React from 'react';
import { IIconProps } from './Icon.props';
import IconContainer from './IconContainer';

const IconInfo: React.FC<IIconProps> = ({ ...props }) => {
  return (
    <IconContainer {...props}>
      <svg
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.39344 0.6C4.60606 0.6 1.53577 3.68924 1.53577 7.5C1.53577 11.3108 4.60606 14.4 8.39344 14.4C12.1808 14.4 15.2511 11.3108 15.2511 7.5C15.2511 3.68924 12.1808 0.6 8.39344 0.6ZM0.939453 7.5C0.939453 3.35786 4.27672 0 8.39344 0C12.5102 0 15.8474 3.35786 15.8474 7.5C15.8474 11.6421 12.5102 15 8.39344 15C4.27672 15 0.939453 11.6421 0.939453 7.5ZM8.39344 4.2C8.22877 4.2 8.09528 4.33431 8.09528 4.5C8.09528 4.66569 8.22877 4.8 8.39344 4.8C8.55811 4.8 8.6916 4.66569 8.6916 4.5C8.6916 4.33431 8.55811 4.2 8.39344 4.2ZM7.49896 4.5C7.49896 4.00294 7.89943 3.6 8.39344 3.6C8.88745 3.6 9.28792 4.00294 9.28792 4.5C9.28792 4.99706 8.88745 5.4 8.39344 5.4C7.89943 5.4 7.49896 4.99706 7.49896 4.5ZM8.50754 6.02284C8.61896 6.06927 8.6916 6.17866 8.6916 6.3V10.8H8.98976C9.15443 10.8 9.28792 10.9343 9.28792 11.1C9.28792 11.2657 9.15443 11.4 8.98976 11.4H7.79712C7.63245 11.4 7.49896 11.2657 7.49896 11.1C7.49896 10.9343 7.63245 10.8 7.79712 10.8H8.09528V7.02426L8.00795 7.11213C7.89151 7.22929 7.70273 7.22929 7.58629 7.11213C7.46985 6.99497 7.46985 6.80503 7.58629 6.68787L8.18261 6.08787C8.26788 6.00207 8.39613 5.9764 8.50754 6.02284Z"
          fill="#004588"
        />
      </svg>
    </IconContainer>
  );
};

export default IconInfo;
