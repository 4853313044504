const Content = {
  firstSection: {
    title: 'AI-Driven Market Insights',
    content:
      'Empowering retail investors with AI-driven insights from structured financial news analysis.',
    // `Enhance retail investors' decision-making through access to structured market intelligence data derived from AI analysis of financial news data.`,
  },
  secondSection: {
    title: `What Problems has sTalk solved?`,
    items: [
      {
        title: 'Time',
        content:
          'Distilling key insights from the constantly growing daily financial news can be daunting for retail investors with limited time and experience.',
      },
      {
        title: 'Financial Jargons',
        content:
          'The absence of a platform that simplifies browsing and understanding financial news data.',
      },
      {
        title: 'Multitude of Opinions',
        content:
          'Different news sources often present conflicting messages regarding the same company. sTalk platform consolidates these opinions, providing a clear, unified view.',
      },
      {
        title: 'Conflicting Messages',
        content:
          'A single news report can offer conflicting messages about a company, making it challenging to gauge the impact of various factors on its price.',
      },
      {
        title: 'Biased Reporting',
        content:
          'Financial reporting is often biassed and gives a skewed representation of the true sentiment.',
      },
    ],
  },
  thirdSection: {
    title: 'Simplifying Financial News',
    content: `sTalk enhances financial news navigation by aggregating, categorising, summarising, and providing sentiment analysis of diverse news sources.`,
  },
  fourthSection: {
    title: 'Financial Sentiment Hub',
    content: `A 'Financial Sentiment Hub' designed to deliver a suite of financial news sentiment, peer recommendations, and the collective wisdom of the crowd directly to your fingertips.`,
  },
  lastSection: [
    {
      title: 'Harness Collective Wisdom',
      content:
        'sTalk allows users to speculate and cast their sentiment on price movements, building an ecosystem where users can benefit from and harness collective wisdom.',
      button: 'Start Speculating',
      href: '/list',
    },
    {
      title: 'Sentiment Analysis',
      content:
        'Simultaneous displays of price and sentiment, along with the daily news volume. This allows users to observe and analyze price and sentiment changes more effectively, enhancing their ability to make informed decisions.',
      button: 'Start Analyzing',
      href: '/list',
    },
    {
      title: 'Track Your Sentiment',
      content: 'Track your sentiment, towards a company, over time.',
      button: 'Start Exploring',
      href: '/list',
    },
  ],
};

export default Content;
