import React from 'react';
import { IIconProps } from './Icon.props';
import IconContainer from './IconContainer';

const IconDate: React.FC<IIconProps> = ({ ...props }) => {
  return (
    <IconContainer {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="35"
        viewBox="0 0 52 52"
        fill="none"
      >
        <path
          d="M26 1H1V51H26V1Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.25 18.708L13.5 24.958L17.6667 20.7913L30.1667 33.2913L36.4167 27.0413L40.5833 31.208L51 20.7913"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.75 7.25H7.25V13.5H19.75V7.25Z"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M7.25 32.25H19.75"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M7.25 36.417H19.75"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M7.25 40.583H19.75"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M7.25 44.75H19.75"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </svg>
    </IconContainer>
  );
};

export default IconDate;
