import * as Types from './types';

const initialState: Types.SentimentState = {
  isUserVoted: false,
  sliderStockIndex: 0,
  isEndViewShow: false,
  VoteType: 'not_voted',
  VoteData: {
    id: 0,
    name: '',
    ticker: '',
    like_count: 0,
    dislike_count: 0,
    neutral_count: 0,
    vote_count: 0,
  },
};

function sentimentReducer(
  state = initialState,
  actions: Types.SentimentActionTypes,
): Types.SentimentState {
  switch (actions.type) {
    case Types.SET_IS_VOTED:
      return { ...state, isUserVoted: actions.payload };
    case Types.SET_IS_END_VIEW_SHOW:
      return { ...state, isEndViewShow: actions.payload };
    case Types.SET_SLIDER_STOCK_INDEX:
      return { ...state, sliderStockIndex: actions.payload };
    case Types.SET_VOTE_DATA:
      return { ...state, VoteData: actions.payload };
    case Types.SET_VOTE_TYPE:
      return { ...state, VoteType: actions.payload };
    default:
      return state;
  }
}

export default sentimentReducer;
