export interface ChangeData {
  calculation_date: string;
  reference_return: number;
  sentiment_return: number;
  num_of_references: number;
  price: number;
  price_return: number;
  sentiment: number;
  price_five_ma: number;
  price_ten_ma: number;
  reference_five_ma: number;
  reference_ten_ma: number;
  sentiment_five_ma: number;
  sentiment_ten_ma: number;
}
export interface IServerChartDataResponse {
  id: number;
  name: string;
  ticker: string;
  change: ChangeData[];
}
export class ChartData {
  id: number;
  name: string;
  ticker: string;
  date: string[];
  price: number[];
  priceReturn: number[];
  sentiment: number[];
  sentimentReturn: number[];
  news: number[];
  newsReturn: number[];
  price5MovingAvg: number[];
  price10MovingAvg: number[];
  sentiment5MovingAvg: number[];
  sentiment10MovingAvg: number[];
  news5MovingAvg: number[];
  news10MovingAvg: number[];
  constructor(props: IServerChartDataResponse) {
    this.id = props.id;
    this.name = props.name;
    this.ticker = props.ticker;
    this.date = props.change.map(item => item.calculation_date);
    this.price = props.change.map(item => item.price);
    this.priceReturn = props.change.map(item => item.price_return);
    this.sentiment = props.change.map(item => item.sentiment);
    this.sentimentReturn = props.change.map(item => item.sentiment_return);
    this.news = props.change.map(item => item.num_of_references);
    this.newsReturn = props.change.map(item => item.reference_return);
    this.price5MovingAvg = props.change.map(item => Number(item.price_five_ma.toFixed(1)));
    this.price10MovingAvg = props.change.map(item => Number(item.price_ten_ma.toFixed(1)));
    this.sentiment5MovingAvg = props.change.map(item => item.sentiment_five_ma);
    this.sentiment10MovingAvg = props.change.map(item => item.sentiment_ten_ma);
    this.news5MovingAvg = props.change.map(item => item.reference_five_ma);
    this.news10MovingAvg = props.change.map(item => item.reference_ten_ma);
  }
}
