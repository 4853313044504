import React from 'react';
import { IIconProps } from './Icon.props';
import IconContainer from './IconContainer';

const IconDown: React.FC<IIconProps> = ({ svgSize, svgColor, children, ...props }) => {
  return (
    <IconContainer {...props}>
      <svg
        width={svgSize ?? '18'}
        height={svgSize ? svgSize / 2 : '9'}
        viewBox="0 0 18 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.05275 -1.48158e-06L16.9502 -9.17797e-08C17.8822 -1.02963e-08 18.3535 1.01329 17.6885 1.60438L9.73978 8.72557C9.33134 9.09148 8.66633 9.09148 8.2579 8.72557L0.309195 1.60438C-0.35058 1.01329 0.115451 -1.56352e-06 1.04751 -1.48203e-06L1.05275 -1.48158e-06Z"
          fill={svgColor ?? 'white'}
        />
      </svg>
      {children}
    </IconContainer>
  );
};

export default IconDown;
