import React from 'react';
import { useSelector } from 'react-redux';
import ColorPalette from '../../utils/ColorPalette';
import { RootState } from 'store';
import { Box, Container, useMediaQuery } from '@mui/material';
import NavbarLogo from './components/NavbarLogo';
import NavbarItems from './components/NavbarItems';
import SearchBar from './components/SearchBar';
import MobileNav from './mobile';
import useStyles from './_styles';
import { useLocation } from 'react-router-dom';

const Navbar: React.FunctionComponent = () => {
  const classes = useStyles();
  const isDisplaySerach = useSelector<RootState, boolean>(s => s.App.displaySearch);
  const matches = useMediaQuery('(min-width:1000px)');
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: isHome ? '#fff' : ColorPalette.darkBlue,
        borderBottom: 'None',
      }}
    >
      <Container className={classes.navbar} sx={{ backgroundColor: isHome ? '#fff' : 'inherit' }}>
        {matches ? (
          <div className="d-flex w-100 align-items-center">
            <NavbarLogo width={50} height={50} isHome={isHome} />
            <NavbarItems />
          </div>
        ) : (
          <div className="d-flex flex-column w-100 align-items-center">
            <MobileNav />
            {isDisplaySerach && !matches && <SearchBar />}
          </div>
        )}
      </Container>
    </Box>
  );
};
export default Navbar;
