import store from 'store';

import { VoteReq } from 'api';
import { DataApi } from 'api/Data';
import { News } from 'models/News';
import { VoteSlide } from 'models/VoteList';
import { ChartData } from './../models/Chart';
import { setSearchData } from 'store/app/actions';
import { ISearchAsset } from 'models/SearchAsset';
import { Information, NewsSentiments } from 'models/NewsSentiment';

export const loadStocksSearchList = async (): Promise<ISearchAsset[]> => {
  try {
    const response = await DataApi.getSearchList();
    if (response?.data) {
      store.dispatch(setSearchData(response?.data));
      return response?.data;
    }
    return [];
  } catch (err) {
    throw err;
  }
};

export const loadAssetChartData = async (id: number): Promise<ChartData> => {
  try {
    const response = await DataApi.getChartData(id);
    if (response.data) {
      return new ChartData(response.data);
    }
    return new ChartData({} as any);
  } catch (err) {
    throw err;
  }
};

export const loadAssetVoteData = async (id: number): Promise<VoteSlide> => {
  try {
    const response = await DataApi.getAssetVoteData(id);
    if (response.data) {
      return new VoteSlide(response.data.result[0]);
    }
    return new VoteSlide({} as any);
  } catch (err) {
    throw err;
  }
};

export const loadVoteSlider = async () => {
  try {
    const response = await DataApi.getVoteStockList();
    if (response.data) {
      // @ts-ignore
      return response?.data.result.map(item => new VoteSlide(item));
    }
  } catch (err) {
    throw err;
  }
};

export const voteService = async (req: VoteReq) => {
  try {
    const response = await DataApi.Vote(req);
    if (response.data) {
      return response?.data.result[0];
    }
  } catch (err) {
    throw err;
  }
};

export const NewsService = async (id: number): Promise<News[]> => {
  try {
    const response = await DataApi.getNews(id);
    if (response.data) {
      return response?.data.result;
    }
    return [];
  } catch (err) {
    throw err;
  }
};

export const SummaryNewsService = async (id: number): Promise<NewsSentiments> => {
  try {
    const response = await DataApi.getNewsSummary(id);
    if (response.data) {
      return response?.data.result[0];
    }
    return {} as NewsSentiments;
  } catch (err) {
    throw err;
  }
};
export const StockInformation = async (id: number): Promise<Information> => {
  try {
    const response = await DataApi.getStockInformation(id);
    if (response.data) {
      return response?.data.result[0];
    }
    return {} as Information;
  } catch (err) {
    throw err;
  }
};
