import React from 'react';
import { Box, Theme, useMediaQuery, Container } from '@mui/material';
import DesktopBack from 'assets/images/backDesk5.png';
import MobileBack from 'assets/images/backMob5.png';
import useStyles from './_styles';

const FirstSection: React.FC = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  return (
    <Box
      sx={{
        background: isMobile ? `url(${MobileBack})` : `url(${DesktopBack})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Container className={classes.linkContainer}>
        <p>
          Need help ? Contact us at 
          <a href="mailto:stalk-exchange@harbinger-technovation.com">
            stalk-exchange@harbinger-technovation.com
          </a>
        </p>
      </Container>
    </Box>
  );
};

export default FirstSection;
