import React from 'react';
import { useQuery } from 'react-query';
import { Box, Stack, useMediaQuery } from '@mui/material';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';

import ColorPalette from 'utils/ColorPalette';
import { Information } from 'models/NewsSentiment';
import { StockInformation } from 'services/stocks';
import { BlackTextTypography } from 'common/CustomTypography';

const InformationTab: React.FC<{ id?: string }> = ({ id }) => {
  const isMobile = useMediaQuery('(max-width:800px)');
  const { data } = useQuery<Information, Error>(
    ['assetInformation', id],
    () => StockInformation(id ? +id : 0),
    {
      retry: 1,
    },
  );

  return (
    <div
      className="d-flex h-100 w-100 px-4 pt-2 flex-column"
      style={{
        backgroundColor: ColorPalette.offWhite,
      }}
    >
      {data?.summary &&
        Object.keys(data?.summary).map((key, index) => {
          return (
            <Box
              key={index}
              sx={{
                borderBottom:
                  Object.keys(data.summary).length !== index + 1
                    ? `1px solid ${ColorPalette.newGrey}`
                    : 'none',
                paddingBlock: '15px',
              }}
            >
              <Stack direction="row" alignItems="center">
                <BlackTextTypography fontSize="18px">{key}</BlackTextTypography>
              </Stack>
              {Array.isArray(data.summary[key as keyof typeof data.summary]) ? (
                <ul
                  style={{
                    marginTop: '10px',
                    color: ColorPalette.darkBlue,
                    fontSize: isMobile ? '12px' : '14px',
                    fontWeight: '400',
                  }}
                >
                  {(data.summary[key as keyof typeof data.summary] as string[]).map(
                    (item: string, index: number) => (
                      <li key={index}>{item}</li>
                    ),
                  )}
                </ul>
              ) : (
                <BlackTextTypography mt={1} fontSize={isMobile ? '12px' : '14px'} fontWeight="400">
                  {data.summary[key as keyof typeof data.summary] as string}{' '}
                </BlackTextTypography>
              )}
            </Box>
          );
        })}
    </div>
  );
};
export default InformationTab;
