import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import ColorPalette from 'utils/ColorPalette';

const Disclaimers: React.FunctionComponent = () => {
  return (
    <Container>
      <Box
        sx={{
          backgroundColor: ColorPalette.pureWhite,
          borderRadius: '20px',
          textAlign: 'left',
          padding: '10px',
          marginTop: '10px',
          '&  < p': {
            color: ` ${ColorPalette.primary} !important`,
            textDecoration: 'none',
          },
        }}
      >
        <Typography sx={{ color: ColorPalette.offBlack }}>
          <h1 style={{ marginTop: '20px', textAlign: 'center', fontSize: 23 }}>Privacy Policy</h1>
          <section>
            <h1 style={{ textIndent: '20px', fontSize: 20 }}>Introduction</h1>
            <section>
              <h2 style={{ textIndent: '20px', fontSize: 16 }}>1.1 Important Information</h2>
              <p style={{ marginLeft: '20px', marginTop: '8px' }}>
                Harbinger Technovation LTD (<b>“we”</b>, <b>“us”</b>, or <b>“our”</b>) operates the
                <a href="stalk-exchange.com"> stalk-exchange.com</a> website (hereinafter referred
                to as the "Service").
              </p>
              <p style={{ marginLeft: '20px', marginTop: '5px' }}>
                At Harbinger technovation Ltd we are committed to protecting and respecting your
                privacy and Personal Data in compliance with the United Kingdom General Data
                Protection Regulation (“GDPR”), the Data Protection Act 2016 and all other mandatory
                laws and regulations of the United Kingdom. This Privacy Policy explains how we
                collect, process and keep your data safe. The Privacy Policy will tell you about
                your privacy rights, how the law protects you, and inform our employees and staff
                members of all their obligations and protocols when processing data.
              </p>
              <p style={{ marginLeft: '20px', marginTop: '5px' }}>
                The individuals from which we may gather and use data can include:
                <ul>
                  <li>Customers</li>
                  <li style={{ marginTop: '3px' }}>Suppliers</li>
                  <li style={{ marginTop: '3px' }}>Business contacts</li>
                </ul>
                and any other people that the organisation has a relationship with or may need to
                contact.
              </p>
              <p style={{ marginLeft: '20px', marginTop: '5px' }}>
                This Privacy Policy applies to all our employees and staff members and all Personal
                Data processed at any time by us.
              </p>
            </section>
            <section>
              <h2 style={{ textIndent: '20px', fontSize: 16 }}>1.2 Your Data Controller</h2>
              <p style={{ marginLeft: '20px', marginTop: '8px' }}>
                Harbinger technovation Ltd is your Data Controller and responsible for your Personal
                Data. We are not obliged by the GDPR to appoint a data protection officer and have
                not voluntarily appointed one at this time. Therefore, any inquiries about your data
                should either be sent to us by email to stalk-exchange@harbinger-technovation.com or
                by post to 27 Old Gloucester Street, London, WC1N 3AX, United Kingdom.
              </p>
              <p style={{ marginLeft: '20px', marginTop: '5px' }}>
                You have the right to make a complaint at any time to the Information Commissioner’s
                Office (ICO), the UK supervisory authority for data protection issues
                (www.ico.org.uk). We would, however, appreciate the chance to deal with your
                concerns before you approach the ICO so please contact us in the first instance.
              </p>
            </section>
            <section>
              <h2 style={{ textIndent: '20px', fontSize: 16 }}>1.3 Processers' Responsibilities</h2>
              <p style={{ marginLeft: '20px', marginTop: '8px' }}>
                In discharging our responsibilities as a Data Controller we have employees who will
                deal with your data on our behalf (known as <b>“Processors”</b>). The
                responsibilities below may be assigned to an individual or may be taken to apply to
                the organisation as a whole. The Data Controller and our Processors have the
                following responsibilities:
                <ul>
                  <li>
                    Ensure that all processing of Personal Data is governed by one of the legal
                    bases laid out in the GDPR (see 2.2 below for more information);
                  </li>
                  <li style={{ marginTop: '3px' }}>
                    Ensure that Processors authorised to process Personal Data have committed
                    themselves to confidentiality or are under an appropriate statutory obligation
                    of confidentiality;
                  </li>
                  <li style={{ marginTop: '3px' }}>
                    Implement appropriate technical and organisational measures to ensure a level of
                    security appropriate to the risk associated with the processing of Personal
                    Data;
                  </li>
                  <li style={{ marginTop: '3px' }}>
                    Obtain the prior specific or general authorisation of the Controller before
                    engaging another Processor;
                  </li>
                  <li style={{ marginTop: '3px' }}>
                    Assist the Controller in the fulfilment of the Controller's obligation to
                    respond to requests for exercising the data subject's rights;
                  </li>
                  <li style={{ marginTop: '3px' }}>
                    Make available to the Controller all information necessary to demonstrate
                    compliance with the obligations laid down in the GDPR and allow for and
                    contribute to audits, including inspections, conducted by the Controller or
                    another auditor mandated by the Controller;
                  </li>
                  <li style={{ marginTop: '3px' }}>
                    Maintain a record of all categories of processing activities carried out on
                    behalf of a Controller;
                  </li>
                  <li style={{ marginTop: '3px' }}>
                    Cooperate, on request, with the supervisory authority in the performance of its
                    tasks;
                  </li>
                  <li style={{ marginTop: '3px' }}>
                    Ensure that any person acting under the authority of the Processor who has
                    access to Personal Data does not process Personal Data except on instructions
                    from the Controller; and
                  </li>
                  <li style={{ marginTop: '3px' }}>
                    Notify the Controller without undue delay after becoming aware of a Personal
                    Data Breach.
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section>
            <h1 style={{ textIndent: '20px', fontSize: 20 }}>2. DATA COLLECTION</h1>
            <section>
              <h2 style={{ textIndent: '20px', fontSize: 16 }}>2.1 Privacy Policy Scope</h2>
              <p style={{ marginLeft: '20px', marginTop: '5px' }}>
                <b>“Personal Data”</b> means any information about an individual from which that
                person can be identified. It does not include data where the identity has been
                removed (anonymous data).
              </p>
              <p style={{ marginLeft: '20px', marginTop: '8px' }}>
                We may collect, use, store and transfer different kinds of Personal Data about you
                which we have grouped together below. Not all of the following types of data will
                necessarily be collected from you but this is the full scope of data that we collect
                and when we collect it from you:
                <ul>
                  <li>
                    Contact Data: This is data relating to your phone number, addresses, email
                    addresses, phone numbers.
                  </li>
                  <li style={{ marginTop: '3px' }}>
                    Marketing and Communications Data: This is your preferences in receiving
                    marketing information and other information from us.
                  </li>
                  <li style={{ marginTop: '3px' }}>
                    Billing Data: This is information relating to your debit and credit card
                    information such as the name attached to your payment details and your billing
                    address.
                  </li>
                  <li style={{ marginTop: '3px' }}>
                    Financial Data: These are your banking details e.g. your account number and sort
                    code.
                  </li>
                  <li style={{ marginTop: '3px' }}>
                    Transactional Data: This is information of details and records of all payments
                    you have made for our services or products.
                  </li>
                </ul>
              </p>
              <p style={{ marginLeft: '20px', marginTop: '8px' }}>
                We also collect, use and share Aggregated Data such as website usage statistics,
                voting frequency, and analytics related to voting. Aggregated Data could be derived
                from your Personal Data but is not considered Personal Data in law as this data will
                not directly or indirectly reveal your identity. However, if we combine or connect
                Aggregated Data with your Personal Data so that it can directly or indirectly
                identify you, we treat the combined data as Personal Data which will be used in
                accordance with this Privacy Policy.
              </p>
              <p style={{ marginLeft: '20px', marginTop: '8px' }}>
                We may also aggregate data to enable research or analysis so that we can better
                understand and serve you and others. For example, we may conduct research on your
                demographics and usage. Although this aggregated data may be based in part on
                Personal Data, it does not identify you personally. We may share this type of
                anonymous data with others, including service providers, our affiliates, agents and
                current and prospective business partners.
              </p>
              <p style={{ marginLeft: '20px', marginTop: '8px' }}>
                We do not collect any Special Categories of Personal Data about you (this includes
                details about your race or ethnicity, religious or philosophical beliefs, sex life,
                sexual orientation, political opinions, trade union membership, information about
                your health, and genetic and biometric data). Nor do we collect any information
                about criminal convictions and offences.
              </p>
            </section>
            <section>
              <h2 style={{ textIndent: '20px', fontSize: 16 }}>
                2.1 Legal Basis for Data Collection
              </h2>
              <p style={{ marginLeft: '20px', marginTop: '5px' }}>
                There are a number of justifiable reasons under the GDPR that allow collection and
                processing of Personal Data. The main avenues we rely on are:
                <ul>
                  <li>
                    <b>“Consent”</b>: Certain situations allow us to collect your Personal Data,
                    such as when you tick a box that confirms you are happy to receive email
                    newsletters from us, or ‘opt in’ to a service.
                  </li>
                  <li style={{ marginTop: '3px' }}>
                    <b>“Contractual Obligations”</b>: We may require certain information from you in
                    order to fulfil our contractual obligations and provide you with the promised
                    service.
                  </li>
                  <li style={{ marginTop: '3px' }}>
                    <b>“Legal Compliance”</b>: We’re required by law to collect and process certain
                    types of data, such as fraudulent activity or other illegal actions.
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section>
            <h1 style={{ textIndent: '20px', fontSize: 20 }}>3. YOUR PERSONAL DATA</h1>
            <section>
              <h2 style={{ textIndent: '20px', fontSize: 16 }}>3.1 Data Uses</h2>
              <p style={{ marginLeft: '20px', marginTop: '5px' }}>
                We will only use your Personal Data when the law allows us to.
              </p>
              <h2 style={{ textIndent: '20px', fontSize: 16 }}>
                3.2 Marketing and Content Updates
              </h2>
              <p style={{ marginLeft: '20px', marginTop: '5px' }}>
                You will receive marketing and new content communications from us if you have
                created an account and chosen to opt into receiving those communications. From time
                to time we may make suggestions and recommendations to you about goods or services
                that may be of interest to you.
              </p>
              <h2 style={{ textIndent: '20px', fontSize: 16 }}>3.3 Change of Purpose</h2>
              <p style={{ marginLeft: '20px', marginTop: '5px' }}>
                We will only use your Personal Data for the purposes for which we collected it,
                unless we reasonably consider that we need to use it for another reason and that
                reason is compatible with the original purpose. If you wish to get an explanation as
                to how the processing for the new purpose is compatible with the original purpose,
                please contact us.
              </p>
              <p style={{ marginLeft: '20px', marginTop: '8px' }}>
                If we need to use your Personal Data for an unrelated purpose, we will notify you
                and we will explain the legal basis which allows us to do so.
              </p>
              <p style={{ marginLeft: '20px', marginTop: '8px' }}>
                Please note that we may process your Personal Data without your knowledge or
                consent, in compliance with the above rules, where this is required or permitted by
                law.
              </p>
            </section>
          </section>
          <section>
            <h1 style={{ textIndent: '20px', fontSize: 20 }}>4. YOUR RIGHTS</h1>
            <section>
              <h2 style={{ textIndent: '20px', fontSize: 16 }}>4.1 Your Legal Rights</h2>
              <p style={{ marginLeft: '20px', marginTop: '5px' }}>
                Under certain circumstances, you have the following rights under data protection
                laws in relation to your personal data:
                <ul>
                  <li>
                    <b>Right to be informed</b>. You have a right to be informed about our purposes
                    for processing your personal data, how long we store it for, and who it will be
                    shared with. We have provided this information to you in this policy.
                  </li>
                  <li>
                    <b>Right of access</b>. This enables you to receive a copy of the personal data
                    we hold about you and to check that we are lawfully processing it (also known as
                    a "data subject access request"). See section 4.5 below for more details on how
                    you can make a data subject access request.
                  </li>
                  <li>
                    <b>Right to rectification</b>. You have a right to request correction of the
                    personal data that we hold about you. This enables you to have any incomplete or
                    inaccurate data we hold about you corrected, though we may need to verify the
                    accuracy of the new data you provide to us.
                  </li>
                  <li>
                    <b>Right to erasure</b>. You have the right to ask us to delete or remove
                    personal data where there is no good reason for us continuing to process it,
                    where you have successfully exercised your right to object to processing (see
                    below), where we may have processed your information unlawfully or where we are
                    required to erase your personal data to comply with local law. Note, however,
                    that we may not always be able to comply with your request of erasure for
                    specific legal reasons which will be notified to you, if applicable, at the time
                    of your request.
                  </li>
                  <li>
                    <b>Right to object</b>. You can object to the processing of personal data we
                    hold about you. This effectively allows you to stop or prevent us from
                    processing your personal data. Note that this is not an absolute right and it
                    only applies in certain circumstances, for example:
                    <ul>
                      <li>
                        Where we are processing your personal data for direct marketing purposes.
                      </li>
                      <li>
                        Where we are relying on a legitimate interest (or those of a third party)
                        and there is something about your particular situation which makes you want
                        to object to processing on this ground as you feel it impacts on your
                        fundamental rights and freedoms.
                      </li>
                      <li>
                        In some cases, we may continue processing your data if we can demonstrate
                        that we have compelling legitimate grounds to process your information which
                        override your rights and freedoms.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b>Right to restrict processing</b>. You have the right to request the
                    restriction or suppression of their personal data. Note that this is not an
                    absolute right and it only applies in certain circumstances:
                    <ul>
                      <li>If you want us to establish the data's accuracy.</li>
                      <li>
                        Where our use of the data is unlawful but you do not want us to erase it.
                      </li>
                      <li>
                        Where you need us to hold the data even if we no longer require it as you
                        need it to establish, exercise or defend legal claims.
                      </li>
                      <li>
                        You have objected to our use of your data but we need to verify whether we
                        have overriding legitimate grounds to use it.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b>Right to data portability</b>. You have the right to request the transfer of
                    your personal data to you or to a third party. If you make such a request, we
                    will provide to you, or a third party you have chosen, your personal data in a
                    structured, commonly used, machine-readable format. Note that this right only
                    applies to automated information which you initially provided consent for us to
                    use or where we used the information to perform a contract with you.
                  </li>
                </ul>
                If you wish to make a request under any of these rights, please contact us at stalk-
                exchange@harbinger-technovation.com.
              </p>
            </section>
            <section>
              <h2 style={{ textIndent: '20px', fontSize: 16 }}>4.2 Control Over Personal Data</h2>
              <p style={{ marginLeft: '20px', marginTop: '5px' }}>
                You may delete your account at any time – this will remove your account page from
                our systems and our related software.
              </p>
              <p style={{ marginLeft: '20px', marginTop: '8px' }}>
                We guarantee this will delete all stored data.
              </p>
              <p style={{ marginLeft: '20px', marginTop: '8px' }}>
                You can access information associated with your account by logging into your account
                you created with us.
              </p>
              <p style={{ marginLeft: '20px', marginTop: '8px' }}>
                Your account information will be protected by a password for your privacy and
                security. You need to prevent unauthorized access to your account and personal
                information by selecting and protecting your password appropriately and limiting
                access to your computer or device and by signing off after you have finished
                accessing your account.
              </p>
              <p style={{ marginLeft: '20px', marginTop: '8px' }}>
                <i>
                  California Privacy Rights: Under California Civil Code sections 1798.83-1798.84,
                  California residents are entitled to ask us for a notice identifying the
                  categories of personal customer information which we share with our affiliates
                  and/or third parties for marketing purposes, and providing contact information for
                  such affiliates and/or third parties. If you are a California resident and would
                  like a copy of this notice, please submit a written request to
                  stalk-exchange@harbinger-technovation.com.
                </i>
              </p>
            </section>
            <section>
              <h2 style={{ textIndent: '20px', fontSize: 16 }}>4.3 How We Protect Personal Data</h2>
              <p style={{ marginLeft: '20px', marginTop: '5px' }}>
                We are concerned with keeping your data secure and protecting it from inappropriate
                disclosure. We implement a variety of security measures to ensure the security of
                your Personal Data on our systems, including all the data we receive is encrypted
                end-to-end for your security. Any Personal Data collected by us is only accessible
                by a limited number of employees who have special access rights to such systems and
                are bound by obligations of confidentiality. If and when we use subcontractors to
                store your data, we will not relinquish control of your Personal Data or expose it
                to security risks that would not have arisen had the data remained in our
                possession. However, unfortunately no transmission of data over the internet is
                guaranteed to be completely secure. It may be possible for third parties not under
                the control of Harbinger technovation Ltd to intercept or access transmissions or
                private communications unlawfully. While we strive to protect your Personal Data, we
                cannot ensure or warrant the security of any Personal Data you transmit to us. Any
                such transmission is done at your own risk. If you believe that your interaction
                with us is no longer secure, please contact us.
              </p>
              <h2 style={{ textIndent: '20px', fontSize: 16 }}>4.4 Opting out of Promotions</h2>
              <p style={{ marginLeft: '20px', marginTop: '5px' }}>
                You can ask us to stop sending you marketing messages at any time by sending the
                request to stalk-exchange@harbinger-technovation.com.
              </p>
              <p style={{ marginLeft: '20px', marginTop: '8px' }}>
                Where you opt out of receiving these marketing messages, we will continue to retain
                other Personal Data provided to us as a result of interactions with us not related
                to your marketing preferences.
              </p>
              <h2 style={{ textIndent: '20px', fontSize: 16 }}>4.5 Request Your Data</h2>
              <p style={{ marginLeft: '20px', marginTop: '8px' }}>
                You will not have to pay a fee to access your Personal Data (or to exercise any of
                the other rights). However, if your request is clearly unfounded, we could refuse to
                comply with your request.
              </p>
              <p style={{ marginLeft: '20px', marginTop: '8px' }}>
                We may need to request specific information from you to help us confirm your
                identity and ensure you have the right to access your Personal Data (or to exercise
                any of your other rights). This is a security measure to ensure that Personal Data
                is not disclosed to any person who has no right to receive it. We may also contact
                you to ask you for further information in relation to your request to speed up our
                response.
              </p>
            </section>
          </section>
          <section>
            <h1 style={{ textIndent: '20px', fontSize: 20 }}>5. THIRD PARTIES</h1>
            <h2 style={{ textIndent: '20px', fontSize: 16 }}>5.1 Sharing with third parties</h2>
            <p style={{ marginLeft: '20px', marginTop: '5px' }}>
              We may also share Personal Data with interested parties in the event that Harbinger
              technovation Ltd anticipates a change in control or the acquisition of all or part of
              our business or assets or with interested parties in connection with the licensing of
              our technology.
            </p>
            <p style={{ marginLeft: '20px', marginTop: '8px' }}>
              If Harbinger technovation Ltd is sold or makes a sale or transfer, we may, in our sole
              discretion, transfer, sell or assign your Personal Data to a third party as part of or
              in connection with that transaction. Upon such transfer, the Privacy Policy of the
              acquiring entity may govern the further use of your Personal Data. In all other
              situations your data will still remain protected in accordance with this Privacy
              Policy (as amended from time to time).
            </p>
            <p style={{ marginLeft: '20px', marginTop: '8px' }}>
              We may share your Personal Data at any time if required for legal reasons or in order
              to enforce our terms or this Privacy Policy.
            </p>
          </section>
          <section>
            <h1 style={{ textIndent: '20px', fontSize: 20 }}>6. RETENTION PERIOD</h1>

            <p style={{ marginLeft: '20px', marginTop: '5px' }}>
              We will only retain your Personal Data for as long as reasonably necessary to fulfil
              the purposes we collected it for. We may retain your Personal Data for a longer period
              than usual in the event of a complaint or if we reasonably believe there is a prospect
              of litigation in respect to our relationship with you.
            </p>
          </section>
          <section>
            <h1 style={{ textIndent: '20px', fontSize: 20 }}>7. AGE LIMIT</h1>

            <p style={{ marginLeft: '20px', marginTop: '5px' }}>
              You must not use Harbinger technovation Ltd services, unless you are aged 18 or older.
              If you are under 18 and you access Harbinger technovation Ltd services, by lying about
              your age, you must immediately stop them. Our services are not intended for children
              and we do not knowingly collect data relating to children.
            </p>
          </section>
          <section>
            <h1 style={{ textIndent: '20px', fontSize: 20 }}>8. TRANSFER OF DATA</h1>

            <p style={{ marginLeft: '20px', marginTop: '5px' }}>
              Your information may be stored and processed in the US or other countries or
              jurisdictions outside the US where Harbinger technovation Ltd has facilities. By using
              Harbinger technovation Ltd, you are permitting and consenting to the transfer of
              information, including Personal Data, outside of the US.
            </p>
          </section>
          <section>
            <h1 style={{ textIndent: '20px', fontSize: 20 }}>9. ACCEPTANCE OF POLICY</h1>

            <p style={{ marginLeft: '20px', marginTop: '5px' }}>
              We keep our Privacy Policy under review and will place any updates here. This version
              is dated 29 June 2024. By using Harbinger technovation Ltd services, you consent to
              the collection and use of data by us as set out in this Privacy Policy. Continued
              access or use of Harbinger technovation Ltd services, will constitute your express
              acceptance of any modifications to this Privacy Policy.
            </p>
          </section>
          <section>
            <h1 style={{ textIndent: '20px', fontSize: 20 }}>10. INTERPRETATION</h1>

            <p style={{ marginLeft: '20px', marginTop: '5px' }}>
              All uses of the word "including" mean "including but not limited to" and the
              enumerated examples are not intended to in any way limit the term which they serve to
              illustrate. Any email addresses set out in this policy may be used solely for the
              purpose for which they are stated to be provided, and any unrelated correspondence
              will be ignored. Unless otherwise required by law, we reserve the right to not respond
              to emails, even if they relate to a legitimate subject matter for which we have
              provided an email address. You are more likely to get a reply if your request or
              question is polite, reasonable and there is no relatively obvious other way to deal
              with or answer your concern or question (e.g. FAQs, other areas of our website, etc.).
            </p>
            <p style={{ marginLeft: '20px', marginTop: '8px' }}>
              Our staff are not authorised to contract on behalf of Harbinger technovation Ltd,
              waive rights or make representations (whether contractual or otherwise). If anything
              contained in an email from a Harbinger technovation Ltd address contradicts anything
              in this policy, our terms or any official public announcement on our website, or is
              inconsistent with or amounts to a waiver of any Harbinger technovation Ltd rights, the
              email content will be read down to grant precedence to the latter. The only exception
              to this is genuine correspondence expressed to be from the Harbinger technovation Ltd
              legal department.
            </p>
          </section>

          <p style={{ marginTop: '10px', marginLeft: '20px' }}>
            For any further questions, contact us at{' '}
            <a href="mailto:stalk-exchange@harbinger-technovation.com">
              stalk-exchange@harbinger-technovation.com
            </a>
          </p>
        </Typography>
      </Box>
    </Container>
  );
};

export default Disclaimers;
