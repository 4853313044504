import Image from 'common/Image';
import Assets from 'utils/Assets';
import { BlackTextTypography } from './CustomTypography';

function Done({ message, detail }: { message: string; detail: string }) {
  return (
    <div className="d-flex flex-column w-100 h-100 align-items-center justify-content-center">
      <Image asset={Assets.Done} width={45} height={45} className="m-4" />
      {message && (
        <BlackTextTypography sx={{ maxWidth: '250px' }} textAlign="center" variant="h5">
          {message}
        </BlackTextTypography>
      )}
      {detail && (
        <BlackTextTypography variant="body2" className="mt-3">
          {detail}
        </BlackTextTypography>
      )}
    </div>
  );
}

export default Done;
