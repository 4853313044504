import React from 'react';
import { IIconProps } from './Icon.props';
import IconContainer from './IconContainer';

const IconDate: React.FC<IIconProps> = ({ ...props }) => {
  return (
    <IconContainer {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 52 52"
        fill="none"
      >
        <path
          d="M7.25 13.5C10.7018 13.5 13.5 10.7018 13.5 7.25C13.5 3.79822 10.7018 1 7.25 1C3.79822 1 1 3.79822 1 7.25C1 10.7018 3.79822 13.5 7.25 13.5Z"
          stroke="white"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M44.75 13.5C48.2018 13.5 51 10.7018 51 7.25C51 3.79822 48.2018 1 44.75 1C41.2982 1 38.5 3.79822 38.5 7.25C38.5 10.7018 41.2982 13.5 44.75 13.5Z"
          stroke="white"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M26 13.5C29.4518 13.5 32.25 10.7018 32.25 7.25C32.25 3.79822 29.4518 1 26 1C22.5482 1 19.75 3.79822 19.75 7.25C19.75 10.7018 22.5482 13.5 26 13.5Z"
          stroke="white"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M7.25 32.25C10.7018 32.25 13.5 29.4518 13.5 26C13.5 22.5482 10.7018 19.75 7.25 19.75C3.79822 19.75 1 22.5482 1 26C1 29.4518 3.79822 32.25 7.25 32.25Z"
          stroke="white"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M44.75 32.25C48.2018 32.25 51 29.4518 51 26C51 22.5482 48.2018 19.75 44.75 19.75C41.2982 19.75 38.5 22.5482 38.5 26C38.5 29.4518 41.2982 32.25 44.75 32.25Z"
          stroke="white"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M7.25 51C10.7018 51 13.5 48.2018 13.5 44.75C13.5 41.2982 10.7018 38.5 7.25 38.5C3.79822 38.5 1 41.2982 1 44.75C1 48.2018 3.79822 51 7.25 51Z"
          stroke="white"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M44.75 51C48.2018 51 51 48.2018 51 44.75C51 41.2982 48.2018 38.5 44.75 38.5C41.2982 38.5 38.5 41.2982 38.5 44.75C38.5 48.2018 41.2982 51 44.75 51Z"
          stroke="white"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M26 51C29.4518 51 32.25 48.2018 32.25 44.75C32.25 41.2982 29.4518 38.5 26 38.5C22.5482 38.5 19.75 41.2982 19.75 44.75C19.75 48.2018 22.5482 51 26 51Z"
          stroke="white"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M9.71947 8.29134H4.77155C4.47989 8.29134 4.33405 7.93717 4.54239 7.73926L7.01114 5.27051C7.13614 5.14551 7.34447 5.14551 7.46947 5.27051L9.93822 7.73926C10.1466 7.94759 10.0007 8.29134 9.70905 8.29134H9.71947Z"
          fill="white"
        />
        <path
          d="M23.5307 6.20801H28.4786C28.7703 6.20801 28.9161 6.56217 28.7078 6.76009L26.239 9.22884C26.114 9.35384 25.9057 9.35384 25.7807 9.22884L23.3119 6.76009C23.1036 6.55176 23.2494 6.20801 23.5411 6.20801H23.5307Z"
          fill="white"
        />
        <path
          d="M47.2195 8.29134H42.2716C41.9799 8.29134 41.8341 7.93717 42.0424 7.73926L44.5111 5.27051C44.6361 5.14551 44.8445 5.14551 44.9695 5.27051L47.4382 7.73926C47.6466 7.94759 47.5007 8.29134 47.2091 8.29134H47.2195Z"
          fill="white"
        />
        <path
          d="M4.78067 24.958H9.72858C10.0203 24.958 10.1661 25.3122 9.95775 25.5101L7.489 27.9788C7.364 28.1038 7.15567 28.1038 7.03067 27.9788L4.56192 25.5101C4.35358 25.3018 4.49942 24.958 4.79108 24.958H4.78067Z"
          fill="white"
        />
        <path
          d="M42.2807 24.958H47.2286C47.5203 24.958 47.6661 25.3122 47.4578 25.5101L44.989 27.9788C44.864 28.1038 44.6557 28.1038 44.5307 27.9788L42.0619 25.5101C41.8536 25.3018 41.9994 24.958 42.2911 24.958H42.2807Z"
          fill="white"
        />
        <path
          d="M9.71947 45.7913H4.77155C4.47989 45.7913 4.33405 45.4372 4.54239 45.2393L7.01114 42.7705C7.13614 42.6455 7.34447 42.6455 7.46947 42.7705L9.93822 45.2393C10.1466 45.4476 10.0007 45.7913 9.70905 45.7913H9.71947Z"
          fill="white"
        />
        <path
          d="M23.5307 43.708H28.4786C28.7703 43.708 28.9161 44.0622 28.7078 44.2601L26.239 46.7288C26.114 46.8538 25.9057 46.8538 25.7807 46.7288L23.3119 44.2601C23.1036 44.0518 23.2494 43.708 23.5411 43.708H23.5307Z"
          fill="white"
        />
        <path
          d="M47.2195 45.7913H42.2716C41.9799 45.7913 41.8341 45.4372 42.0424 45.2393L44.5111 42.7705C44.6361 42.6455 44.8445 42.6455 44.9695 42.7705L47.4382 45.2393C47.6466 45.4476 47.5007 45.7913 47.2091 45.7913H47.2195Z"
          fill="white"
        />
        <path
          d="M21.835 22.8747C21.835 20.5726 23.6995 18.708 26.0016 18.708C28.3037 18.708 30.1683 20.5726 30.1683 22.8747C30.1683 25.1768 28.3037 27.0413 26.0016 27.0413V29.1247"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M26.0016 34.3333C26.5769 34.3333 27.0433 33.867 27.0433 33.2917C27.0433 32.7164 26.5769 32.25 26.0016 32.25C25.4263 32.25 24.96 32.7164 24.96 33.2917C24.96 33.867 25.4263 34.3333 26.0016 34.3333Z"
          fill="white"
        />
      </svg>
    </IconContainer>
  );
};

export default IconDate;
