import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { Link, useLocation } from 'react-router-dom';
import { RootState } from 'store';
import { setDisplaySearch } from 'store/app/actions';
import MenuIcon from '@mui/icons-material/Menu';
import ColorPalette from 'utils/ColorPalette';
import Drawer from './components/Drawer';
import NavbarLogo from '../components/NavbarLogo';

const MobileNav: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  const displaySearch = useSelector<RootState, boolean>(s => s.App.displaySearch);
  const { pathname } = useLocation();
  const isAssetPath = pathname.includes('asset');
  const isHome = pathname === '/';

  const drawerHandler = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <div className="d-flex w-100 align-items-center justify-content-between">
      <Link className="d-flex align-items-center" to="/">
        <NavbarLogo width={40} height={40} isHome={isHome} />
      </Link>
      <div>
        {isAssetPath && (
          <IconButton onClick={() => dispatch(setDisplaySearch(!displaySearch))}>
            <SearchIcon
              sx={{
                color: isHome ? ColorPalette.primary : ColorPalette.white,
                fontSize: '30px',
              }}
            />
          </IconButton>
        )}
        <IconButton onClick={drawerHandler}>
          <MenuIcon
            sx={{
              color: isHome ? ColorPalette.primary : ColorPalette.white,
              fontSize: '40px',
            }}
          />
        </IconButton>
      </div>
      <Drawer open={openDrawer} onClose={drawerHandler} />
    </div>
  );
};
export default MobileNav;
