import React from 'react';
import { IIconProps } from './Icon.props';
import IconContainer from './IconContainer';

const IconLogo: React.FC<IIconProps> = ({ ...props }) => {
  return (
    <IconContainer {...props}>
      <svg
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M48.9933 24.9947C48.9933 38.2457 38.2486 48.9904 24.9976 48.9904C20.6266 48.9904 16.5254 47.8212 12.9938 45.7766L1.00195 48.9904L4.21577 36.9985C2.17116 33.4669 1.00195 29.3657 1.00195 24.9947C1.00195 11.7377 11.7407 0.999023 24.9976 0.999023C38.2546 0.999023 48.9933 11.7377 48.9933 24.9947Z"
          fill="white"
        />
        <path
          d="M1.00132 49.988C0.737498 49.988 0.485669 49.8861 0.2938 49.6942C0.0419715 49.4424 -0.0599592 49.0706 0.0359755 48.7289L3.14786 37.1327C1.08526 33.4393 0 29.2481 0 24.997C0 11.2124 11.2124 0 24.997 0C38.7816 0 49.994 11.2124 49.994 24.997C49.994 38.7816 38.7816 49.994 24.997 49.994C20.7459 49.994 16.5547 48.9087 12.8553 46.8521L1.25914 49.964C1.1752 49.988 1.08526 50 1.00132 50V49.988ZM12.9932 44.7716C13.167 44.7716 13.3409 44.8195 13.4968 44.9035C16.9804 46.9181 20.9557 47.9854 24.997 47.9854C37.6784 47.9854 47.9913 37.6724 47.9913 24.991C47.9913 12.3096 37.6784 1.99664 24.997 1.99664C12.3156 1.99664 2.00264 12.3156 2.00264 24.997C2.00264 29.0383 3.06991 33.0195 5.08454 36.4972C5.21645 36.725 5.25243 37.0008 5.18647 37.2587L2.42235 47.5776L12.7413 44.8135C12.8253 44.7895 12.9152 44.7775 12.9992 44.7775L12.9932 44.7716Z"
          fill="#004588"
        />
        <path
          d="M34.0991 23.801H15.8955C14.8282 23.801 14.2886 22.5059 15.0501 21.7504L24.1519 12.6486C24.6196 12.1809 25.381 12.1809 25.8487 12.6486L34.9505 21.7504C35.706 22.5059 35.1724 23.801 34.1051 23.801H34.0991Z"
          fill="#25CD98"
        />
        <path
          d="M34.944 28.2381L25.8422 37.3399C25.3745 37.8076 24.613 37.8076 24.1453 37.3399L15.0435 28.2381C14.288 27.4826 14.8217 26.1875 15.8889 26.1875H34.0925C35.1598 26.1875 35.6995 27.4826 34.938 28.2381H34.944Z"
          fill="#CC0033"
        />
      </svg>
    </IconContainer>
  );
};

export default IconLogo;
