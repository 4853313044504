import Done from 'common/Done';

function SignUpSuccess() {
  return (
    <Done
      message="Thanks for signing up!"
      detail="Please check your inbox for a confirmation link."
    />
  );
}

export default SignUpSuccess;
