import { useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import LockIcon from '@mui/icons-material/Lock';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Snackbar, Stack, Typography, useMediaQuery } from '@mui/material';

import './styles.css';
import { Vote } from './types';
import { isLoggedIn } from 'utils/helper';
import IconNews from 'assets/icons/IconNews';
import ColorPalette from 'utils/ColorPalette';
import IconGauge from 'assets/icons/IconGauge';
import IconPrice from 'assets/icons/IconNewsPrice';
import IconWatchList from 'assets/icons/IconWatchList';
import IconInformation from 'assets/icons/IconInformation';
import NewVoteSection from '../../../common/UserSentimentSection';
import { createBookmark, deleteBookmark } from 'services/lists';

interface viewProps {
  data: any;
  filterType: string;
  refetch?: any;
  isMarked?: boolean;
  setShowLoginModal?: () => void;
  isLock?: boolean;
}
const Row: React.FC<viewProps> = ({
  data,
  filterType,
  refetch,
  isMarked = false,
  isLock,
  setShowLoginModal,
}) => {
  const { push } = useHistory();
  const isMobile = useMediaQuery('(max-width:820px)');
  const [snack, setSnack] = useState({
    show: false,
    text: '',
  });
  const isLogged = isLoggedIn();
  const [vote, setVote] = useState<Vote>({
    isVoted: data?.vote_outcome_within_revote_period !== 'not_voted',
    voteType: data?.vote_outcome_within_revote_period,
    voteCount: data?.vote_count,
    totalVotePercent: Number((data?.like_count - data?.dislike_count) / data?.vote_count) * 100,
    likePercent: Number((data?.like_count / data?.vote_count) * 100).toFixed(0),
    dislikePercent: Number((data?.dislike_count / data?.vote_count) * 100).toFixed(0),
    neutralPercent: Number((data?.neutral_count / data?.vote_count) * 100).toFixed(0),
  });
  const { mutateAsync: addBookmarkMutate } = useMutation('bookmark-add', createBookmark);
  const { mutateAsync: deleteBookmarkMutate } = useMutation('bookmark-delete', deleteBookmark);

  const price_change = 100.0 * data.change_price_return;

  const color = (percent: number) =>
    percent > 0 ? ColorPalette.newSuccess : percent === 0 ? ColorPalette.grey : ColorPalette.error;

  const onVote = (data: Vote) => {
    setVote(data);
  };

  const changeSentiment = 100 * data.change_sentiment;
  const NewsBarLocation =
    changeSentiment === 0
      ? 50
      : changeSentiment > 0
      ? changeSentiment / 2 + 50
      : 50 + changeSentiment / 2;

  return (
    <div
      style={{
        backgroundColor: ColorPalette.darkBlue,
        borderRadius: '3px',
        padding: '15px',
      }}
      className="w-100 d-flex mb-3 flex-column"
    >
      <Stack direction={isLogged ? 'row-reverse' : 'row'} justifyContent="space-between">
        {isLogged && (
          <Box
            sx={{
              backgroundColor: isMarked ? ColorPalette.newSuccess : ColorPalette.darkBlue,
              borderRadius: '5px',
              display: 'flex',
              padding: '5px 8px',
              width: isMobile ? 'fit-content' : 'clamp(150px,150px, 150px)',
              height: isMobile ? '35px' : '42px',
              marginLeft: '12px',
              alignItems: 'center',
              justifyContent: 'space-between',
              color: ColorPalette.white,
              cursor: 'pointer',
            }}
            title={isMarked ? 'Remove from watch list' : 'Add to watch list'}
            onClick={() => {
              if (isMarked) {
                deleteBookmarkMutate(data?.id).then(() => {
                  setSnack({ show: true, text: 'The stock has been removed from your watchlist' });
                  refetch();
                });
              } else {
                addBookmarkMutate(data?.id).then(() => {
                  setSnack({ show: true, text: 'The stock has been added to your watchlist' });
                  refetch();
                });
              }
            }}
          >
            {isMarked ? (
              <DoneIcon sx={{ fontSize: '18px' }} />
            ) : (
              <AddIcon sx={{ fontSize: '18px' }} />
            )}
            <IconWatchList
              svgSize={isMobile ? 18 : 26}
              svgColor={ColorPalette.white}
              sx={{ ml: 0 }}
            />
            {!isMobile && (
              <Typography fontSize={isMobile ? '12px' : '16px'} lineHeight={1.1} textAlign="center">
                Watch List
              </Typography>
            )}
          </Box>
        )}
        <Stack>
          <Stack direction="row" alignItems="baseline">
            <Typography
              lineHeight={1}
              color={ColorPalette.white}
              style={{ fontWeight: 'bold', fontSize: isMobile ? '18px' : ' 22px' }}
            >
              {data?.ticker}
            </Typography>
            <Typography
              lineHeight={1}
              color={ColorPalette.white}
              style={{ fontSize: isMobile ? '16px' : '20px', marginLeft: '8px' }}
            >
              {data?.name}
            </Typography>
          </Stack>
          <Typography
            color={ColorPalette.white}
            style={{ fontSize: isMobile ? '10px' : '14px' }}
            marginBottom="10px"
            marginTop="5px"
          >
            {`News ${
              filterType === 'sentiment' ? 'Sentiment' : filterType === 'price' ? 'Price' : 'Count'
            } Rank : `}
            {filterType === 'sentiment'
              ? data.rank_in_sentiment
              : filterType === 'price'
              ? data.rank_in_price
              : data?.rank_in_news}
          </Typography>
        </Stack>
      </Stack>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <Box
          sx={{
            backgroundColor: ColorPalette.white,
            borderRadius: '5px',
            display: 'flex',
            padding: isMobile ? '3px 5px' : '5px 8px',
            minWidth: '90px',
            justifyContent: 'space-between',
            flex: isMobile ? 1 : 1.5,
          }}
        >
          <IconPrice svgSize={isMobile ? 20 : 30} />
          <Stack
            direction={isMobile ? 'column' : 'row'}
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              fontSize={isMobile ? '11px' : '20px'}
              fontWeight="bold"
              mr={isMobile ? '' : '10px'}
            >
              {data?.change_price.toFixed(2)}$
            </Typography>
            <Typography
              style={{
                color: color(+price_change?.toFixed(2)),
                fontSize: isMobile ? '9px' : '14px',
                marginBlock: isMobile ? 'auto' : '',
              }}
              fontWeight="bold"
            >
              {+price_change?.toFixed(2)}%
            </Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            backgroundColor: ColorPalette.white,
            borderRadius: '5px',
            display: 'grid',
            gridTemplateColumns: `1fr 1fr ${isMobile ? '1fr' : '2fr'}`,
            alignItems: 'center',
            flex: 5,
          }}
        >
          <Stack
            direction="row"
            sx={{
              borderRight: `1px solid ${ColorPalette.primary}`,
              padding: '3px 10px 3px 3px',
              alignItems: 'center',
            }}
          >
            {/*<IconNews svgSize={isMobile ? 15 : 25} />*/}
            {isMobile ? (
              <Typography fontWeight="bold" lineHeight={1.1} marginLeft="5px" fontSize="10px">
                News <br />
                Sentiment
              </Typography>
            ) : (
              <Typography fontWeight="bold" lineHeight={1.1} marginLeft="5px" fontSize="14px">
                News Sentiment
              </Typography>
            )}
          </Stack>
          <Stack direction="column" alignItems="center" marginX="5px">
            <Typography fontSize={isMobile ? '12px' : '16px'}>
              {+data.change_num_of_references.toFixed(2)}
              <strong
                style={{
                  fontSize: isMobile ? '9px' : '14px',
                  color: color(data.change_reference_return),
                  marginLeft: '2px',
                }}
              >
                ({data.change_reference_return})
              </strong>
            </Typography>
            <Typography fontSize={isMobile ? '8px' : '12px'} noWrap>
              Number Of News
            </Typography>
          </Stack>
          <Box
            style={{
              background:
                'linear-gradient(270deg, rgba(37,205,152,1) 0%, rgba(255,255,255,1) 50%, rgba(204,0,51,1) 100%)',
              height: '100%',
              width: '100%',
              borderRadius: '0px 5px 5px 0px',
              position: 'relative',
            }}
          >
            <div
              style={{
                position: 'absolute',
                left: `${NewsBarLocation === 100 ? (isMobile ? 88 : 96) : NewsBarLocation}%`,
                top: '-8px',
              }}
            >
              <div className="class1"></div>
              <div className="class2"></div>
              <div className="class3" style={{ backgroundColor: 'rgb(255, 255, 255)' }}></div>
            </div>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          marginTop: '5px',
          minHeight: isMobile ? '36px' : '45px',
        }}
      >
        <Box
          sx={{
            backgroundColor: ColorPalette.white,
            borderRadius: '5px',
            display: 'flex',
            padding: isMobile ? '3px 5px' : '5px 8px',
            minWidth: '90px',
            alignItems: 'center',
            justifyContent: 'space-between',
            flex: isMobile ? 1 : 1.5,
            cursor: 'pointer',
          }}
          onClick={() => {
            if (isLock && setShowLoginModal) {
              setShowLoginModal();
            } else {
              push(`/asset/${data?.ticker}`);
            }
          }}
        >
          {isLock ? (
            <LockIcon sx={{ fontSize: isMobile ? '16px' : '2rem' }} />
          ) : (
            <IconInformation svgSize={isMobile ? 20 : 28} />
          )}
          {isMobile ? (
            <Typography fontSize={'8px'} lineHeight={1.1} textAlign="center">
              More <br />
              Information
            </Typography>
          ) : (
            <Typography fontSize={'16px'} lineHeight={1.1} textAlign="center" fontWeight="600">
              More Information
            </Typography>
          )}
        </Box>
        <NewVoteSection id={data?.id} ticker={data?.ticker} voted={vote} onVote={onVote} />
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snack.show}
        onClose={() => setSnack({ show: false, text: '' })}
        autoHideDuration={2000}
        message={snack.text}
      />
    </div>
  );
};

export default Row;
