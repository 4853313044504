import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import ColorPalette from 'utils/ColorPalette';

export const WhiteTextTypography = withStyles({
  root: {
    color: ColorPalette.white,
  },
})(Typography);

export const BlackTextTypography = withStyles({
  root: {
    color: ColorPalette.offBlack,
  },
})(Typography);
