import * as Types from './types';

const initialState: Types.ScatterChartState = {
  XAxis: 'SENTIMENT',
  YAxis: 'PRICE',
  dataRange: 'DAILY',
  xIndex: 0,
  yIndex: 0,
};

function scatterChartReducer(
  state = initialState,
  actions: Types.ScatterChartActionsType,
): Types.ScatterChartState {
  switch (actions.type) {
    case Types.SET_X_AXIS:
      return { ...state, XAxis: actions.payload };
    case Types.SET_X_INDEX:
      return { ...state, xIndex: actions.payload };
    case Types.SET_Y_AXIS:
      return { ...state, YAxis: actions.payload };
    case Types.SET_Y_INDEX:
      return { ...state, yIndex: actions.payload };
    case Types.SET_DATA_RANGE:
      return { ...state, dataRange: actions.payload };
    default:
      return state;
  }
}

export default scatterChartReducer;
