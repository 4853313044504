import React from 'react';
import { IIconProps } from './Icon.props';
import IconContainer from './IconContainer';

const NewsSummary: React.FC<IIconProps> = ({ ...props }) => {
  return (
    <IconContainer {...props}>
      <svg
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.0625 0.3C0.0625 0.134315 0.197644 0 0.364352 0H7.6088C7.7755 0 7.91065 0.134315 7.91065 0.3V3.6H11.231C11.3977 3.6 11.5329 3.73431 11.5329 3.9V7.2H14.8532C15.0199 7.2 15.1551 7.33431 15.1551 7.5V14.7C15.1551 14.8657 15.0199 15 14.8532 15H7.6088C7.44209 15 7.30694 14.8657 7.30694 14.7V11.4H3.98657C3.81987 11.4 3.68472 11.2657 3.68472 11.1V7.8H0.364352C0.197644 7.8 0.0625 7.66569 0.0625 7.5V0.3ZM7.30694 7.2V0.6H0.666204V7.2H7.30694ZM7.6088 7.8H4.28843V10.8H10.9292V4.2H7.91065V7.5C7.91065 7.66569 7.7755 7.8 7.6088 7.8ZM11.231 11.4H7.91065V14.4H14.5514V7.8H11.5329V11.1C11.5329 11.2657 11.3977 11.4 11.231 11.4ZM1.87361 2.1C1.87361 1.93431 2.00875 1.8 2.17546 1.8H5.79769C5.96439 1.8 6.09954 1.93431 6.09954 2.1C6.09954 2.26569 5.96439 2.4 5.79769 2.4H2.17546C2.00875 2.4 1.87361 2.26569 1.87361 2.1ZM1.87361 3.3C1.87361 3.13431 2.00875 3 2.17546 3H5.79769C5.96439 3 6.09954 3.13431 6.09954 3.3C6.09954 3.46569 5.96439 3.6 5.79769 3.6H2.17546C2.00875 3.6 1.87361 3.46569 1.87361 3.3ZM1.87361 4.5C1.87361 4.33431 2.00875 4.2 2.17546 4.2H5.79769C5.96439 4.2 6.09954 4.33431 6.09954 4.5C6.09954 4.66569 5.96439 4.8 5.79769 4.8H2.17546C2.00875 4.8 1.87361 4.66569 1.87361 4.5ZM1.87361 5.7C1.87361 5.53432 2.00875 5.4 2.17546 5.4H5.79769C5.96439 5.4 6.09954 5.53432 6.09954 5.7C6.09954 5.86569 5.96439 6 5.79769 6H2.17546C2.00875 6 1.87361 5.86569 1.87361 5.7Z"
          fill="#004588"
        />
      </svg>
    </IconContainer>
  );
};

export default NewsSummary;
