import React, { useState } from 'react';
import { Divider } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { ThreeCircles } from 'react-loader-spinner';
import { useInfiniteQuery, useQuery } from 'react-query';
import { loadBookmark, loadTable } from 'services/lists';

import Row from '../components/Row';
import { isLoggedIn } from 'utils/helper';
import Tabs from '../components/StockListTabs';
import InfiniteScroll from 'common/InfinitScroll';
import VoteHistory from '../components/VoteHistory';
import SignInModal from '../components/SignInModal';
import FilterSectionM from '../components/FilterSection';

export type ActiveTabType = 'ALL' | 'WATCHLIST' | 'VOTEHISTORY';

const ListDesktopView: React.FC = () => {
  const [filter, setFilter] = useState({
    page: 1,
    asset_id: '',
    sortby: 'news',
    dir: 'desc',
  });
  const isMobile = useMediaQuery('(max-width:720px)');
  const isLogged = isLoggedIn();
  const [currentTab, setCurrentTab] = useState<ActiveTabType>('ALL');
  const [showLoginModal, setShowLoginModal] = useState(false);

  const {
    data,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
    isFetched,
    isFetchingPreviousPage,
    isFetching,
    isFetchedAfterMount,
    isPreviousData,
    isRefetching,
  } = useInfiniteQuery(
    ['assetLists', filter.sortby, filter.dir, filter.asset_id],
    () => loadTable(filter),
    {
      getNextPageParam: () => filter,
    },
  );

  const {
    data: bookmark,
    isLoading: isBookmarkLoading,
    refetch,
  } = useQuery('bookmark', loadBookmark, {
    enabled: isLogged,
  });

  const fetchMore = () => {
    if (
      data &&
      isFetched &&
      !isFetchingNextPage &&
      !isFetchingPreviousPage &&
      !isFetching &&
      isFetchedAfterMount &&
      !isPreviousData &&
      !isRefetching
    ) {
      setFilter(prv => ({ ...prv, page: (prv.page += 1) }));
      fetchNextPage();
    }
  };

  return (
    <>
      <SignInModal showModal={showLoginModal} handleClose={() => setShowLoginModal(false)} />
      <Tabs
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        setShowLoginModal={setShowLoginModal}
      />
      <FilterSectionM
        onChangeSort={(data: { sortby: string; dir: string }) =>
          setFilter(prv => ({ ...prv, page: 1, ...data }))
        }
        disabled={currentTab !== 'ALL' || !isLogged}
        filters={filter}
        onChangeAssetId={search => setFilter(prv => ({ ...prv, asset_id: search, page: 1 }))}
        setShowLoginModal={setShowLoginModal}
      />

      {!isMobile && <Divider className="mb-2 w-100 " style={{ borderColor: '#fff' }} />}
      {(data?.pages.length !== 0 && !isLoading && currentTab === 'ALL') || !currentTab ? (
        <InfiniteScroll
          onFetchMore={fetchMore}
          hasMore={
            // @ts-ignore
            Number(data?.pages[data.pages.length - 1].current_page) <=
            // @ts-ignore
            data?.pages[0].number_of_pages
          }
        >
          {data?.pages.map(page => (
            <React.Fragment
              // @ts-ignore
              key={page?.current_page}
            >
              {/* @ts-ignore */}
              {page?.result.map((item: any, index: number) => (
                <React.Fragment key={item.id}>
                  <Row
                    data={item}
                    isLock={!isLogged && index > 0}
                    setShowLoginModal={() => setShowLoginModal(true)}
                    filterType={filter.sortby}
                    isMarked={bookmark?.result?.some((el: any) => el.id === item.id)}
                    refetch={refetch}
                  />
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </InfiniteScroll>
      ) : currentTab === 'WATCHLIST' && !isBookmarkLoading ? (
        <React.Fragment>
          {bookmark?.result.map((item: any) => (
            <Row data={item} filterType={filter.sortby} isMarked refetch={refetch} />
          ))}
        </React.Fragment>
      ) : currentTab === 'VOTEHISTORY' ? (
        <VoteHistory />
      ) : (
        <div
          style={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20%',
          }}
        >
          <ThreeCircles color="#1057C4" />
        </div>
      )}
    </>
  );
};

export default ListDesktopView;
