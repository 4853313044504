import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from 'pages/home/Home';

import About from 'pages/about/About';
import Login from 'pages/subscribe/Login';
import Lists from 'pages/asset-list/Lists';
import AssetDetails from 'pages/asset-details';
import Subscribe from 'pages/subscribe/SignUp';
import VerifyEmail from 'pages/subscribe/EmailVerify';
import ChangePass from 'pages/subscribe/ChangePassword';
import Disclaimers from 'pages/disclaimers/Disclaimers';
import NewPassEnter from 'pages/subscribe/NewPassEnter';
import SignUpSuccess from 'pages/subscribe/SignUpSuccess';
import ConfirmChangePass from 'pages/subscribe/ConfirmChangePass';
import ChangePassSuccess from 'pages/subscribe/ChangePassReqSuccess';

const RouterProvider: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/asset/:ticker" component={AssetDetails} />
      <Route exact path="/list" component={Lists} />
      <Route exact path="/about-us" component={About} />
      <Route exact path="/disclaimers" component={Disclaimers} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/sign-up" component={Subscribe} />
      <Route exact path="/email/confirm/:id" component={VerifyEmail} />
      <Route exact path="/change-password" component={ChangePass} />
      <Route exact path="/change-password-request-success" component={ChangePassSuccess} />
      <Route exact path="/password-reset/confirm/:uid/:id" component={NewPassEnter} />
      <Route exact path="/confirm-change-pass" component={ConfirmChangePass} />
      <Route exact path="/sign-up-success" component={SignUpSuccess} />
    </Switch>
  );
};

export default RouterProvider;
