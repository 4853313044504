import React from 'react';
import { Container, Link, Stack, Typography, useMediaQuery } from '@mui/material';
import ColorPalette from 'utils/ColorPalette';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import IconFooter from 'assets/icons/IconFooter';

const Footer: React.FunctionComponent = () => {
  const isMobile = useMediaQuery('(max-width:900px');

  return (
    <footer
      style={{
        width: '100%',
        minHeight: '100px',
        display: 'flex',
        marginTop: 'auto',
        alignItems: 'center',
        paddingInline: '2rem',
        flexDirection: isMobile ? 'column' : 'row',
        paddingTop: isMobile ? '5px' : '1rem',
        borderTop: '1px solid white',
      }}
    >
      {!isMobile ? (
        <IconFooter />
      ) : (
        <Stack
          direction="row"
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            paddingBottom: '5px',
            borderBottom: '1px solid white',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ marginRight: 'auto' }}
          >
            <IconFooter sx={{ width: 25 }} />
            <Typography
              color={ColorPalette.offBlack}
              sx={{
                fontSize: '1.2rem',
                marginLeft: '7px',
              }}
            >
              sTalk
            </Typography>
          </Stack>
          <LinkedInIcon sx={{ marginRight: '10px', color: ColorPalette.darkBlue }} />
          <InstagramIcon sx={{ marginRight: '10px', color: ColorPalette.darkBlue }} />
        </Stack>
      )}
      <Typography
        sx={{
          maxWidth: isMobile ? '100%' : '60%',
          marginInlineStart: isMobile ? '0px' : '1rem',
          marginBlockStart: isMobile ? '10px' : 'undefined',
          marginBottom: isMobile ? '10px' : '0px',
          textAlign: isMobile ? 'center' : 'left',
          fontSize: isMobile ? '.7rem' : '.9rem',
        }}
        color={ColorPalette.offBlack}
      >
        © 2024 Harbinger Technovation Ltd. All rights reserved.
      </Typography>
      {!isMobile && (
        <Stack
          direction="row"
          sx={{
            marginLeft: 'auto',
          }}
        >
          <a href="https://www.linkedin.com" target="_blank">
            <LinkedInIcon
              sx={{ marginRight: '10px', color: ColorPalette.darkBlue }}
              fontSize="large"
            />
          </a>
          <a href="https://www.instagram.com" target="_blank">
            <InstagramIcon
              sx={{ marginRight: '10px', color: ColorPalette.darkBlue }}
              fontSize="large"
            />
          </a>
        </Stack>
      )}
      {/*{!isMobile && (
        <Typography
          color={ColorPalette.offBlack}
          variant="body1"
          sx={{
            fontSize: isMobile ? '.7rem' : '.9rem',
          }}
          marginBottom={isMobile ? '1rem' : undefined}
        >
          Follow us on Social Media
        </Typography>
      )}*/}
    </footer>
  );
};

export default Footer;
