import axios from 'axios';
import { VoteType } from 'store/sentiment/types';

export type RequestMethod = 'POST' | 'GET' | 'DELETE' | 'PATCH';

export interface IPagination {
  pageSize?: number;
  page: number;
}

const app = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

if (localStorage.getItem('token')) {
  app.defaults.headers.common['Authorization'] = `Token ${localStorage.getItem('token')}`;
}

app.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  },
);

export interface VoteReq {
  id: number;
  type: VoteType;
}

export const PAGINATION_LIMIT = 30;
export interface IRequest {
  url: string;
  isFormData?: boolean;
  body?: Record<string, any>;
  method?: RequestMethod;
}

export default app;
